import { Tooltip } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { formatResourceText } from "../../../utils/reservation";


function ResourcesBullets({ resourceRequirements }) {
  console.log(resourceRequirements)
  return (
    resourceRequirements.length > 0 && (
          resourceRequirements.map((r, index) => (
            <Tooltip key={index} title={r.type === "EQ" ? "Limited resource" : ""}>
              <Typography color={r.type === "EQ" && "primary"} key={index}>
                {formatResourceText(r)}
              </Typography>
            </Tooltip>
          ))
    )
  );
}

export default ResourcesBullets;
