import { Box } from "@mui/material";
import { useContext } from "react";
import { ApiContext } from "../api/api.context";
import { GettingStartedList } from "../components/GettingStarted/GettingStartedList";
import { useGettingStartedItems } from "../components/GettingStarted/gettingStartedConfig";

export function GettingStarted(props) {
  const { items, mutate } = useGettingStartedItems();
  const api = useContext(ApiContext);

  const setStatus = async (item, newStatus) => {
    const updatedItem = { ...item, status: newStatus };
    await api.gettingStarted.put(item.id, updatedItem);
    mutate();
  };

  return (
    <Box m={4}>
      <GettingStartedList items={items} setStatus={setStatus} />
    </Box>
  );
}
