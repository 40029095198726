import FullCalendar from "@fullcalendar/react";
import adaptivePlugin from "@fullcalendar/adaptive";
import listPlugin from "@fullcalendar/list";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import rrulePlugin from "@fullcalendar/rrule";
import timeGridPlugin from "@fullcalendar/timegrid";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useContext, useRef } from "react";
import { ApiContext } from "../../../api/api.context";
import { ActiveSchoolsContext } from "../../../contexts/activeSchools.context";
import { pickTextColorBasedOnBgColorAdvanced, RGB_Linear_Shade } from "../../../utils/color";
import { getReservationTitle, getTagsSet } from "../../../utils/reservation";
import { BackgroundEvent } from "./BackgroundEvent";
import ReservationContent from "./ReservationContent";
import { ReservationListRow } from "./ReservationListRow";
// import ProductRecommendations from "../../../components/ProductRecommendations/ProductRecommendations.container";
import { CreateReservationForm } from "../../../components/forms/CreateReservationForm";
import { LoadingReservationDetailsWithControls } from "./LoadingReservationDetailsWithControls";


function Scheduler({
  initialView,
  reservations,
  slots,
  locations,
  openDrawer,
  closeDrawer,
  loading,
  school,
  mutate,
  setStartDate,
  startDate,
  setView,
  setDateAndView,
  filterTags,
  tags
}) {
  // console.log(reservations);
  const api = useContext(ApiContext);
  const theme = useTheme();
  const {
    activeSchoolsState: { user },
    activeProfile,
  } = useContext(ActiveSchoolsContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const calendarRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const getNewReservationButtonText = () => {
    if (isMobile) {
      return "+";
    }
    if (isSmallScreen) {
      return "New";
    }
    return "New reservation";
  };

  return (
    <>
      {loading && (
        <CircularProgress size={60} style={{ position: "absolute", left: "50%", top: "50%" }} />
      )}
      <FullCalendar
        initialView={initialView}
        initialDate={startDate}
        className="tour-step-1"
        ref={calendarRef}
        hiddenDays={school?.days_excluded || [0, 6]}
        height="100%"
        schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY}
        eventColor={theme.palette.secondary.main}
        titleFormat={isSmallScreen && { year: "numeric", month: "short", day: "numeric" }}
        resourceAreaHeaderContent="Location"
        eventBorderColor="none"
        plugins={[timeGridPlugin, resourceTimelinePlugin, rrulePlugin, adaptivePlugin, listPlugin]}
        slotMinWidth={75}
        resourceAreaWidth={isMobile ? "30%" : "15%"}
        scrollTime="08:00:00"
        slotDuration='00:30:00'
        nowIndicator={true}
        resources={locations.map((location) => ({
          id: location.id,
          title: location.name,
        }))}
        resourceOrder="title"
        dayHeaderFormat={({ date }) => moment(date).format("ddd D")}
        eventSources={[
          async () => {
            return await slots.map((slot) => {
              //must use moment UTC so timezone is respected when UNTIL is used
              const dtStart = moment(slot.start).format("YYYYMMDDTHHmmss");
              // console.log("slot: ", slot);
              // if slot.recurrences.contains()
              const rrule = slot.recurrences.split("\n").pop();
              const slotsEventData = {
                id: slot.id,
                title: slot.title,
                // reserved_for: slot.reserved_for,
                number_of_sets: slot.number_of_sets,
                location: slot.location,
                display: "background",
                rrule: `DTSTART:${dtStart}\n${rrule}`,
                duration: moment(slot.end).diff(moment(slot.start)),
                school: slot.school,
                onClick: (eventInfo) => 
                  openDrawer(
                    <div>
                      <CreateReservationForm
                        onSuccess={() => {
                          closeDrawer();
                          mutate();
                        }}
                        initialValues={{ start: eventInfo.event.start, end: eventInfo.event.end }}
                        riskAssessment={activeProfile.school.risk_assessment_required_before_reservation}
                      />
                      {/* <ProductRecommendations productQuery={"app-showcase-15-09-22"}/> */}
                    </div>,
                    "New reservation"
                  )
              };
              // console.log("slotEvent: ", slotsEventData);
              return slotsEventData;
            });
          },
          async () => {
            const events = await reservations.map((r) => {

              // const getProductQuery=(r)=>{
              //   if (r.total_required_resources.length > 0){
              //     return r.total_required_resources[0].name
              //   }
              //   if (r.title) {
              //     return r.title
              //   }
              //   if (r.activities_detail?.length > 0) {
              //     return r.activities_detail[0].name
              //   }
              //   return "showcase"
              // }

              return {
                title: getReservationTitle(r),
                start: r.start,
                end: r.end,
                reservation: r,
                onClick: () =>
                openDrawer(
                  <div>
                    <LoadingReservationDetailsWithControls
                      loaderFunction={() => api.reservations.getOne(r.id)}
                      onError={(error) =>
                        enqueueSnackbar(`Something went wrong, please try again. ${error.message}`)
                      }
                      onSuccess={() => {
                        closeDrawer();
                        mutate();
                      }}
                    />
                    {/* {getProductQuery(r) && <ProductRecommendations productQuery={getProductQuery(r)}/>} */}
                  </div>,
                  ""
                ),
                resourceId: r.location, // to show the event on the time/location grid.
              };
            });
            return events;
          },
        ]}
        lazyFetching={false}
        eventDataTransform={(event) => {

          const view = calendarRef.current._calendarApi.view.type

          // if event is a slot, show event.
          if (event.display === "background") {
            // event.color = theme.palette.secondary["400"];
            event.color='#b3b3b3'
            return event;
          } 

          // event must be reservation
          const reservation = event.reservation;
          if (!reservation) return false;

          if (filterTags.length > 0){
            if (!filterTags.some(r=> getTagsSet(event.reservation).has(r))) {
              return false
            }
          }

          if (!calendarRef.current) return event;
          //if list view, show event without setting a color
          if (view === "listWeek") return event;

          //set default color
          event.color = theme.palette.secondary.dark;

          //if assigned, set color
          if (reservation.assigned_to && reservation.assigned_to.length) {
            event.color = theme.palette.secondary.darker;
          }

          //if late, set color
          if (reservation.is_late) {
            event.color = theme.palette.warning.main;
          }

          // if deliberately colored, override color
          if (reservation.color){
            event.color = reservation.color
          }

          //show all events in timeline
          if (calendarRef.current._calendarApi.view.type === "resourceTimelineDay") return event;

          //is the event reserved for me? SHOW EVENT
          if (reservation.reserved_for === user.id) {
            return event;
          }

          //is the event assigned to me? SHOW EVENT
          if (reservation.assigned_to?.map((id) => id).includes(user.id)) {
            return event;
          }

          
          //else HIDE EVENT
          return false;
        }}
        eventContent={(eventInfo) => {
          if (eventInfo.view.type === "listWeek")
            return <ReservationListRow eventInfo={eventInfo} mutate={mutate} tags={tags} openDrawer={openDrawer} closeDrawer={closeDrawer}/>;
          // console.log(eventInfo);
          if (eventInfo.event.display === "background") return <BackgroundEvent eventInfo={eventInfo} />;
          return <ReservationContent eventInfo={eventInfo} mutate={mutate} tags={tags} textColor={pickTextColorBasedOnBgColorAdvanced(eventInfo.backgroundColor)}/>;
        }}
        buttonIcons={{
          refresh: " fas fa-sync-alt",
        }}
        buttonText={{ today: useMediaQuery(theme.breakpoints.down("sm")) ? "now" : "today" }}
        customButtons={{
          refresh: {
            text: "refresh",
            //icon: " fas fa-sync",
            click: mutate,
          },
          newReservation: {
            text: getNewReservationButtonText(),
            click: () =>
              openDrawer(
                <div>                
                  <CreateReservationForm
                    onSuccess={() => {
                      closeDrawer();
                      mutate();
                    }}
                  />
                  {/* <ProductRecommendations productQuery={"app-showcase-15-09-22"} numberOfHits={25} randomize/> */}
                </div>,
                "New reservation"
              ),
          },
        }}
        headerToolbar={{
          left: useMediaQuery(theme.breakpoints.down("sm")) ? "prev,next,today" : "prev,next today",
          center: useMediaQuery(theme.breakpoints.down("md")) ? "" : "title",
          right: useMediaQuery(theme.breakpoints.down("sm"))
            ? "timeGridWeek,timeGridDay,resourceTimelineDay,listWeek newReservation"
            : "timeGridWeek,timeGridDay,resourceTimelineDay,listWeek,refresh newReservation",
        }}
        views={{
          timeGridWeek: {
            buttonText: isSmallScreen ? "wk" : "my week",
            titleFormat: { month: "short", day: "numeric" }
          },
          timeGridDay: {
            buttonText: isSmallScreen ? "day" : "my day",
            titleFormat: { month: "short", day: "numeric" }
          },
          resourceTimelineDay: {
            type: "resourceTimeline",
            buttonText: isSmallScreen ? "all" : "all reservations",
            titleFormat: { month: "short", day: "numeric", weekday: 'short' }
          },
          listWeek: {
            buttonText: "list",
            titleFormat: { month: "short", day: "numeric" }
          },
        }}
        // viewDidMount={(view) => setView(view.view.type)} // TODO: this is probably bad: doing a state update on mount.
        //Makes a new API call with new start and end params when you advance the date with the < and > buttons
        datesSet={async (dateInfo) => {
          // do not run on first load up, only when > or < is used.
          const dateChanged =
            moment(dateInfo.start).format("YYYY-MM-DD") !== moment(startDate).format("YYYY-MM-DD");
          const viewChanged = dateInfo.view.type !== initialView;
          if (dateChanged || viewChanged) setDateAndView(dateInfo.start, dateInfo.view.type);
        }}
        eventClassNames={(info) => {
          var classes = [];
          if (
            info.event.extendedProps.reserved_for &&
            info.event.extendedProps.reserved_for.email !== user.email
          ) {
            classes.push("hidden");
          }
          return classes;
        }}
        eventMouseEnter={(mouseEnterInfo) => {
          const newColor = RGB_Linear_Shade(
            -0.2,
            mouseEnterInfo.el.style.backgroundColor
          );
          mouseEnterInfo.el.style.backgroundColor = newColor
          mouseEnterInfo.el.style.cursor = "pointer";
        }}
        eventMouseLeave={(mouseEnterInfo) => {
          mouseEnterInfo.el.style.backgroundColor = mouseEnterInfo.event.backgroundColor;
        }}
        // eventClick={(info) => {
        //   if (info.event.display === "background") {
        //     // user clicked a slot -> create new reservation
        //     openDrawer(
        //       <CreateReservationForm
        //         onSuccess={() => {
        //           closeDrawer();
        //           refresh();
        //         }}
        //         initialValues={{ start: info.event.start, end: info.event.end }}
        //         riskAssessment={activeProfile.school.risk_assessment_required_before_reservation}
        //       />,
        //       "New reservation"
        //     );
        //   } else {
        //     // user clickd an existing reservation  -> show details
        //     openDrawer(
        //       <LoadingReservationDetailsWithControls
        //         loaderFunction={() =>
        //           api.reservations.getOne(info.event.extendedProps.reservation.id)
        //         }
        //         onError={(error) =>
        //           enqueueSnackbar(`Something went wrong, please try again. ${error.message}`)
        //         }
        //         onSuccess={() => {
        //           closeDrawer();
        //           refresh();
        //         }}
        //       />,
        //       ""
        //     );
        //   }
        // }}
      />
    </>
  );
}

export default Scheduler;
