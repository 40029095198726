import { useContext } from "react";
import { ApiContext } from "../../api/api.context";
import { ReservationForm } from "./ReservationForm";

export function CreateReservationForm(props) {
  const api = useContext(ApiContext);
  return (
    <ReservationForm
      apiSubmitFunction={api.reservations.post}
      submitButtonText="Create"
      {...props}
    />
  );
}
