import { Button, Grid } from "@mui/material";
import * as Sentry from "@sentry/react";
import { TextField } from "formik-mui";

import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import { useMemo } from "react";

const defaultInitialValues = {
  name: "",
};

function formatInitialValues(initialValues) {
    const formattedValues = { ...initialValues };  
    return formattedValues;
  }

export function LocationForm({ initialValues, onSuccess, apiSubmitFunction, submitButtonText }) {
    const formattedInitialValues = useMemo(
        () => initialValues && formatInitialValues(initialValues),
        [initialValues]
      );

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter an location name."),
  });

  const onSubmit = async (values, { setFieldError, resetForm }) => {
    const valuesToSubmit = { ...values };
    try {
      await apiSubmitFunction(valuesToSubmit);
      enqueueSnackbar("Location created", { variant: "success" });
      onSuccess();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("An error occurred. Location not created.", { variant: "error" });
      Sentry.captureException(error);
    }
  };

  return (
    <Formik
      initialValues={formattedInitialValues || defaultInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
        <Form>
          <Grid container columns={6} spacing={2} rowSpacing={1} alignItems="center">
            <Grid item xs={6}>
              <Field component={TextField} label="Name" name="name" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onSubmit={submitForm}
                sx={{ width: "100%" }}
              >
                {submitButtonText || "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
