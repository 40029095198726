import React from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

export default function useModal() {
  const [modal, setModal] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const openModal = (contents) =>
    setModal(
      <Dialog fullScreen={fullScreen} open onClose={closeModal}>
        {contents}
      </Dialog>
    );

  const closeModal = () => {
    setModal(null);
  };

  return [modal, openModal, closeModal];
}
