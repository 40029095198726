import { useContext } from "react";
import useSWR from "swr";
import { ApiContext } from "../../api/api.context";
import { ActiveSchoolsContext } from "../../contexts/activeSchools.context";

export const DONE_STATUS = "done";
export const PENDING_STATUS = "pending";
export const SKIPPED_STATUS = "skipped";

export const gettingStartedItems = [
  {
    name: "signup",
    heading: "Sign up and take the tour",
    text: "Signup for bunsen and take a look around the app.",
    path: "/scheduler/",
    status: DONE_STATUS,
  },
  {
    name: "reservations",
    heading: "Make a reservation",
    text: "See how quick it is to request a practical by clicking on any of the time-slots in the scheduler. Choose a set practical, or type your own message to make a custom request, or book out an individual piece of equipment on its own.",
    path: "/scheduler/",
    video:"https://www.loom.com/share/acc7bff855824c2c811af05c1020d293",
    status: PENDING_STATUS,
  },
  {
    name: "practicals",
    heading: "Create practicals",
    text: "A practical represents a standard set of equipment that can be booked out in one go. If you want us to upload practicals from an existing document, click the cloud icon towards the top of the screen.",
    path: "/practicals/",
    video:"https://www.loom.com/share/8147156c7d6145f991d855e2bf9e1ee6",
    status: PENDING_STATUS,
  },
  {
    name: "equipment",
    heading: "Add your equipment",
    text: "Bunsen can keep track of your stock, and check if equipment is available before a reservation is made to prevent double-bookings. We can import your existing stock from a document that you can upload using the cloud icon towards the top of the screen.",
    path: "/equipment/",
    video:"https://www.loom.com/share/b161113bddec4f119a2cdd52f60dc71d",
    status: PENDING_STATUS,
  },
  {
    name: "slots",
    heading: "Edit your reservation slots",
    text: "Input your timetable so teachers can make reservations quickly. When a teacher clicks on a time-slot in the calendar, the start and end times of their reservation will be pre-loaded.",
    path: "/settings/school-reservation-slots/",
    video:"https://www.loom.com/share/74cdad4677b742268277c9db9bbbcf55",
    status: PENDING_STATUS,
  },
  {
    name: "tags",
    heading: "Organise your reservations, practicals, and equipment with tags",
    text: "Create coloured tags to organise your reservations, practicals and equipment by subject, preparation status, year group and more.",
    path: "/settings/tags/",
    video: "https://www.loom.com/share/850d36ddfe294bd58425105270a5ee62",
    status: PENDING_STATUS,
  },
  {
    name: "settings",
    heading: "Fine tune your set up",
    text: "Go to settings to create deadlines for reservations, set up email notifications, and more.",
    path: "/settings/my-school/",
    video:"https://www.loom.com/share/c6b6a20bc8d448e0a0da5533f410ca77",
    status: PENDING_STATUS,
  },
];

export function useGettingStartedItems() {
  const api = useContext(ApiContext);
  const { activeSchoolID } = useContext(ActiveSchoolsContext);
  const { data, error, mutate } = useSWR(
    ["getting-started", activeSchoolID],
    () => activeSchoolID && api.gettingStarted.list()
  );

  if (!data?.data) return { items: undefined, error, mutate };

  const serverItems = Object.fromEntries(data.data.map((x) => [x.name, x]));
  const itemsWithStatus = gettingStartedItems.map((item) => {
    const serverItem = serverItems[item.name];
    return serverItem ? { ...item, ...serverItem } : item;
  });

  return { items: itemsWithStatus, error, mutate };
}

export async function useInitGettingStarted() {
  const api = useContext(ApiContext);
  const { items, mutate } = useGettingStartedItems();
  if (items === undefined) return; // no response from server yet

  // if an item does not have an id, it does not exist yet on the server. Create.
  let mutateNeeded = false;
  for (const item of items) {
    if (!item.id) {
      await api.gettingStarted.post(item);
      mutateNeeded = true;
    }
  }
  if (mutateNeeded) mutate();
}
