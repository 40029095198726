import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { CheckboxWithLabel } from 'formik-mui';
import { Field, Form, Formik } from "formik";
import { TextField, ToggleButtonGroup } from "formik-mui";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect } from "react";
import * as Yup from "yup";
import { geographyOptions } from "../signup/containers/geographyOptions";
import ToggleButton from '@mui/material/ToggleButton';
import { useAxios } from "../../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../redux/data";
import { LocalizationProvider } from '@mui/x-date-pickers'
import moment from "moment";
import DayTimeDeadlineField from "./components/DayTimeDeadlineField"
import AdapterMoment from '@mui/lab/AdapterMoment';
import { ActiveSchoolsContext } from "../../contexts/activeSchools.context";

function MySchool() {
  const theme = useTheme();
  const school = useSelector((state)=>state.school.data)
  const { activeProfile } = useContext(ActiveSchoolsContext)
  const isAdmin = activeProfile?.is_admin;
  const { enqueueSnackbar } = useSnackbar();
  const {axiosInstance} = useAxios()

  const dispatch = useDispatch()
  const DAYS_OF_THE_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

  useEffect(() => {
    (async () => {
      const res = await axiosInstance.get('auth/schools/')
      dispatch(get({ name: "school", data: res.data[0] }));
    })();
  }, [dispatch, axiosInstance])


  const onSubmit = async ({name, country, timezone, days_excluded, risk_assessment_required_before_reservation, send_email_notifications, default_reservation_assignee, hours_notice_deadline, day_time_deadline}) => {
    const payload = {
      name,
      country,
      timezone, 
      days_excluded,
      risk_assessment_required_before_reservation,
      send_email_notifications,
      default_reservation_assignee,
    }
    if (hours_notice_deadline.hours !== null){
      payload["hours_notice_deadline"]=hours_notice_deadline
    }
    if(!!day_time_deadline.day){
      payload["day_time_deadline"]={
        day: day_time_deadline.day,
        time: moment(day_time_deadline.time).format("HH:mmZ")
      }
    }
    try {
      const res = await axiosInstance.put(`auth/schools/${school.id}/`, payload);
      dispatch(get({ name: "school", data: res.data }));
      enqueueSnackbar("Details updated", { variant: "success" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Sorry! An error has occurred", { variant: "error" });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
    <Paper
      elevation={0}
      style={{
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        backgroundColor: "transparent",
      }}
    >
      <Typography variant="h6" gutterBottom>My school</Typography>
      <Formik
      enableReinitialize
        initialValues={{
          name: school.name || "",
          country: school.country || "GB",
          timezone: school.timezone || "Europe/London",
          days_excluded: school.days_excluded || [0,6],
          risk_assessment_required_before_reservation: school.risk_assessment_required_before_reservation || false,
          send_email_notifications: school.send_email_notifications || false,
          default_reservation_assignee: school.default_reservation_assignee || "",
          hours_notice_deadline: {
            hours: school.hours_notice_deadline?.hours || null
          },
          day_time_deadline: {
            day: school.day_time_deadline?.day || "",
            time: moment(school.day_time_deadline?.time, 'H:m') || "",
          }
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Please enter your school's name."),
          country: Yup.string().required("Please enter your school's country."),
        })}
        onSubmit={onSubmit}
      >
        {({ values, submitForm, isSubmitting, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  label="School or institution name"
                  name="name"
                  InputProps={{ readOnly: !isAdmin }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  label="Country"
                  name="country"
                  select
                  InputProps={{ readOnly: !isAdmin }}
                  children={geographyOptions.countries.map((country, index) => (
                    <MenuItem key={index} value={country.code}>
                      {country.label}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  label="Timezone"
                  name="timezone"
                  select
                  InputProps={{ readOnly: !isAdmin }}
                  children={geographyOptions.timezones.map(
                    (timezone, index) => (
                      <MenuItem key={index} value={timezone}>
                        {timezone}
                      </MenuItem>
                    )
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label="Auto-assign reservations to"
                      name="default_reservation_assignee"
                      select
                      disabled={!isAdmin}
                      children={colleagueOptions.map((colleague, index) => (
                        <MenuItem key={index} value={colleague.id}>
                          {colleague.user.given_name + " " + colleague.user.family_name}
                        </MenuItem>
                      ))}
                    />
                  </Grid> */}
              <Grid item xs={12}>              
                <Field
                  component={TextField}
                  label="Hours' notice required for reservation"
                  name="hours_notice_deadline.hours"
                  type="number"
                  disabled={!isAdmin}
                  fullWidth
                  inputProps={{
                    min: 0,
                    max: 999,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <DayTimeDeadlineField DAYS_OF_THE_WEEK={DAYS_OF_THE_WEEK} isAdmin={isAdmin} setFieldValue={setFieldValue} values={values} onSubmit={onSubmit} school={school} />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>Days hidden in calendar</Typography>
                <Field
                  component={ToggleButtonGroup}
                  name="days_excluded"
                  type="checkbox"
                  size="small"
                >
                  {DAYS_OF_THE_WEEK.map(
                    (day, index) => (
                      <ToggleButton id={index} key={index} value={index}>
                        {day}
                      </ToggleButton>
                    )
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field component={CheckboxWithLabel} type="checkbox" name="risk_assessment_required_before_reservation" Label={{label: "Risk assessment is required before reservation is made"}} InputProps={{ readOnly: !isAdmin }}/>
              </Grid>
              <Grid item xs={12}>
                <Field component={CheckboxWithLabel} type="checkbox" name="send_email_notifications" Label={{label: "Send reservation email notifications to admins"}} InputProps={{ readOnly: !isAdmin }}/>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="primary"
                  disabled={!isAdmin || isSubmitting}
                  style={{ float: "right" }}
                >
                  {isSubmitting ? "Loading..." : "Update details"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
    </LocalizationProvider>
  );
}

export default MySchool;
