import { useContext, useCallback } from "react";
import { ApiContext } from "../../api/api.context";
import { withLoader } from "../../api/withLoader";
import { AutocompleteInput } from "./AutocompleteInput";

const AutocompleteInputWithLoader = withLoader(AutocompleteInput, "options");

export function UsersAutocomplete(props) {
  const { sendMessage, ...passThroughProps } = props;
  const api = useContext(ApiContext);

  const loadUsers = async () => {
    const profilesResponse = await api.userProfiles.list();
    // console.log("profilesResponse: ", profilesResponse.data);
    const usersWithNamesSet = profilesResponse.data
      .map((p) => p.user_detail.name?.trim() && p.user_detail)
      .filter((p) => p);
    const users = { data: usersWithNamesSet };
    return users;
  };

  const onError = useCallback((error) => sendMessage(error.message), [sendMessage]);

  return (
    <AutocompleteInputWithLoader
      loaderFunction={loadUsers}
      onError={onError}
      label="Add User"
      {...passThroughProps}
    />
  );
}
