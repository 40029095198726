import { useContext, useState } from "react";
import { ActiveSchoolsContext } from "../contexts/activeSchools.context";
import { Stack, Typography, Popover, Box } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import WorkspacesIcon from "@mui/icons-material/Workspaces";

function SchoolChip({ schoolData, primary, onClick }) {
  return (
    <Stack direction="row" alignItems="center" onClick={onClick} sx={{ cursor: "pointer" }}>
      <Typography color={primary ? "primary" : "black"}>{schoolData.name}</Typography>
      {primary && <UnfoldMoreIcon sx={{color: 'text.secondary'}} />}
    </Stack>
  );
}

export function SchoolSwitcher() {
  const {
    activeProfile,
    activeSchoolsState,
    dispatch: activeSchoolDispatch,
  } = useContext(ActiveSchoolsContext);
  const allSchoolData = activeSchoolsState.userprofiles.map((x) => x.school);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const updateActiveSchool = (newSchoolID) => {
    activeSchoolDispatch({ type: "SET_ACTIVE_SCHOOL", data: newSchoolID });
    handleClosePopover();
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        onClick={handleOpenPopover}
        sx={{ cursor: "pointer", pl: 1 }}
      >
        <WorkspacesIcon sx={{ color: "text.secondary" }} />
        <SchoolChip schoolData={activeProfile.school} primary />
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ margin: 2 }}>
          {allSchoolData.map((x, index) => (
            <SchoolChip key={index} schoolData={x} onClick={() => updateActiveSchool(x.id)} />
          ))}
        </Stack>
      </Popover>
    </Box>
  );
}
