import { useContext } from "react";
import { ApiContext } from "../../api/api.context";
import { ColorChip } from "../../components/ColorChip";
import DataTable from "../../components/Datatable/DataTable";
import { TagForm } from "../../components/forms/TagForm";

function UpdateTagForm(props) {
  const { item, ...passThroughProps } = { ...props };
  const api = useContext(ApiContext);
  return (
    <TagForm
      apiSubmitFunction={(values) => api.tags.put(item.id, values)}
      submitButtonText="Update"
      initialValues={item}
      {...passThroughProps}
    />
  );
}

function CreateTagForm(props) {
  const { ...passThroughProps } = { ...props };
  const api = useContext(ApiContext);

  return (
    <TagForm apiSubmitFunction={api.tags.post} submitButtonText="Create" {...passThroughProps} />
  );
}

function Tags() {
  const api = useContext(ApiContext);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: { display: "excluded", filter: false },
    },
    { name: "name", label: "Name", options: { filter: false } },
    {
      name: "color",
      label: "Color",
      options: {
        filter: false,
        customBodyRender: (value) => value && <ColorChip color={value} />,
      },
    },
  ];

  return (
    <DataTable
      minimal
      itemName="Tag"
      columns={columns}
      apiListFunction={api.tags.list}
      apiDeleteFunction={api.tags.del}
      updateFormComponent={UpdateTagForm}
      createFormComponent={CreateTagForm}
    />
  );
}

export default Tags;
