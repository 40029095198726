import { Button, Grid, useTheme } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import * as Yup from "yup";
import { ActiveSchoolsContext } from "../../../contexts/activeSchools.context";
import { useAxios } from "../../../hooks/useAxios";
import { auth0Instance } from "../../../auth/auth0";

function GeneralAccountDetails() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { axiosInstance } = useAxios();
  const user = auth0Instance.getUser();
  const isSocial = user.sub.startsWith("windowslive") || user.sub.startsWith("google-oauth2");
  const { dispatch: activeSchoolDispatch } = useContext(ActiveSchoolsContext);

  const onSubmit = async (payload) => {
    try {
      const response = await axiosInstance.patch("auth/me/", payload);
      activeSchoolDispatch({ type: "SET_USER", data: response.data });
      enqueueSnackbar("Details updated", { variant: "success" });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{
        given_name: user.given_name,
        family_name: user.family_name,
      }}
      validationSchema={Yup.object({
        given_name: Yup.string().required("This field is required."),
        family_name: Yup.string().required("This field is required."),
      })}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                disabled={isSocial}
                label="Forename(s)"
                name="given_name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                disabled={isSocial}
                label="Surname"
                name="family_name"
                fullWidth
              />
            </Grid>
            <Button
              onClick={submitForm}
              variant="contained"
              color="primary"
              disabled={isSocial || isSubmitting}
              style={{ float: "right", margin: theme.spacing(1) }}
            >
              {isSubmitting ? "Loading..." : "Change details"}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default GeneralAccountDetails;
