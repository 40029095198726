import { useEffect, useState } from "react";
import useSWR from "swr";
import { CenteredBox } from "../components/CenteredBox";
import { LoaderWithMessage } from "../components/LoaderWithMessage";
import { setId } from "../rewards";
import * as Sentry from "@sentry/react";

const MAX_RETRIES = 5;

export function ActivateRewards() {
  // if the user gets here, it means they just activated their rewards account.
  // set this account as the school rewards account.
  // display an error if something went wrong.
  const [errorCount, setErrorCount] = useState(0);
  const incrementErrorCount = () => setErrorCount(errorCount + 1);
  const { data, error } = useSWR(["rewards-id"], () => setId(), {
    errorRetryCount: MAX_RETRIES,
    onError: incrementErrorCount,
  });
  const loading = !data && !error;

  useEffect(() => {
    if (error) {
      console.log(error);
      Sentry.captureException(error, { extra: { response: error.response } });
    }
  });

  if (loading) return <LoaderWithMessage message="Activating Bunsen Rewards." />;
  if (errorCount >= MAX_RETRIES) {
    return (
      <CenteredBox>
        The rewards account activation could not be processed after {MAX_RETRIES} attempts. Please
        contact support@bunsen.education.
        <br />
        Error message:
        <br />
        {error.message}
      </CenteredBox>
    );
  }
  if (error)
    return (
      <LoaderWithMessage
        message={`Still waiting for your account to activate... (attempt ${errorCount})`}
      />
    );
  return <CenteredBox>Rewards Activated.</CenteredBox>;
}
