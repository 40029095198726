import { useContext, useState, useCallback } from "react";
import { ApiContext } from "../../api/api.context";
import { withLoader } from "../../api/withLoader";
import { filterByNameCodeTag } from "../../utils/filters";
import { AutocompleteInput } from "./AutocompleteInput";
import { OptionWithNameCodeTag } from "./OptionWithNameCodeTag";

const AutocompleteInputWithLoader = withLoader(AutocompleteInput, "options");

const filterResources = (options, params) => {
  const filtered = filterByNameCodeTag(options, params);
  const { inputValue } = params;

  const isExisting = options.some((option) => inputValue === option.name);

  if (inputValue !== "" && !isExisting) {
    filtered.push({
      inputValue: inputValue,
      name: inputValue,
      title: `Add new resource: '${inputValue}'`,
    });
  }
  return filtered;
};

export function ResourcesAutocomplete(props) {
  const { sendMessage, ...passThroughProps } = props;
  const [loading, setLoading] = useState(false);

  const api = useContext(ApiContext);

  const createResource = useCallback(async (payload) => {
    let data = undefined;
    setLoading(true);
    try {
      const response = await api.resources.post(payload);
      sendMessage &&
        sendMessage("Resource created. You can edit this resource on the resources page.");
      data = response.data;
    } catch (error) {
      if (sendMessage) {
        sendMessage("Error: Resource not created", {
          variant: "error",
        });
      } else {
        throw error;
      }
    }
    setLoading(false);
    return data;
  }, [api.resources, sendMessage]);

  const onError = useCallback((error) => sendMessage(error.message), [sendMessage]);

  return (
    <AutocompleteInputWithLoader
      createItem={createResource}
      loaderFunction={api.resources.list}
      loading={loading}
      onError={onError}
      autoSelect={true}
      label="Add Resource"
      filterOptions={(options, params) => filterResources(options, params)}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <OptionWithNameCodeTag option={option} />
          </li>
        );
      }}
      {...passThroughProps}
    />
  );
}
