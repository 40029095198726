import React, { useContext } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { geographyOptions } from "./geographyOptions";
import { Link, MenuItem, FormHelperText} from "@mui/material";
import { useAxios } from '../../../hooks/useAxios';
import { CheckboxWithLabel } from 'formik-mui';
import { ActiveSchoolsContext } from '../../../contexts/activeSchools.context';
import { auth0Instance } from "../../../auth/auth0";

function CreateSchool({nextStep, previousStep}) {
    const theme = useTheme();
    const { dispatch } = useContext(ActiveSchoolsContext)
    const {axiosInstance} = useAxios()
    const user = auth0Instance.getUser();
    const isSocial = user.sub.startsWith("windowslive") || user.sub.startsWith("google-oauth2");


    const onSubmit = async ({ name, country, timezone, given_name, family_name, terms_accepted }, { setFieldError }) => {
        try {
        const payload = {name, country, timezone, given_name, family_name, terms_accepted}
          const response = await axiosInstance.post(
            `auth/schools/`,
            payload,
          );
          dispatch({ type: "SET_ACTIVE_SCHOOL", data: response.data.id })
          window.analytics.track("Account Created");
          nextStep()
        } catch (error) {
          console.log(error);
        }
      };

    return (
        <div>
        <Formik
          validateOnBlur={false}
          initialValues={{
            name: "",
            country: "GB",
            timezone: "Europe/London",
            given_name: user.given_name || "",
            family_name: user.family_name || "",
            terms_accepted: false
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(
            "Please enter the name of your school or institution."
          ),
            given_name: Yup.string().required(
              "Please enter your first name."
          ),
            family_name: Yup.string().required(
              "Please enter your last name."
          ),
            terms_accepted: Yup.bool().oneOf([true], 'You must accept the terms of service to proceed.')
          })}
          onSubmit={onSubmit}
        >
          {({ submitForm, isSubmitting, touched, errors }) => (
              <>
            <Typography gutterBottom variant="h6">
                Create your school account
            </Typography>
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Field
                            component={TextField}
                            disabled={isSocial}
                            label="Forename(s)"
                            name="given_name"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            component={TextField}
                            label="Surname"
                            disabled={isSocial}
                            name="family_name"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            component={TextField}
                            label="School or institution name"
                            autoComplete="organization"
                            name="name"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            component={TextField}
                            label="Country"
                            name="country"
                            select
                            children={geographyOptions.countries.map(
                                (country, index) => (
                                <MenuItem key={index} value={country.code}>
                                    {country.label}
                                </MenuItem>
                                )
                            )}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                        fullWidth
                        component={TextField}
                        label="Timezone"
                        name="timezone"
                        select
                        children={geographyOptions.timezones.map(
                            (timezone, index) => (
                            <MenuItem key={index} value={timezone}>
                                {timezone}
                            </MenuItem>
                            )
                        )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="terms_accepted"
                        Label={{ label: <Typography>By checking this box, I agree to the <Link href="https://app.termly.io/document/terms-and-conditions/29bd5d6b-6232-48a6-a92c-303e074cd061" target="_blank" rel="noopener noreferrer">terms of service</Link></Typography> }}
                      />
                      {(touched["terms_accepted"] && !!errors["terms_accepted"]) ? <FormHelperText error>{errors["terms_accepted"]}</FormHelperText> : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                          onClick={previousStep}
                          variant="contained"
                          color="primary"
                          style={{ float: "left", marginTop: theme.spacing(2) }}
                          >
                          Back
                        </Button>
                        <Button
                        onClick={submitForm}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        style={{ float: "right", marginTop: theme.spacing(2) }}
                        >
                        {isSubmitting ? "Loading..." : "Next"}
                        </Button>
                    </Grid>
                </Grid>
            </Form>
            </>
          )}
        </Formik>

      </div>
    )
}

export default CreateSchool
