import * as tags from "./tags";
import * as activities from "./activities";
import * as reservations from "./reservations";
import * as resources from "./resources";
import * as uploads from "./uploads";
import * as imports from "./imports";
import * as userProfiles from "./userProfiles";
import * as locations from "./locations";
import * as slots from "./slots";
import * as gettingStarted from "./gettingStarted";
import * as rewards from "./rewards";

export { setSchoolID } from "./base";

export const api = {
  rewards,
  tags,
  activities,
  reservations,
  resources,
  uploads,
  imports,
  locations,
  userProfiles,
  gettingStarted,
  slots,
};
