import { Button, FormHelperText, Grid } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-mui";

import AdapterMoment from "@mui/lab/AdapterMoment";
import * as Sentry from "@sentry/react";
import { DesktopDateTimePicker } from "formik-mui-lab";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useContext, useMemo } from "react";
import * as Yup from "yup";
import { ApiContext } from "../../api/api.context";
import useAttachmentUpload from "../../hooks/useAttachmentUpload";
import { getReservationPrimaryActivity } from "../../utils/reservation";
import { TagsAutocomplete } from "../Autocomplete";
import { ActivitiesAutocomplete } from "../Autocomplete/ActivitiesAutocomplete";
import { LocationsAutocomplete } from "../Autocomplete/LocationsAutocomplete";
import { ResourceInput } from "../ResourceInput";
import { sendReservationAction } from "../../rewards";
import { ColorSelector } from "../ColorSelector";
import { themeTagColors } from "../../theme";

const defaultInitialValues = {
  activity: null,
  location: null,
  start: moment(),
  end: moment().add(1, "hours"),
  number_of_sets: 1,
  comment: "",
  resource_requirements: [],
  risk_assessed: false,
  department: "",
  tags: [],
};

function formatInitialValues(initialValues) {
  const formattedValues = { ...initialValues };

  if (initialValues.tags_detail) formattedValues.tags = initialValues.tags_detail;
  else formattedValues.tags = initialValues.tags?.map((id) => ({ id }));
  formattedValues.tags = formattedValues.tags || [];

  if (initialValues.resource_requirements_detail) {
    formattedValues.resource_requirements = initialValues.resource_requirements_detail?.map(
      (x) => ({
        resource: {
          id: x.resource,
          units: x.resource_units,
          name: x.resource_name,
        },
        quantity: x.quantity_per_set || "",
      })
    );
  }
  if (initialValues.location) formattedValues.location = { id: initialValues.location };

  const activity = getReservationPrimaryActivity(initialValues);
  if (activity) formattedValues.activity = { id: activity.id };
  if (!activity && initialValues.title)
    formattedValues.activity = { inputValue: initialValues.title };

  const adHocActivity = initialValues.activities_detail?.filter((x) => x.ad_hoc)[0];
  if (adHocActivity) {
    formattedValues.resource_requirements = adHocActivity?.resource_requirements;
    formattedValues.resource_requirements = formattedValues.resource_requirements.map((x) => ({
      resource: {
        id: x.resource,
        units: x.resource_units,
        name: x.resource_name,
      },
      quantity: x.quantity_per_set || "",
    }));
  }

  if (initialValues.attachments_detail)
    formattedValues.files = initialValues.attachments_detail?.map((x) => ({
      name: x.file.split("/").pop().split("?")[0], // remove path and query params.
      id: x.id,
    }));

  console.log("formatted values: ", formattedValues);

  return formattedValues;
}

export function ReservationForm({
  initialValues,
  onSuccess,
  apiSubmitFunction,
  submitButtonText,
  riskAssessment,
}) {
  // console.log("RS: ", riskAssessment);
  const formattedInitialValues = useMemo(
    () => initialValues && formatInitialValues(initialValues),
    [initialValues]
  );

  const api = useContext(ApiContext);
  const { enqueueSnackbar } = useSnackbar();

  const [files, fileChips, dropzone, setDropzoneOpen] = useAttachmentUpload(
    formattedInitialValues?.files
  );

  const validationSchema = Yup.object({
    activity: Yup.mixed().required("This field is required"),
    location: Yup.mixed().required("This field is required"),
    start: Yup.date("Please enter a valid date and time.")
      .required("This field is required")
      .nullable(),
    end: Yup.date("Please enter a valid date and time.")
      .min(Yup.ref("start"), "End date must be later than start date")
      .required("This field is required")
      .nullable(),
    comment: Yup.string().max(2500, "Must be 2500 characters or less").nullable(),
    risk_assessed: riskAssessment
      ? Yup.bool().oneOf([true], "Risk assessment required")
      : Yup.mixed().notRequired(),
  });

  const onSubmit = async (values) => {
    const valuesToSubmit = { ...values };

    console.log("pre values: ", values);

    if (!values.activity.id) {
      valuesToSubmit["title"] = values.activity.inputValue || values.activity;
      valuesToSubmit["activities"] = [];
    } else {
      valuesToSubmit["activities"] = [values.activity.id];
    }

    //format tags
    valuesToSubmit["tags"] = values.tags?.map((tag) => tag && tag.id);
    valuesToSubmit["location"] = values.location.id;

    //format resource requirements
    valuesToSubmit.resource_requirements = values.resource_requirements.filter(
      (req) => req.resource && req.resource.id
    );
    valuesToSubmit.resource_requirements = valuesToSubmit.resource_requirements.map((req) => ({
      resource: req.resource.id,
      quantity_per_set: req.quantity || undefined,
    }));

    //upload file attachment and insert id into valuesToSubmit
    valuesToSubmit.attachments = [];
    if (files.length > 0) {
      valuesToSubmit.attachments = [];
      for (const file of files) {
        if (file.id) {
          valuesToSubmit.attachments.push(file.id);
          continue;
        }
        const formData = new FormData();
        console.log(files);
        formData.append("file", file);
        console.log(formData.values());
        const uploadResponse = await api.uploads.post(formData);
        valuesToSubmit.attachments.push(uploadResponse.data.id);
      }
    }

    console.log("submitting: ", valuesToSubmit);
    try {
      await apiSubmitFunction(valuesToSubmit);
      enqueueSnackbar(`Reservation ${submitButtonText.toLowerCase()}d`, { variant: "success" });
      sendReservationAction(); // user gets rewards points through smile.io
      onSuccess();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.unavailable_resources) {
        const resources = error.response.data.unavailable_resources;
        const resourceNames = resources.map((x) => x.name);
        const message = `The following resources are unavailable: ${resourceNames.join(", ")}.`;
        enqueueSnackbar(message, {
          variant: "error",
        });
      } else if (error.response && error.response.data.detail) {
        Sentry.captureException(error, { extra: { valuesToSubmit, response: error.response } });
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
        });
      } else {
        Sentry.captureException(error, { extra: { valuesToSubmit } });
        enqueueSnackbar("An error has occured. Contact support@bunsen.education", {
          variant: "error",
        });
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Formik
        initialValues={{ ...defaultInitialValues, ...formattedInitialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting, touched, errors, values, setFieldValue, formikBag }) => {
          // console.log(errors);
          return (
            <Form>
              <Grid container columns={6} spacing={2} rowSpacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Field
                    component={ActivitiesAutocomplete}
                    freeSolo
                    name="activity"
                    value={values.activity}
                    onChange={(value) => setFieldValue("activity", value)}
                    sendMessage={enqueueSnackbar}
                    error={errors.activity}
                    touched={touched?.activity}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={LocationsAutocomplete}
                    name="location"
                    value={values.location}
                    onChange={(value) => setFieldValue("location", value)}
                    sendMessage={enqueueSnackbar}
                    error={errors.location}
                    touched={touched?.location}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={DesktopDateTimePicker}
                    inputFormat="DD/MM/YY HH:mm"
                    mask="__/__/__ __:__"
                    ampm={false}
                    disablePast
                    name="start"
                    variant="inline"
                    renderInput={(params) => <MuiTextField {...params} label="Start" fullWidth />}
                    autoOk
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={DesktopDateTimePicker}
                    inputFormat="DD/MM/YY HH:mm"
                    mask="__/__/__ __:__"
                    ampm={false}
                    disablePast
                    name="end"
                    variant="inline"
                    renderInput={(params) => <MuiTextField {...params} label="End" fullWidth />}
                    autoOk
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    label="Number of sets"
                    name="number_of_sets"
                    type="number"
                    fullWidth
                    inputProps={{
                      min: 1,
                      max: 999,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TagsAutocomplete}
                    name="tags"
                    value={values.tags}
                    onChange={(value) => setFieldValue("tags", value)}
                    sendMessage={enqueueSnackbar}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    multiline={true}
                    label="Additional information"
                    name="comment"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={ColorSelector}
                    label="Color"
                    name="color"
                    value={values.color}
                    onChange={(value) => setFieldValue("color", value)}
                    options={themeTagColors}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={ResourceInput}
                    name="resource_requirements"
                    value={values.resource_requirements}
                    onChange={(value) => setFieldValue("resource_requirements", value)}
                    sendMessage={enqueueSnackbar}
                    error={errors.resource_requirements}
                    touched={touched?.resource_requirements}
                  />
                </Grid>
                {riskAssessment && (
                  <Grid item xs={6}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="risk_assessed"
                      Label={{ label: "I have assessed the risks associated with this practical" }}
                    />
                    {touched["risk_assessed"] && !!errors["risk_assessed"] ? (
                      <FormHelperText error>{errors["risk_assessed"]}</FormHelperText>
                    ) : null}
                  </Grid>
                )}
                <Grid item xs={6}>
                  {fileChips}
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => setDropzoneOpen(true)}
                  >
                    Add file attachment
                  </Button>
                  {dropzone}
                </Grid>
                <Grid item xs={3} alignSelf='flex-end'>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onSubmit={submitForm}
                  >
                    {submitButtonText || "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </LocalizationProvider>
  );
}
