import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { DONE_STATUS, SKIPPED_STATUS, useGettingStartedItems } from "./gettingStartedConfig";

function CircularProgressWithLabel({ value, ...passThroughProps }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        color="secondary"
        variant="determinate"
        value={value}
        {...passThroughProps}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export function GettingStartedWidget({ items }) {
  if (!items.length) return null;
  const pendingItems = items.filter((x) => x.status !== DONE_STATUS && x.status !== SKIPPED_STATUS);
  if (pendingItems.length === 0) return null;
  const progress = (100 * (items.length - pendingItems.length)) / items.length;
  return (
    <Grid container columns={12} alignItems="center" spacing={1}>
      <Grid item xs={4}>
        <CircularProgressWithLabel value={progress || ""} />
      </Grid>
      <Grid item xs={8}>
        <Stack>
          <Typography variant="body1" color="text.secondary">
            Quick Start
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {pendingItems.length} items remaining
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}

export function GettingStartedWidgetContainer() {
  const { items } = useGettingStartedItems();
  return items ? <GettingStartedWidget items={items} /> : null;
}
