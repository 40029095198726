import { Button, CircularProgress, Grid, Link, Tooltip, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useContext } from "react";
import useSWR from "swr";
import { ActiveSchoolsContext } from "../contexts/activeSchools.context";
import { getPoints, setId } from "../rewards";

function ActivatePointsControl({ onActivate }) {
  return (
    <Grid container columns={12} rowSpacing={0.5} sx={{textAlign:"center"}}>
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom sx={{ lineHeight: 1 }}>
          Save on lab supplies
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Click here to earn Bunsen loyalty points for your school as you make requisitions, and save on lab supplies.">
          <Button
            variant="contained"
            fullWidth={true}
            size="small"
            onClick={onActivate}
            style={{ fontSize: 12 }}
          >
            Earn loyalty points
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Link
          href={
            new URL(
              "pages/auto-login?redirectUrl=/pages/how-bunsen-points-help-you-save",
              process.env.REACT_APP_MARKETPLACE_URL
            ).href
          }
          variant="caption"
        >
          Learn more
        </Link>
      </Grid>
    </Grid>
  );
}

export function RewardsPointsWidget({ points, loading, needsActivation, onActivate }) {
  if (loading) {
    return <CircularProgress />;
  }
  if (needsActivation) return <ActivatePointsControl onActivate={onActivate} />;
  return (
    <Grid container columns={12} rowSpacing={0.5}>
      <Grid item xs={12}>
        <Typography variant="overline" sx={{ lineHeight: 1 }}>
          Your school has
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1" color={"primary"} sx={{ lineHeight: 1 }}>
          {points}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body1" sx={{ lineHeight: 1 }}>
          Bunsen Points
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Link
          href={
            new URL(
              "pages/auto-login?redirectUrl=/pages/how-bunsen-points-help-you-save",
              process.env.REACT_APP_MARKETPLACE_URL
            ).href
          }
          variant="caption"
        >
          Redeem in Marketplace
        </Link>
      </Grid>
    </Grid>
  );
}

export function RewardsPointsWidgetContainer() {
  const { activeSchoolID } = useContext(ActiveSchoolsContext);
  const { data, error, mutate } = useSWR(["rewards", activeSchoolID], () => getPoints(), {
    refreshInterval: 5000,
  });
  const loading = !data && !error;
  const needsActivation = !!error;

  // console.log("get points: ", data, error);
  const activate = async () => {
    try {
      await setId();
      mutate();
    } catch (error) {
      if (error.response.status === 404) {
        // 404 means the rewards account for this user does not exist.
        // go create one, then come back.
        const storeActivationUrl = new URL(
          "pages/auto-login?redirectUrl=https://app.bunsen.education/activate-rewards/",
          process.env.REACT_APP_MARKETPLACE_URL
        ).href;
        window.location.href = storeActivationUrl;
      } else {
        console.log("Scheduler: ", error);
        Sentry.captureException(error, { extra: { response: error.response } });
      }
    }
  };
  return (
    <RewardsPointsWidget
      points={data?.data?.points}
      loading={loading}
      needsActivation={needsActivation}
      onActivate={activate}
    />
  );
}
