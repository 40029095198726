import Axios from "axios";
import { useContext, useMemo } from "react";
import { auth0Instance } from "../auth/auth0";
import { ActiveSchoolsContext } from "../contexts/activeSchools.context";

export const useAxios = () => {
  const { activeSchoolID } = useContext(ActiveSchoolsContext);

  const abortController = useMemo(() => new AbortController(), []);

  const createAxiosInstance = () => {
    const instance = Axios.create({
      baseURL: process.env.REACT_APP_AXIOS_URL,
      timeout: 40000,
      signal: abortController.signal,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Bunsen-App-Version": process.env.REACT_APP_VERSION,
        "Bunsen-App-Name": "bunsen-scheduling-ui",
        "Bunsen-App-Git_SHA": process.env.REACT_APP_GIT_SHA || "",
      },
    });

    instance.interceptors.request.use(
      async (config) => {
        console.log("AxiosInstance :", config.url, activeSchoolID, config.method, config);

        const isAuthenticated = auth0Instance.isAuthenticated();
        if (!isAuthenticated) return config;

        const token = await auth0Instance.getClient().getTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;

        // if no active school, no injection needed
        if (!activeSchoolID) return config;

        // if data exists, inject school id into data.
        if (config.data) {
          if (config.data instanceof Array) {
            config.data = config.data.map((el) => ({ ...el, school: activeSchoolID }));
          } else {
            config.data["school"] = activeSchoolID;
          }
        }

        // if url parameters were encodeded in url string, decode them and put them in 'params'
        if (config.url.includes("?")) {
          const urlSearchParams = new URLSearchParams(config.url.split("?")[1]);
          const params = Object.fromEntries(urlSearchParams.entries());
          config.params = { ...config.params, ...params };
          config.url = config.url.split("?")[0];
        }

        // if get method, inject school id into params.
        if (config.method === "get") {
          if (config.params === undefined) config.params = {};
          config.params = { school: activeSchoolID, ...config.params };
        }
        return config;
      },
      (error) => {
        console.log("Axios Error: ", error);
        return Promise.reject(error);
      }
    );
    return instance;
  };

  const axiosInstance = useMemo(createAxiosInstance, [abortController, activeSchoolID]);

  return { axiosInstance, abortController };
};
