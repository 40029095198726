import axios from "axios";
import { auth0Instance } from "../auth/auth0";

let _schoolID = undefined;

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_URL,
    timeout: 40000,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      "Bunsen-App-Version": process.env.REACT_APP_VERSION,
      "Bunsen-App-Name": "bunsen-scheduling-ui",
      "Bunsen-App-Git_SHA": process.env.REACT_APP_GIT_SHA || "",
    },
  });

  instance.interceptors.request.use(
    async (config) => {
      // console.log("AxiosInstance :", config.url, _schoolID, config.method, config);

      const isAuthenticated = auth0Instance.isAuthenticated();
      if (!isAuthenticated) return config;

      const token = await auth0Instance.getClient().getTokenSilently();
      config.headers.Authorization = `Bearer ${token}`;

      // if no active school, no injection needed
      if (!_schoolID) return config;

      // if data exists, inject school id into data.
      if (config.data) {
        if (config.data instanceof Array) {
          config.data = config.data.map((el) => ({ ...el, school: _schoolID }));
        } else if (config.data instanceof FormData) {
          config.data.append("school", _schoolID);
        } else {
          config.data["school"] = _schoolID;
        }
      }

      // if url parameters were encodeded in url string, decode them and put them in 'params'
      if (config.url.includes("?")) {
        const urlSearchParams = new URLSearchParams(config.url.split("?")[1]);
        const params = Object.fromEntries(urlSearchParams.entries());
        config.params = { ...config.params, ...params };
        config.url = config.url.split("?")[0];
      }

      // if get method, inject school id into params.
      if (config.method === "get") {
        if (config.params === undefined) config.params = {};
        config.params = { school: _schoolID, ...config.params };
      }
      return config;
    },
    (error) => {
      console.log("Axios Error: ", error);
      return Promise.reject(error);
    }
  );
  return instance;
};

const axiosInstance = createAxiosInstance();

function joinUrls(url1, url2) {
  // remove trailing slash if present
  if (url1[url1.length - 1] === "/") url1 = url1.slice(0, url1.length - 1);
  if (url2[url2.length - 1] === "/") url2 = url2.slice(0, url2.length - 1);
  return `${url1}/${url2}/`;
}

export function getOne(url, id) {
  const fullUrl = joinUrls(url, id);
  return axiosInstance.get(fullUrl);
}

export function del(url, id) {
  const fullUrl = joinUrls(url, id);
  return axiosInstance.delete(fullUrl);
}

export function list(url, payload = {}) {
  return axiosInstance.get(url, {
    params: payload,
  });
}

export function get(url, payload = {}) {
  return axiosInstance.get(url, {
    params: payload,
  });
}

export function put(url, id, payload) {
  const fullUrl = joinUrls(url, id);
  return axiosInstance.put(fullUrl, payload);
}

export function patch(url, id, payload) {
  const fullUrl = joinUrls(url, id);
  return axiosInstance.patch(fullUrl, payload);
}

export function post(url, payload) {
  return axiosInstance.post(url, payload);
}

export function apiCallFactory(url) {
  const calls = {
    getOne: (id) => getOne(url, id),
    del: (id) => del(url, id),
    list: (payload) => list(url, payload),
    put: (id, payload) => put(url, id, payload),
    patch: (id, payload) => patch(url, id, payload),
    post: (payload) => post(url, payload),
  };
  return calls;
}

export function setSchoolID(schoolID) {
  _schoolID = schoolID;
}
