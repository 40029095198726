import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function RequestInvite({nextStep}) {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Join an existing school account
      </Typography>
      <Typography gutterBottom>
        To join an existing school account, ask a member of your school's Bunsen
        account for an invitation. Once the invitation is sent, you will be logged into your school's account. Invitations can be sent from Settings >
        Users.
      </Typography>
      <Typography component={Link} onClick={nextStep}>
        Create a new school account instead
      </Typography>
    </div>
  );
}

export default RequestInvite;
