import { Button, Stack, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel } from "formik-mui";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import { ActiveSchoolsContext } from "../../../contexts/activeSchools.context";
import { useAxios } from "../../../hooks/useAxios";



function SchoolProfiles() {
  const { enqueueSnackbar } = useSnackbar();
  const { axiosInstance } = useAxios();
  const { activeProfile, activeSchoolsState } = useContext(ActiveSchoolsContext);
  const userProfiles = activeSchoolsState.userprofiles;

  const onSubmit = async (values) => {
    try {
      await axiosInstance.patch(`auth/userprofiles/${activeProfile.id}/`, values);
      enqueueSnackbar("Preferences updated", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Unable to update preferences", { variant: "error" });
      console.log(e);
    }
  };

  return (
    <div>
      {userProfiles.map((profile, key) => (
        <div key={key}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6">{profile.school.name}</Typography>
            {profile.is_active && <Typography variant="body1">currently active school</Typography>}
          </Stack>
          <div>
            <Typography variant="overline">Account type</Typography>
            <Typography>{profile.is_admin ? "Admin" : "Standard"}</Typography>
            <Formik
              enableReinitialize
              initialValues={{
                receive_reservation_emails: profile.receive_reservation_emails || false,
              }}
              onSubmit={onSubmit}
            >
              {({ values, submitForm, isSubmitting }) => (
                <Form>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="receive_reservation_emails"
                    Label={{
                      label: "Recieve reservation email notifications if enabled in 'My school'",
                    }}
                  />
                  <Button onClick={submitForm} variant="contained" color="primary">
                    {isSubmitting ? "Loading..." : "Update preferences"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SchoolProfiles;
