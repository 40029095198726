import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { TextField, ToggleButtonGroup } from "formik-mui";
import MuiTextField from "@mui/material/TextField";
import * as Yup from "yup";
import { DateTimePicker } from "formik-mui-lab";
import AdapterMoment from '@mui/lab/AdapterMoment';

import { Button, Grid, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { LocalizationProvider } from '@mui/x-date-pickers'

import ToggleButton from '@mui/material/ToggleButton';

import { Autocomplete } from 'formik-mui';
import { RRule } from "rrule";
import { useSnackbar } from "notistack";
import { useAxios } from "../../../hooks/useAxios";
import { useDispatch, useSelector } from 'react-redux'
import { create, deleteSingle, get, update } from "../../../redux/data";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function SlotCard({ slot, index, path }) {
  const locationOptions = useSelector((state) => state.locations.data)
  const dispatch = useDispatch()

  const theme = useTheme();
  const classes = useStyles(theme);
  const {axiosInstance} = useAxios()

  // TODO: review
  // const [repeatForever, setRepeatForever] = useState(
  //   slot ? !RRule.fromString(slot.recurrences).options.until : true
  // );
  const repeatForever = slot ? !RRule.fromString(slot.recurrences).options.until : true
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    if (locationOptions.length === 0){
    (async () => {
      const locationsResponse = await axiosInstance.get("locations/");
      await dispatch(get({ name: "locations", data: locationsResponse.data }));
    }) ()}
  }, [dispatch, axiosInstance, locationOptions.length])

  const onSubmit = async (
    {
      title,
      location,
      number_of_sets,
      start,
      end,
      byDay,
      interval,
      from,
      until,
    },
    { setFieldError, resetForm }
  ) => {
    try {
      const rule = new RRule({
        freq: RRule.WEEKLY,
        interval: interval,
        byweekday: byDay,
        until: repeatForever ? null : moment(until),
      });
      console.log(`startdate: ${moment(from)}`)

      console.log(`start: ${moment(start)}`)
      console.log(`end: ${moment(end)}`)
      const payload = {
        title,
        location: location && location.id,
        number_of_sets,
        start,
        end,
        recurrences: rule.toString(),
      };
      if (slot) {
        console.log(`update: ${payload}`)
        const response = await axiosInstance.patch(
          `${path}/${slot.id}/`,
          payload
        );
        dispatch(update({ name: path==="user-reservation-slots" ? "slots" : "defaultSlots", data: response.data }));
        enqueueSnackbar("Reservation slot updated");
      } else {
        console.log(`create: ${payload}`)
        const response = await axiosInstance.post(
          `${path}/`,
          payload
        );
        dispatch(create({ name: path==="user-reservation-slots" ? "slots" : "defaultSlots", data: response.data }));
        enqueueSnackbar("Reservation slot created", { variant: "success" });
        resetForm();
      }
    } catch (error) {
      if (error.response && error.response.data.detail) {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
        });
      } else {
        console.log(error)
        enqueueSnackbar("An error has occured. Contact support@bunsen.education", {
          variant: "error",
        })
      }
    }
  };

  const deleteSlot = async () => {
    try {
      await axiosInstance.delete(`${path}/${slot.id}/`);
      dispatch(deleteSingle({ name: path==="user-reservation-slots" ? "slots" : "defaultSlots", data: slot.id }));
      enqueueSnackbar("Reservation slot deleted");
    } catch (error) {
      console.log(error);
    }
  };


  const [locationFieldOpen, setLocationFieldOpen] = useState(false);

  return (
    <div className={classes.root}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Formik
          initialValues={
            slot
              ? {
                  title: slot.title || "",
                  location:
                    locationOptions.find((o) => o.id === slot.location) || null,
                  number_of_sets: slot.number_of_sets || 1,
                  start: slot.start || moment(),
                  end: slot.end || moment().add(1, 'hours'),
                  byDay:
                    (slot &&
                      RRule.fromString(slot.recurrences).options.byweekday) ||
                    [],
                  interval:
                    (slot &&
                      RRule.fromString(slot.recurrences).options.interval) ||
                    1,
                  from:
                    (slot &&
                      RRule.fromString(slot.recurrences).options.dtstart) ||
                    new Date(),
                  until:
                    (slot &&
                      RRule.fromString(slot.recurrences).options.until) ||
                    null,
                }
              : {
                  title: "New slot",
                  location: null,
                  number_of_sets: 1,
                  start: null,
                  end: null,
                  byDay: [0, 1, 2, 3, 4],
                  interval: 1,
                  from: new Date(),
                  until: null,
                }
          }
          validationSchema={Yup.object({
            title: Yup.string(),
            interval: Yup.number()
              .integer()
              .positive()
              .required("This field is required."),
            start: Yup.date("Please enter a valid date and time.").required(
              "This field is required"
            ).nullable(),
            end: Yup.date("Please enter a valid date and time.")
              .min(
                Yup.ref("start"),
                "End time must be later than start time. Slots cannot run overnight."
              )
              .required("This field is required").nullable(),
            from: Yup.date("Please enter a valid date and time.").required(
              "This field is required"
            ).nullable(),
            until: Yup.date("Please enter a valid date and time.")
              .min(
                Yup.ref("from"),
                "'Until' date must be later than 'From' date"
              )
              .nullable(),
          })}
          onSubmit={onSubmit}
        >
          {({
            submitForm,
            isSubmitting,
            touched,
            errors,
            values,
            setValues,
          }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    label="Slot name"
                    name="title"
                    variant="filled"
                    size="small"
                    fullWidth
                  />
                </Grid>
                {path==="reservationslots" && 
                <>
                <Grid item xs={12} sm={6}>
                  <Field
                    autoHighlight
                    name="location"
                    open={locationFieldOpen}
                    onOpen={() => {
                      setLocationFieldOpen(true);
                    }}
                    onClose={() => {
                      setLocationFieldOpen(false);
                    }}
                    component={Autocomplete}
                    options={locationOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    noOptionsText={"Create a location in Settings > Locations"}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <MuiTextField
                        {...params}
                        error={touched["location"] && !!errors["location"]}
                        helperText={touched["location"] && errors["location"]}
                        label="Location"
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    label="Number of sets"
                    name="number_of_sets"
                    type="number"
                    variant="filled"
                    size="small"
                    fullWidth
                    inputProps={{
                      min: 1,
                      max: 999,
                    }}
                  />
                </Grid>
                </>}
                <Grid item xs={12} sm={6}>
                  <Field
                    component={DateTimePicker}
                    inputFormat="DD/MM/YY HH:mm"
                    ampm={false}
                    disablePast
                    name="start"
                    variant="inline"
                    renderInput={(params) => <MuiTextField {...params} variant="filled" label="Start" fullWidth helperText="Start date and time of FIRST occurence" />}
                    autoOk
                    helperText="Start date and time of FIRST occurence"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={DateTimePicker}
                    inputFormat="DD/MM/YY HH:mm"
                    ampm={false}
                    disablePast
                    name="end"
                    variant="inline"
                    inputVariant="filled"
                    renderInput={(params) => <MuiTextField {...params} variant="filled" label="End" fullWidth helperText="End date and time of FIRST occurence" />}
                    autoOk
                    helperText="End date and time of FIRST occurence"
                  />
                </Grid>
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                  Repeat every{" "}
                  <Field
                    component={TextField}
                    name="interval"
                    type="number"
                    size="small"
                    style={{
                      width: "50px",
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),
                    }}
                    inputProps={{
                      min: 1,
                      max: 999,
                      style: { textAlign: "center" },
                    }}
                  />
                  week(s) on
                  <Field
                    component={ToggleButtonGroup}
                    name="byDay"
                    type="checkbox"
                    size="small"
                    style={{marginLeft: theme.spacing(1)}}
                    // variant="inline"
                  >
                    {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                      (day, index) => (
                        <ToggleButton id={day} key={index} value={index}>
                          {day}
                        </ToggleButton>
                      )
                    )}
                  </Field>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Field
                    component={DatePicker}
                    label="From*"
                    name="from"
                    size="small"
                    variant="inline"
                    inputVariant="filled"
                    fullWidth
                    autoOk
                  />
                </Grid> */}
                {/* <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Field
                    component={DatePicker}
                    label="Until"
                    name="until"
                    size="small"
                    variant="inline"
                    inputVariant="filled"
                    disabled={repeatForever}
                    style={{ flex: "0 0 100%" }}
                    autoOk
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={repeatForever}
                        onChange={() => {
                          setRepeatForever(!repeatForever);
                        }}
                      />
                    }
                    label="Repeat forever"
                    size="small"
                  />
                </Grid> */}
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  {slot && (
                    <Button
                      onClick={deleteSlot}
                      style={{ marginRight: theme.spacing(1) }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    type="submit"
                    onSubmit={submitForm}
                    disabled={isSubmitting}
                  >
                    {slot ? "Update" : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </LocalizationProvider>
    </div>
  );
}

export default SlotCard;
