import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChatIcon from "@mui/icons-material/Chat";
import DescriptionIcon from "@mui/icons-material/Description";
import NotesIcon from "@mui/icons-material/Notes";
import PersonIcon from "@mui/icons-material/Person";
import RoomIcon from "@mui/icons-material/Room";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import LabelIcon from "@mui/icons-material/Label";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Grid, IconButton, Skeleton, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TagChip } from "../../../components/TagChip";
import { getReservationPrimaryActivity, getReservationTitle } from "../../../utils/reservation";
import ResourcesBullets from "./ResourcesBullets";
import { FileChip } from "../../../components/FileChip";
import EmailIcon from '@mui/icons-material/Email';
import BuildIcon from "@mui/icons-material/Build";
import PaletteIcon from '@mui/icons-material/Palette';

export function ReservationDetails({ reservation, editable, tagsControl, assignmentControl, colorControl }) {
  const currentReservation = reservation;
  const activity = getReservationPrimaryActivity(reservation);
  const resourceRequirements = currentReservation?.total_required_resources;

  const cardTitle = getReservationTitle(reservation);

  if (!currentReservation)
    return (
      <div>
        <Skeleton width="400px" height="100px" />
        <Skeleton width="400px" height="100px" />
        <Skeleton width="400px" height="100px" />
      </div>
    );

  return (
    <Grid container spacing={1} columns={12} alignItems="center">
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <Typography variant="h5" component="h2" display="inline" paragraph>
          {cardTitle + " "}
        </Typography>
        <Typography color="textSecondary" display="inline">
          {(currentReservation.activity?.code ? currentReservation.activity.code : "") +
            " × " +
            currentReservation.number_of_sets}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Reservation tags"><LabelIcon color="disabled" /></Tooltip>
      </Grid>
      <Grid item xs={11}>
        {tagsControl}
      </Grid>
      {/* <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        {currentReservation?.tags_detail.map((tag, index) => (
          <TagChip key={index} size="small" label={tag.name} color={tag.color} />
        ))}
      </Grid> */}
      {activity?.tags_detail.length > 0 && (
        <>
          <Grid item xs={1}>
            <Tooltip title="Activity tags"><LabelOutlinedIcon color="disabled" /></Tooltip>
          </Grid>
          <Grid item xs={11}>
            {activity?.tags_detail.map((tag, index) => (
              <TagChip key={index} size="small" label={tag.name} color={tag.color} />
            ))}
          </Grid>
        </>
      )}


      <Grid item xs={1}>
        <Tooltip title="Creation time"><AddBoxIcon color="disabled" /></Tooltip>
      </Grid>
      <Grid item xs={11}>
        <Grid container alignItems="center">
          <Typography color="textSecondary" variant="body2" gutterBottom>
            {`Created ${new Date(currentReservation.created).toLocaleString(undefined)}` +
              (currentReservation.is_late ? " (late) " : " ") +
              `by ${currentReservation.reserved_for_detail.name}`}
          </Typography>
          <Tooltip title="Email user">
            <IconButton href={`mailto:${currentReservation.reserved_for_detail.email}`}>
              <EmailIcon fontSize="small" color="disabled" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Location"><RoomIcon color="disabled" /></Tooltip>
      </Grid>
      <Grid item xs={11}>
        <Typography color="textSecondary" variant="body2" gutterBottom>
          {currentReservation.location_detail.name}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Duration"><AccessTimeIcon color="disabled" /></Tooltip>
      </Grid>
      <Grid item xs={11}>
        <Typography color="textSecondary" variant="body2" gutterBottom>
          {`${new Date(currentReservation.start).toLocaleString(undefined, {
            dateStyle: "medium",
            timeStyle: "short",
          })} - ${
            currentReservation.end &&
            new Date(currentReservation.end).toLocaleString(undefined, {
              dateStyle: "medium",
              timeStyle: "short",
            })
          }`}
        </Typography>
      </Grid>
      {resourceRequirements && (
        <>
          <Grid item xs={1}>
              <Tooltip title="Resources"><BuildIcon color="disabled" /></Tooltip>
          </Grid>
          <Grid item xs={11}>
            <ResourcesBullets resourceRequirements={resourceRequirements} />
          </Grid>
        </>
      )}
      {currentReservation.comment && (
        <>
          <Grid item xs={1}>
            <Tooltip title="Reservation comment"><ChatIcon color="disabled" /></Tooltip>
          </Grid>
          <Grid item xs={11}>
            {" "}
            <Typography component="p" color="textSecondary" variant="body2" gutterBottom>
              {currentReservation.comment}
            </Typography>
          </Grid>
        </>
      )}
      {activity && activity.comment && (
        <>
          <Grid item xs={1}>
            <Tooltip title="Activity comment"><NotesIcon color="disabled" /></Tooltip>
          </Grid>
          <Grid item xs={11}>
            <Typography component="p" color="textSecondary" variant="body2" gutterBottom>
              {activity.comment}
            </Typography>
          </Grid>
        </>
      )}
      {(currentReservation.attachments_detail?.length > 0 ||
        currentReservation.activities_detail[0]?.attachments_detail?.length > 0) && (
        <>
          <Grid item xs={1}>
            <Tooltip title="File"><DescriptionIcon color="disabled" /></Tooltip>
          </Grid>
          <Grid item xs={11}>
            {currentReservation.activities_detail[0]?.attachments_detail?.map((f, index) => (
              <FileChip attachment={f} key={index} />
              // <Link href={f.file} target="_blank" rel="noopener noreferrer" key={index}>
              //   <Chip
              //     clickable
              //     size="small"
              //     label={getFileName(f.file)}
              //     target="_blank"
              //     href={f.file}
              //   />
              // </Link>
            ))}
            {currentReservation.attachments_detail?.map((f, index) => (
              <Tooltip
                key={index}
                title={`Extra file added by ${currentReservation.reserved_for_detail.name}`}
                placement="bottom"
              >
                <FileChip attachment={f} key={index} color="primary" />
              </Tooltip>
            ))}
          </Grid>
        </>
      )}
      <Grid item xs={1}>
        <Tooltip title="Assigned to"><PersonIcon color="disabled" /></Tooltip>
      </Grid>
      <Grid item xs={11}>
        {assignmentControl}
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Custom colour"><PaletteIcon color="disabled" /></Tooltip>
      </Grid>
      <Grid item xs={11}>
        {colorControl}
      </Grid>
    </Grid>
  );
}
