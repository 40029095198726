import { Button, IconButton, Link, Tooltip } from "@mui/material";
import { Checkbox } from "formik-mui";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAxios } from "../../../hooks/useAxios";
import React from "react";
import { useHistory } from "react-router-dom";

function Invite({ handleClose, onSuccess }) {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { axiosInstance } = useAxios();
  const history = useHistory();

  const completeSetup = async () => {
    localStorage.setItem("showTour", JSON.stringify(true));
    localStorage.removeItem("setupStage");
    history.push("/scheduler");
    onSuccess();
  };

  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" gutterBottom>
            Invite colleagues
          </Typography>
        </Grid>
        <Grid>
          {pathname === "/settings/users" ? (
            <IconButton onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          ) : (
            <Link onClick={completeSetup} color="inherit" sx={{ cursor: "pointer" }}>
              I'll do this later
            </Link>
          )}
        </Grid>
      </Grid>

      <Typography gutterBottom>
        Share equipment reservations with colleagues from your school. Admins can invite users,
        record new templates for reserving equipment, and create tags for organising lessons and
        inventory.
      </Typography>
      <Formik
        initialValues={{
          colleagues: [
            { email: "", is_admin: false },
            // { email: "", is_admin: false },
          ],
        }}
        // validationSchema={Yup.object().shape({
        //   colleagues: Yup.array().of(
        //     Yup.object().shape({
        //       email: Yup.string()
        //         .email("Please enter a valid email address or delete this row.")
        //         // .required(
        //         //   "Please enter a colleague's email or skip this step using the link above."
        //         // ),
        //     })
        //   ),
        // })}
        onSubmit={async ({ colleagues }, { setFieldError }) => {
          try {
            const reservePayload = colleagues.filter(
              (colleague) =>
                colleague.email && {
                  email: colleague.email,
                  is_admin: colleague.is_admin,
                }
            );
            const res = await axiosInstance.post("auth/invite_user/", reservePayload);
            console.log(res.data);
            //loop through each returned email
            // for (let invitee of res.data) {
            //   window.analytics.track("Invite Sent", {
            //     "properties": {
            //       "invitee_email": invitee.email,
            //       "invitee_is_admin": invitee.is_admin
            //     }
            //   });
            // }

            // TODO: fix this auth legacy thing.
            // if ( authState.setupStage === 0 ){
            //   dispatch({ type: "SET_SETUP_STAGE", data: 1 })
            // }
            enqueueSnackbar("Invites sent", { variant: "success" });
            if (pathname !== "/settings/users") {
              completeSetup();
            } else {
              handleClose();
            }
          } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 400) {
              error.response.data.map((el, index) =>
                setFieldError(`colleagues.${index}.email`, el.email && el.email[0])
              );
            } else {
              console.log(error);
            }
          }
        }}
      >
        {({ values, submitForm, isSubmitting }) => (
          <Form>
            <FieldArray
              name="colleagues"
              render={(arrayHelpers) => (
                <>
                  <Grid container spacing={1}>
                    {values.colleagues.map((colleague, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={8}>
                          <Field
                            label={`Colleague's work email`}
                            component={TextField}
                            fullWidth
                            name={`colleagues.${index}.email`}
                          />
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "center" }}>
                          <Field
                            type="checkbox"
                            component={Checkbox}
                            name={`colleagues.${index}.is_admin`}
                          />
                          <Typography variant="overline">Admin </Typography>
                          <Tooltip title="Technicians, heads of department, and staff involved in purchasing or stock management require admin privileges">
                            <HelpOutlineIcon fontSize="small" />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            // remove a room from the list
                            onClick={() => arrayHelpers.remove(index)}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                  <br />
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => arrayHelpers.push({ email: "", is_admin: false })} // insert an empty string at a position
                  >
                    Add more people
                  </Button>
                </>
              )}
            />
            <Button
              onClick={submitForm}
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              style={{ float: "right" }}
            >
              {isSubmitting ? "Loading..." : "Finish"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Invite;
