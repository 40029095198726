import { useState } from "react";
import { TagsAutocomplete } from "./Autocomplete";
import * as Sentry from "@sentry/react";

export function InlineTagsControl({
  initialTags,
  apiUpdateFunction,
  sendMessage,
  ...passThroughProps
}) {
  const tagsWithIds = initialTags.map((tag) => {
    if (tag.id) return tag;
    // assume input is a list of ids
    return { id: tag };
  });
  const [value, setValue] = useState(tagsWithIds || "Add tags");
  const handleChange = async (newValue) => {
    setValue(newValue);
    try {
      const payload = {
        tags: newValue.map((t) => t.id),
      };
      await apiUpdateFunction(payload);
      sendMessage("Tags updated");
    } catch (error) {
      sendMessage("An error occurred.", { variant: "error" });
      Sentry.captureException(error, { extra: { response: error.response } });
    }
  };

  return (
    <TagsAutocomplete
      label={"Edit Reservation Tags"}
      variant="filled"
      value={value}
      {...passThroughProps}
      onChange={handleChange}
      disableClearable
      size="small"
      tagProps={{size: "small"}}
    />
  );
}
