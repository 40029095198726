export const navLabels = [
  { id: 1, title: "Scheduler", path:"/scheduler", target: undefined, icon: "calendar", className: undefined },
  { id: 2, title: "Practicals", path:"/practicals", target: undefined, icon: "flask", className: undefined },
  { id: 3, title: "Equipment", path: "/equipment", target: undefined, icon: "box-open", className: undefined },
  // { id: 4, title: "Shop", path: `${process.env.REACT_APP_MARKETPLACE_URL}pages/auto-login`, target: '_blank', icon: "store", className: "tour-step-5"},
  { id: 4, title: "Settings", path: "/settings", target: undefined, icon: "cog", className: undefined,
  children: [
    {
        id: 5,
        title: 'My account',
        type: 'item',
        path: '/settings/my-account', //from settings/my-school
        target: undefined,
    },
    {
        id: 6,
        title: 'My school',
        type: 'item',
        path: '/settings/my-school', //from settings/my-school
        target: undefined,
    },
    {
      id: 7,
      title: 'Users',
      type: 'item',
      path: '/settings/users', //from settings/my-school
      target: undefined,
    },
    {
      id: 8,
      title: 'Locations',
      type: 'item',
      path: '/settings/locations', //from settings/my-school
      target: undefined,
    },
    {
      id: 9,
      title: 'Tags',
      type: 'item',
      path: '/settings/tags', //from settings/my-school
      target: undefined,
    },
    {
      id: 10,
      title: 'My Timetable',
      type: 'item',
      path: '/settings/user-reservation-slots', //from settings/my-school
      target: undefined,
    },
    {
      id: 11,
      title: 'School Timetable',
      type: 'item',
      path: '/settings/school-reservation-slots', //from settings/my-school
      target: undefined,
    }
]
}
];
