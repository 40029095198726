import { Button, Chip, Dialog, IconButton, useMediaQuery, useTheme, Link, Typography, Tooltip } from "@mui/material";
// import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAxios } from "../../hooks/useAxios";
import { deleteSelected, get } from "../../redux/data";
import Invite from "../signup/containers/Invite.five";
import { ActiveSchoolsContext } from "../../contexts/activeSchools.context";

function Users() {
  // const classes = useStyles();
  const users = useSelector((state) => state.users.data)
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const {axiosInstance} = useAxios()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { activeProfile } = useContext(ActiveSchoolsContext)
  const isAdmin = activeProfile?.is_admin;

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const makeAdmin = async (id, permission) => {
    try {
      await axiosInstance.patch(
        `auth/userprofiles/${id}/`,
        { [permission]: true }
      );
      enqueueSnackbar("Privileges updated", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Unable to update privileges", { variant: "error" });
      console.log(e);
    }
  };

  const deleteAssignee = async (id) => {
    try {
      await axiosInstance.patch(
        `auth/userprofiles/${id}/`,
        { default_reservation_assignee: false }
      );
      enqueueSnackbar("Privileges updated", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Unable to update privileges", { variant: "error" });
      console.log(e);
    }
  };

  const handleDelete = async (usersToDelete) => {
    const idsToDelete = usersToDelete.map((u) => u.id);
    await Promise.all(idsToDelete.map((id) => axiosInstance.delete(`auth/userprofiles/${id}/`)));
    enqueueSnackbar(`User(s): ${usersToDelete.map(u=>`${u.user.given_name} ${u.user.family_name}`).join(", ")} removed from school account.`);
    dispatch(deleteSelected({ name: "users", data: idsToDelete }));
  };
  

  const columns = [
      {
        name: "id",
        label: "ID",
        options: { display: "excluded", filter: false },
      },
      { name: "user_detail.name", label: "Name", options: { filter: false } },
      { name: "user_detail.email", label: "Email", options: { filter: false } },
      {
        name: "default_reservation_assignee",
        label: <Tooltip title="Automatically assigns reservations to user on creation. This makes reservations appear in the user's 'My week' and 'My day' views" placement="bottom"><Typography variant='body2' sx={{fontFamily: 'Raleway'}}>Auto-assign reservations</Typography></Tooltip>,
        options: {
          sort: false,
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) =>
            value ? (
              <Chip label="Default assignee" {... (isAdmin && {onDelete: ()=>{deleteAssignee(tableMeta.rowData[0]); updateValue(false);}})} />
            ) : (
              isAdmin && (
                <Link
                  onClick={() => {
                    makeAdmin(tableMeta.rowData[0], "default_reservation_assignee");
                    updateValue(true);
                  }}
                >
                  Make default reservation assignee
                </Link>
              )
            ),
        },
      },
      {
        name: "is_admin",
        label: "Permissions",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) =>
            value ? (
              <Chip label="Admin" />
            ) : (
              isAdmin && (
                <Link
                  onClick={() => {
                    makeAdmin(tableMeta.rowData[0], "is_admin");
                    updateValue(true);
                  }}
                >
                  Make admin
                </Link>
              )
            ),
        },
      },
    ];

  useEffect(() => {
    const getUsers = async () => {
      const response = await axiosInstance.get("auth/userprofiles/");
      dispatch(get({ name: "users", data: response.data }));
    };
    getUsers();
  }, [dispatch, axiosInstance]);

  const options = {
    elevation: 0,
    selectableRows: isAdmin ? "multiple" : "none",
    onRowsDelete: (rowsDeleted) => {
      const usersSelected = rowsDeleted.data.map((d) => users[d.dataIndex]);
      const unableToDelete = usersSelected.filter(u=>u.is_admin)
      console.log(unableToDelete)
      const usersToDelete = usersSelected.filter(u=>!u.is_admin)
  
      if(unableToDelete.length > 0){
        enqueueSnackbar(`Unable to delete user(s): ${unableToDelete.map(u=>`${u.user.given_name} ${u.user.family_name}`).join(", ")}. Admins have equal privileges to you and cannot be deleted. Please email support@bunsen.education to request deletion.`, { variant: "error" });
        return false;
      }

      if(usersToDelete.length > 0){
        handleDelete(usersToDelete);
      }
    },
    enableNestedDataAccess: ".",
    print: false,
    download: false,
    viewColumns: !isMobile,
    customToolbar: () =>
      isAdmin &&
      (isMobile ? (
        <IconButton onClick={handleClickOpen} size="large">
          <AddIcon />
        </IconButton>
      ) : (
        <Button onClick={handleClickOpen} variant="contained" color="primary">
          Invite users
        </Button>
      )),
  };

  return (
    <>
      <MUIDataTable
        title={"Manage users"}
        data={users}
        columns={columns}
        options={options}
        style={{ zIndex: 0 }}
      />
      <Dialog
        fullScreen={isMobile}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: theme.spacing(3),
          },
        }}
        width="lg"
      >
        <Invite handleClose={handleClose}/>
      </Dialog>
    </>
  );
}

export default Users;
