import { api } from "../api";
import { actions } from "./smileConfig";
import * as Sentry from "@sentry/react";

export async function sendAction(token) {
  try {
    await api.rewards.post({ token });
  } catch (error) {
    if (error.response.status === 404) {
      console.log("rewards user not set");
    } else {
      Sentry.captureException(error, { extra: { response: error.response } });
      console.log("rewards error: ", error);
    }
  }
}

export function sendReservationAction() {
  return sendAction(actions.createReservation);
}

export function getPoints() {
  return api.rewards.list();
}

export function setId() {
  return api.rewards.setId();
}
