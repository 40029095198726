import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Invite from "./containers/Invite.five";
import JoinOrCreateSchool from "./containers/JoinOrCreateSchool.zero";
import Locations from "./containers/Locations.three";
import RequestInvite from "./containers/RequestInvite";
import Stepper from "./Stepper";
import SignUpBackground from "./SignUpBackground";
import CreateSchool from "./containers/CreateSchool";

function Setup({ onSuccess }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentStep, setCurrentStep] = useState(parseInt(localStorage.getItem("setupStage")) || 0);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const toRequestInvite = () => {
    setCurrentStep(-1);
  };

  const toJoinOrCreateSchool = () => {
    setCurrentStep(0);
    localStorage.setItem("setupStage", 0);
  };

  useEffect(() => {
    localStorage.setItem("setupStage", currentStep);
  }, [currentStep]);

  const renderForm = (currentStep) => {
    switch (currentStep) {
      case -1:
        return <RequestInvite nextStep={toJoinOrCreateSchool} />;
      case 0:
        return <JoinOrCreateSchool nextStep={nextStep} toRequestInvite={toRequestInvite} />;
      case 1:
        return <CreateSchool nextStep={nextStep} previousStep={previousStep} />;
      case 2:
        return <Locations nextStep={nextStep} previousStep={previousStep} />;
      case 3:
        return <Invite previousStep={previousStep} onSuccess={onSuccess} />;
      default:
        return <Redirect to="/signup" />;
    }
  };

  return (
    <div>
      <SignUpBackground />
      <Dialog fullScreen={fullScreen} open={true} width="lg">
        <DialogContent>{renderForm(currentStep)}</DialogContent>
        <DialogActions>
          <Stepper currentStep={currentStep - 1} nextStep={nextStep} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Setup;
