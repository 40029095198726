import { Button, Dialog, Grid, Typography, useMediaQuery, useTheme, FormHelperText, Link } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useAxios } from '../../../hooks/useAxios'
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { CheckboxWithLabel } from 'formik-mui';
import { useHistory } from "react-router-dom";

export function SubmitNameModal({onSuccess}) {

    const {axiosInstance} = useAxios()
    const [open, setOpen] = useState(true)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();

    const handleClose = () => {
      setOpen(false);
    };

    const onSubmit = async (values) => {
      try {
        await axiosInstance.patch("auth/me/", values)
        localStorage.setItem("showTour", JSON.stringify(true));
        await onSuccess()
        history.push("/scheduler");
      } catch (error) {
      }
    }

    return (
      <Dialog
        fullScreen={isMobile}
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            padding: theme.spacing(3),
          },
        }}
        width="lg"> 
          <Typography gutterBottom variant="h6">
              Please enter your full name
          </Typography>
          <Typography gutterBottom>
              Your name's initials will be used as an identifier throughout the app
          </Typography>
          <Formik
            initialValues={{
              given_name: "",
              family_name: "",
              terms_accepted: false
            }}
            validationSchema={Yup.object({
              given_name: Yup.string().required("Please enter your first name."),
              family_name: Yup.string().required("Please enter your last name."),
              terms_accepted: Yup.bool().oneOf([true], 'You must accept the terms of service to proceed.')
            })}
            onSubmit={onSubmit}
          >
            {({ submitForm, isSubmitting, touched, errors, setValues }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label="Forename(s)"
                      name="given_name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label="Surname"
                      name="family_name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="terms_accepted"
                        Label={{ label: <Typography>By checking this box, I agree to the <Link href="https://app.termly.io/document/terms-and-conditions/29bd5d6b-6232-48a6-a92c-303e074cd061" target="_blank" rel="noopener noreferrer">terms of service</Link></Typography> }}
                      />
                      {(touched["terms_accepted"] && !!errors["terms_accepted"]) ? <FormHelperText error>{errors["terms_accepted"]}</FormHelperText> : null}      
                  </Grid>
                </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{ marginTop: theme.spacing(2),float: "right" }}
                  >
                    Submit
                  </Button>
              </Form>
            )}
          </Formik>
      </Dialog>
    );
}
