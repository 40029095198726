import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { ApiContext } from "../../api/api.context";
import * as Sentry from "@sentry/react";

function ImportModal({ closeModal }) {
  const api = useContext(ApiContext);

  const RootStyle = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
  }));

  let files = []
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (newFiles) => {
    // newFiles = newFiles.filter(
    //   (file) => !files.find((f) => f.data === file.data)
    // );
    // setFiles(newFiles);
    files = newFiles
  };

  const onSubmit = async () => {
    console.log("submit");
    try {
      setLoading(true);
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);
        await api.imports.post(formData);
      }
      enqueueSnackbar("Request submitted", { variant: "success" });
      closeModal();
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar("Request failed", { variant: "error" });
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <RootStyle>
      <Typography variant="h6" gutterBottom>
        Bulk upload
      </Typography>
      <Typography gutterBottom>
        If you have a spreadsheet with details of your practicals or equipment, you can drop them in the dropzone below to queue them for bulk upload. Please format your spreadsheet with columns that at least loosely correspond to "Name", "Code", "Tags". We cannot guarantee that all files are importable by this method, especially if they are not formatted accordingly.
      </Typography>
      <DropzoneArea
        dropzoneText="Drag and drop files here or click to upload"
        maxFileSize={10000000}
        useChipsForPreview
        showFileNames
        // showAlerts={false}
        onChange={handleChange}
        acceptedFiles={[
          "image/*",
          ".doc",
          ".docx",
          ".odt",
          ".pages",
          ".key",
          ".numbers",
          ".pdf",
          ".rtf",
          ".ppt",
          ".pptx",
          ".ods",
          ".xls",
          ".xlsx",
          ".csv",
          "odp",
          ".txt",
          ".md",
        ]}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        disabled={loading}
        style={{ marginTop: "16px" }}
      >
        {loading ? "Loading..." : "Submit"}
      </Button>
    </RootStyle>
  );
}

export default ImportModal;
