import { Button, Grid } from "@mui/material";
import * as Sentry from "@sentry/react";
import { TextField } from "formik-mui";

import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import useAttachmentUpload from "../../hooks/useAttachmentUpload";
import { TagsAutocomplete } from "../Autocomplete";

import { ResourceInput } from "../ResourceInput";
import { ApiContext } from "../../api/api.context";
import { useContext, useMemo } from "react";

const defaultInitialValues = {
  name: "",
  code: "",
  tags: [],
  comment: "",
  resource_requirements: [],
};

function formatInitialValues(initialValues) {
  const formattedValues = { ...initialValues };

  formattedValues.resource_requirements = initialValues.resource_requirements_detail?.map((x) => ({
    resource: {
      id: x.resource,
      units: x.resource_units,
      name: x.resource_name,
    },
    quantity: x.quantity_per_set || "",
  }));
  formattedValues.resource_requirements = formattedValues.resource_requirements || [];

  if (initialValues.tags_detail) formattedValues.tags = initialValues.tags_detail;
  else formattedValues.tags = initialValues.tags?.map((id) => ({ id }));
  formattedValues.tags = formattedValues.tags || [];

  if (initialValues.attachments_detail)
    formattedValues.files = initialValues.attachments_detail?.map((x) => ({
      name: x.file.split("/").pop().split("?")[0], // remove path and query params.
      id: x.id,
    }));

  console.log("formatted values: ", formattedValues);

  return formattedValues;
}

export function ActivityForm({ initialValues, onSuccess, apiSubmitFunction, submitButtonText }) {
  const formattedInitialValues = useMemo(
    () => initialValues && formatInitialValues(initialValues),
    [initialValues]
  );

  const api = useContext(ApiContext);
  const { enqueueSnackbar } = useSnackbar();
  const [files, fileChips, dropzone, setDropzoneOpen] = useAttachmentUpload(
    formattedInitialValues?.files
  );

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter an activity name."),
    code: Yup.string().nullable(),
    comment: Yup.string().max(1000, "Comment must be shorter than 1000 characters").nullable(),
    resource_requirements: Yup.array().of(
      Yup.object({
        quantity: Yup.number(),
        resource: Yup.object(),
      }).test(
        "no-lone-quantities",
        "Resource is required if quantity is specified",
        (value, context) => {
          const requirementIsValid = value.quantity ? value.resource?.id : true;
          if (!requirementIsValid) {
            enqueueSnackbar("Resource is required if quantity is specified", {
              variant: "error",
              preventDuplicate: true,
            });
          }
          return requirementIsValid;
        }
      )
    ),
  });

  const onSubmit = async (values, { setFieldError, resetForm }) => {
    const valuesToSubmit = { ...values };

    //format tags
    valuesToSubmit["tags"] = values.tags.map((tag) => tag && tag.id);

    //format resource requirements
    valuesToSubmit.resource_requirements = values.resource_requirements.filter(
      (req) => req.resource && req.resource.id
    );
    valuesToSubmit.resource_requirements = valuesToSubmit.resource_requirements.map((req) => ({
      resource: req.resource.id,
      quantity_per_set: req.quantity || undefined,
    }));

    //upload file attachment and insert id into valuesToSubmit
    valuesToSubmit.attachments = [];
    if (files.length > 0) {
      valuesToSubmit.attachments = [];
      for (const file of files) {
        if (file.id) {
          valuesToSubmit.attachments.push(file.id);
          continue;
        }
        const formData = new FormData();
        console.log(files);
        formData.append("file", file);
        console.log(formData.values());
        const uploadResponse = await api.uploads.post(formData);
        valuesToSubmit.attachments.push(uploadResponse.data.id);
      }
    }

    try {
      await apiSubmitFunction(valuesToSubmit);
      enqueueSnackbar("Activity created", { variant: "success" });
      onSuccess();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("An error occurred. Activity not created.", { variant: "error" });
      Sentry.captureException(error, { extra: { valuesToSubmit, response: error.response } });
    }
  };

  return (
    <Formik
      initialValues={formattedInitialValues || defaultInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
        <Form>
          <Grid container columns={6} spacing={2} rowSpacing={1} alignItems="center">
            <Grid item xs={6}>
              <Field component={TextField} label="Name" name="name" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Field component={TextField} label="Code" name="code" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TagsAutocomplete}
                name="tags"
                value={values.tags}
                onChange={(value) => setFieldValue("tags", value)}
                sendMessage={enqueueSnackbar}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                multiline={true}
                label="Comment"
                name="comment"
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                component={ResourceInput}
                name="resource_requirements"
                value={values.resource_requirements}
                onChange={(value) => setFieldValue("resource_requirements", value)}
                sendMessage={enqueueSnackbar}
              />
            </Grid>
            {fileChips.length > 0 && (
              <Grid item xs={6}>
                {fileChips}
              </Grid>
            )}
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setDropzoneOpen(true)}
                sx={{ width: "100%" }}
              >
                Add file attachment
              </Button>
              {dropzone}
            </Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onSubmit={submitForm}
                sx={{ width: "100%" }}
              >
                {submitButtonText || "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
