import { CircularProgress, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign:'center',
        padding: theme.spacing(3)
    },
    loading: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
}));

function NotFound() {
    const theme = useTheme()
    const classes = useStyles(theme);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(()=>setLoading(false), 1000)
    }, [])

    return (
        loading ? <CircularProgress size={80} className={classes.loading} /> :
        (<div className={classes.root}>
            <Typography variant="h5" gutterBottom>Sorry! Page not found</Typography>
            <Typography>The page you are looking for could not be found. It may have been deleted or moved.</Typography>
            <Typography gutterBottom>NB: The login page has moved. Please change your bookmark to "https://app.bunsen.education/scheduler"</Typography>
            <Typography component={Link} color="primary" to={"/scheduler"}>Return to login</Typography>        
        </div>)
    )
}

export default NotFound
