export const ENABLE_POINTS = "enablePoints";

const flagDefaults = {};
flagDefaults[ENABLE_POINTS] = false;

export function setFeatureFlagsLocalStorage() {
  const url = new URL(window.location.href);

  const params = url.searchParams;
  console.log(url, params);
  Object.keys(flagDefaults).forEach((name) => {
    const urlValue = params.get(name);
    console.log(name, urlValue);
    if (urlValue !== null) {
      window.localStorage.setItem(name, JSON.stringify(urlValue));
    }
  });
}

export function getFeatureFlag(name) {
  const localValue = window.localStorage.getItem(name);
  if (localValue) return JSON.parse(localValue);
  return flagDefaults[name];
}
