import styled from "@emotion/styled";
import { AssignmentInd } from "@mui/icons-material";
import ChatIcon from "@mui/icons-material/Chat";
import { Box, Hidden, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { ActiveSchoolsContext } from "../../../contexts/activeSchools.context";
import { getReservationPrimaryActivity } from "../../../utils/reservation";
import { getInitials } from "../../../utils/user";
import { ReservationTagsControl } from "./ReservationTagsControl";
import { alpha } from "@mui/material";

const ResponsiveText = (props) => (
  // using MUI built in responsiveness.
  <Typography display="inline" fontSize={{ xs: "11px", sm: "11.9px" }} noWrap {...props} />
);

const StyledTagsArea = styled(Stack)(({ theme }) => ({
  width: '100%',
  borderRadius: '3px',
  backgroundColor:alpha('#000000', 0),
  '&:hover': {
    background: alpha('#000000', 0.25),
 },
}));


function ReservationContent({ eventInfo, mutate, tags, textColor }) {
  const { user } = useContext(ActiveSchoolsContext);
// 
  const reservation = eventInfo.event.extendedProps.reservation;
  const onClick = eventInfo.event.extendedProps.onClick;

  const assignedToMe = reservation.assigned_to.includes(user.id);

  const initials = getInitials(reservation.reserved_for_detail.name);
  const activity = getReservationPrimaryActivity(reservation);
  const activityText = `${reservation.number_of_sets} \u00D7 ${eventInfo.event.title}`;

  //TAGS
  const activityTags = activity?.tags_detail.map(t=>({...t, type: 'Activity tag'})) || [];
  const reservationTags = reservation?.tags_detail.map(t=>({...t, type: 'Reservation tag'})) || [];
  const allTags = [...reservationTags, ...activityTags]
  
  const TAG_HEIGHT=15

  const [tagsAutocompleteOpen, setTagsAutocompleteOpen] = useState(false)


  if (!reservation) return null;
  return (
    <Box
      padding={{ xs: 0.2, sm: 0.5 }}
      boxSizing="border-box"
      position="relative"
      overflow="hidden"
      height="100%"
      onClick={(event) => {
        console.log("parent clicked");
        onClick();
      }}
    >
      {/* ICONS */}

      <Box position="absolute" right={"4px"} sx={{ fontSize: { xs: "11px", sm: "11.9px" } }}>
        <Hidden smDown>
          {assignedToMe && <AssignmentInd fontSize="inherit" />}
          {reservation.comment && <ChatIcon fontSize="inherit" />}
        </Hidden>
      </Box>



      {/* TEXT */}
      <Stack spacing={0.5} alignItems="flex-start">
        <Box sx={{color: textColor}}>
          <ResponsiveText>
            {initials}
            {" • "}
          </ResponsiveText>
          {eventInfo.view.type !== "resourceTimelineDay" && (
            <ResponsiveText>{reservation.location_detail.name}</ResponsiveText>
          )}

          <Typography
            display={eventInfo.view.type === "resourceTimelineDay" && "inline"}
            noWrap={eventInfo.view.type === "resourceTimelineDay" ? false : [...reservationTags, ...activityTags].length > 0}
            sx={{ fontWeight: 550 }}
            fontSize={{ xs: "11px", sm: "11.9px" }}
          >
            {activityText}
          </Typography>
        </Box>

        {/* TAGS */}
          <StyledTagsArea direction="row" flexWrap="wrap" spacing={0} onClick={(e)=>{e.stopPropagation(); setTagsAutocompleteOpen(true);}}>
            <ReservationTagsControl 
              initialValues={allTags} 
              mutate={mutate} 
              tags={tags}
              responsiveTags
              reservation={reservation} 
              open={tagsAutocompleteOpen} 
              setOpen={setTagsAutocompleteOpen}
              sx={{height: TAG_HEIGHT, }}  
              deleteIconProps={{height: TAG_HEIGHT}}
              InputProps={{style:{padding:1, fontSize:12}}} 
            />
          </StyledTagsArea>


      </Stack>
    </Box>
  );
}

export default ReservationContent;

