import { useEffect, useState } from "react";
import { auth0Instance } from "./auth0";
import { useHistory } from "react-router-dom";
import { LoaderWithMessage } from "../components/LoaderWithMessage";
import { setFeatureFlagsLocalStorage } from "../utils/featureFlags";
import * as Sentry from "@sentry/react";

export function LoginRequired({ children, signup }) {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(auth0Instance.isAuthenticated());
  const history = useHistory();

  useEffect(() => setFeatureFlagsLocalStorage(), []);

  useEffect(() => {
    setLoading(true);
    const appInit = async () => {
      console.log(window.location);
      try {
        await auth0Instance.handleAppInit(!!signup); // On first run, this redirects to Auth0 login page.
        setIsAuthenticated(auth0Instance.isAuthenticated());
        history.push(auth0Instance.getRedirectPath());
      } catch (error) {
        Sentry.captureException(error, { extra: { response: error.response } });
        history.push("/login-failed");
      } finally {
        setLoading(false);
      }
    };
    appInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderWithMessage message="Checking credentials..." />;
  if (!isAuthenticated)
    return <LoaderWithMessage message="Redirecting to authentication server..." />;
  return children;
}
