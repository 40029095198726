import { TextField, InputAdornment, Grid, IconButton, Button, Tooltip } from "@mui/material";
import { ResourcesAutocomplete } from "./Autocomplete/ResourcesAutocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
// import { useState } from "react";

function SingleResourceInput({ onChange, value, onDelete, sendMessage }) {
  const onChangeResource = (resource) => {
    // console.log("onChangeResource", resource);
    onChange({ ...value, resource });
  };

  const onChangeQuantity = (event) => {
    const quantity = event.target.value;
    // console.log("onChangeQantiti", quantity);
    onChange({ ...value, quantity });
  };

  return (
    <>
      <Grid item xs={6}>
        <ResourcesAutocomplete onChange={onChangeResource} value={value.resource} sendMessage={sendMessage} />
      </Grid>
      <Grid item xs={4}>
        <TextField
          onChange={onChangeQuantity}
          value={value.quantity}
          label="Quantity per set"
          type="number"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: value && (
              <InputAdornment position="end">{value?.resource?.units}</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={onDelete} size="large">
          <DeleteIcon />
        </IconButton>
      </Grid>
    </>
  );
}

export function ResourceInput(props) {
  const { value, onChange, sendMessage } = props;

  const onChangeResource = (newResourceValue, index) => {
    console.log(newResourceValue, index);
    const newValueArray = [...value];
    newValueArray[index] = newResourceValue;
    onChange(newValueArray);
  };

  const onDelete = (index) => {
    const newValueArray = [...value];
    newValueArray.splice(index, 1);
    onChange(newValueArray);
  };

  const onAdd = () => {
    onChange([...value, { resource: { name: "" }, quantity: "" }]);
  };
  return (
    <Grid container columns={12} rowSpacing={1} spacing={2} alignItems="center">
      {value.map((v, index) => (
        <SingleResourceInput
          sendMessage={sendMessage}
          value={v}
          onChange={(value) => onChangeResource(value, index)}
          onDelete={() => onDelete(index)}
          key={index}
        />
      ))}
      <Grid item xs={8}>
        <Button startIcon={<AddCircleOutlineIcon />} onClick={onAdd}>
          {value.length > 0 ? "Add more equipment" : "Add equipment"}
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Tooltip
          title="If equipment is attached to a practical, Bunsen will check if enough equipment is available before a reservation is made. Record equipment stock levels in 'inventory'"
          placement="top"
        >
          <InfoIcon size="small" color="disabled" />
        </Tooltip>
      </Grid>
    </Grid>
  );
}
