import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";

import { SchoolSwitcher } from "../../SchoolSwitcher";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(1), marginRight: theme.spacing(1) },

  // root: {
  //   // margin: theme.spacing(2),
  //   position: 'absolute',
  //   top: 8,
  //   right: 8,
  //   [theme.breakpoints.up("sm")]: { color: "black" },
  // },
}));

function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span className={classes.root}>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        size="large"
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <SchoolSwitcher />
        </MenuItem>
        <MenuItem component={NavLink} to="/settings/my-account">
          Settings
        </MenuItem>
      </Menu>
    </span>
  );
}

export default AccountMenu;
