import { Box, Typography, Button, Stack } from "@mui/material";

export function LoginFailed(){
    const onClick = ()=>{
        window.location = '/'
    }
    return(
        <Box textAlign={'center'} marginTop='5rem'>
            <Stack spacing={2} alignItems='center'>
            <Typography variant='body1'>
                Looks like something went wrong during authentication.
            </Typography>
            <Button onClick={onClick} variant='contained'>Try again</Button>
            <Typography variant='body1'>
                If you continue to experience problems, please contact <br/>
                support@bunsen.education
            </Typography>
            </Stack>
        </Box>
    )
}