const activeSchoolsReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      // console.log(action.data);
      return {
        ...state,
        user: action.data,
        userprofiles: action.data.userprofiles,
      };
    case "SET_ACTIVE_SCHOOL":
      // console.log(action.data);
      return {
        ...state,
        activeSchoolID: action.data,
      };
    default:
      return state;
  }
};

export default activeSchoolsReducer;
