import {
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import * as Yup from "yup";
import { useAxios } from "../../../hooks/useAxios";

function Locations({ nextStep }) {
  const theme = useTheme();
  const { axiosInstance } = useAxios()

  return (
    <div>
      <Typography gutterBottom variant="h6">
        Where do lessons or activities take place?
      </Typography>
      <Typography gutterBottom>
        You can add more locations in Settings > 'My School'.
      </Typography>
      <Formik
        initialValues={{
          locations: [
            { name: "Lab 1" },
            { name: "Classroom A" },
            { name: "Football pitch" },
          ],
        }}
        validationSchema={Yup.object().shape({
          locations: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required(
                "Please enter a location name or delete this field using the button on the right."
              ),
            })
          ),
        })}
        onSubmit={async ({ locations }, { setSubmitting }) => {
          await axiosInstance.post("locations/", locations);
          nextStep();
        }}
      >
        {({ values, submitForm, isSubmitting }) => (
          <Form>
            <FormControl>
              <FieldArray
                name="locations"
                render={(arrayHelpers) => (
                  <div>
                    <Grid container spacing={3}>
                      {values.locations.map((location, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={10}>
                            <Field
                              label={`Location ${index + 1}`}
                              component={TextField}
                              fullWidth
                              name={`locations.${index}.name`}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              // remove a location from the list
                              onClick={() => arrayHelpers.remove(index)}
                              size="large">
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                    <br />
                    <Grid item>
                      <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => arrayHelpers.push({ name: "" })} // insert an empty string at a position
                      >
                        Add new location
                      </Button>
                    </Grid>
                  </div>
                )}
              />
            </FormControl>
            <Button
              onClick={submitForm}
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              style={{ float: "right", marginTop: theme.spacing(2) }}
            >
              {isSubmitting ? "Loading..." : "Next"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Locations;
