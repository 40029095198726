import { createContext, useReducer } from "react";
import { setSchoolID } from "../api";
import activeSchoolsReducer from "../reducers/activeSchools.reducer";

const initialState = {
  userprofiles: [],
  user: undefined,
  activeSchoolID: undefined,
};

export const ActiveSchoolsContext = createContext();

function getActiveSchool(userProfiles, activeSchoolID) {
  const activeProfiles = userProfiles.filter((x) => x.school.id === activeSchoolID);
  return activeProfiles.length > 0 && activeProfiles[0];
}

export function ActiveSchoolsProvider(props) {
  const [activeSchoolsState, dispatch] = useReducer(activeSchoolsReducer, initialState);
  const { userprofiles, activeSchoolID, user } = activeSchoolsState;

  // let the api know what the active school is. This pattern can be improved.
  setSchoolID(activeSchoolID);

  if (activeSchoolID === undefined && userprofiles.length > 0) {
    dispatch({ type: "SET_ACTIVE_SCHOOL", data: userprofiles[0].school.id });
  }

  const activeSchool = activeSchoolID && getActiveSchool(userprofiles, activeSchoolID);
  const activeProfile = activeSchool; // TODO: rename active school -> active profile everwhere.

  return (
    <ActiveSchoolsContext.Provider
      value={{
        activeSchool,
        activeSchoolID,
        activeSchoolsState,
        activeProfile,
        user,
        dispatch,
      }}
    >
      {props.children}
    </ActiveSchoolsContext.Provider>
  );
}
