import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { ApiContext } from "../../../api/api.context";
import * as Sentry from "@sentry/react";
import { ColorSelector } from "../../../components/ColorSelector";
import { themeTagColors } from "../../../theme";

export function ColorControl({ reservation, disabled, ...passThroughProps }) {
  const api = useContext(ApiContext);
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const [color, setColor] = useState(reservation?.color || []);


  const handleColorChange = async (newValue) => {
    reservation.color = newValue;
    try {
      await api.reservations.put(reservation.id, reservation);
      setColor(newValue);
      enqueueSnackbar("Reservation updated");
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response && error.response.data.detail) {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("An error has occurred. Contact support@bunsen.education.", {
          variant: "error",
        });
      }
    }
  };

  return (
    <ColorSelector
      fullWidth
      label="Custom colour"
      options={themeTagColors}
      size="small"
      disabled={disabled}
      value={color}
      onChange={handleColorChange}
      textFieldProps={{variant: "filled"}}
      InputProps={{disableUnderline: true}}
      {...passThroughProps}
    />
  );
}
