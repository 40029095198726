import { Typography } from "@mui/material";
import { getVersionString } from "../utils/version";

export function VersionIndicator() {
  const versionString = getVersionString();
  return (
    <Typography color="#999" fontSize={"80%"}>
      version {versionString}
    </Typography>
  );
}
