import makeStyles from "@mui/styles/makeStyles";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";
import { ApiContext } from "../../api/api.context";
import { ActiveSchoolsContext } from "../../contexts/activeSchools.context";
import useDrawer from "../../hooks/useDrawer";
import { useTimer } from "../../hooks/useTimer";
import Scheduler from "./components/Scheduler";
import { SchedulerFilter } from "./components/SchedulerFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    [theme.breakpoints.up("sm")]: { 
      padding: theme.spacing(2),
      maxWidth: `calc(100vw - ${theme.drawerWidth}px)`
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(7),
    },
  },
  loading: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export function SchedulerContainer() {
  const history = useHistory();
  const api = useContext(ApiContext);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { activeProfile } = useContext(ActiveSchoolsContext);
  const school = activeProfile.school;
  const basePath = "/scheduler/";

  //scheduler state
  const { startDate, viewName } = useParams();
  const [drawer, openDrawer, closeDrawer, drawerOpenState] = useDrawer();

  //filters state
  const [filterTags, setFilterTags] = useState([])

  const setDateAndView = (newDate, newViewName) => {
    let mDate = moment(newDate);
    if (viewName === "timeGridWeek" || viewName === "listWeek") {
      // when switching from a week view to something else, change date to today
      // only if viewing current week
      const durationFromToday = moment.duration(mDate.diff(moment()));
      if (durationFromToday < 0 && durationFromToday > -moment.duration(7, "days")) {
        mDate = moment();
      }
    }
    const newDateString = mDate.format("YYYY-MM-DD");
    if (newViewName === viewName && newDateString === startDate) return;

    history.push(`${basePath}${newViewName}/${newDateString}`);
  };

  if (startDate === undefined) setDateAndView(moment(), "timeGridWeek");

  const {
    data: reservationsResponse,
    error,
    mutate,
  } = useSWR([startDate, "reservations", school.id], (startDate) => {
    const startParam = moment(startDate).subtract(1, "weeks").toISOString();
    const endParam = moment(startDate).add(1, "weeks").toISOString();
    return api.reservations.list({
      start: startParam,
      end: endParam,
    });
  });

  if (error) {
    console.log("Scheduler: ", error);
    Sentry.captureException(error, { extra: { response: error.response } });
  }
  const reservations = reservationsResponse?.data || [];

  const { data: locationsResponse } = useSWR(["locations", school.id], (key) =>
    api.locations.list()
  );
  const locations = locationsResponse?.data || [];

  const { data: tagsResponse } = useSWR(["tags", school.id], (key) =>
    api.tags.list()
  );
  const tags = tagsResponse?.data || [];

  const { data: slotsResponse } = useSWR(["slots", school.id], (key) => {
    const useDefaultSlots = !activeProfile.use_user_reservation_slots;
    return useDefaultSlots ? api.slots.school.list() : api.slots.user.list();
  });
  const slots = slotsResponse?.data || [];

  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpenState]);

  const pollMinutes = 1;
  const pollReservations = async () => {
    try {
      const response = await api.reservations.updates(pollMinutes * 60); // seconds
      if (response.data.updates) {
        mutate();
        enqueueSnackbar(`Updated reservations retrieved ${moment().format("LT")}`);
      }
    } catch (error) {
      Sentry.captureException(error, { extra: { response: error.response } });
    }
  };
  useTimer(pollReservations, pollMinutes * 60 * 1000); // milliseconds

  if (!startDate) return null;
  return (
    <div className={classes.root}>
      <SchedulerFilter className={classes.filter} tags={tags} filterTags={filterTags} setFilterTags={setFilterTags} />
      <Scheduler
        tags={tags}
        initialView={viewName}
        startDate={startDate}
        reservations={reservations}
        slots={slots}
        locations={locations}
        school={school}
        openDrawer={openDrawer}
        closeDrawer={() => {
          closeDrawer();
          mutate();
        }}
        loading={false}
        setStartDate={(start) => setDateAndView(start, viewName)}
        mutate={mutate}
        setView={(view) => setDateAndView(startDate, view)}
        setDateAndView={setDateAndView}
        filterTags={filterTags}
      />
      {drawer}
    </div>
  );
}
