import { useContext, useCallback } from "react";
import { ApiContext } from "../../api/api.context";
import { withLoader } from "../../api/withLoader";
import { AutocompleteInput } from "./AutocompleteInput";

const AutocompleteInputWithLoader = withLoader(AutocompleteInput, "options");

export function LocationsAutocomplete(props) {
  const { sendMessage, ...passThroughProps } = props;
  const api = useContext(ApiContext);
  const onError = useCallback((error) => sendMessage(error.message), [sendMessage]);

  return (
    <AutocompleteInputWithLoader
      loaderFunction={api.locations.list}
      onError={onError}
      label="Add Location"
      autoSelect={true}
      {...passThroughProps}
    />
  );
}
