import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      
      compactDrawerWidth: 80,
      drawerWidth: 180,
      mobileDrawerWidth: 56,
      rightDrawerWidth: 500,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

export const themeTagColors = [
  { name: "Grey", color: "#c6c6c6" },
  { name: "Purple", color: '#9C27B0'},
  { name: "Deep purple", color: '#673AB7' },
  { name: "Indigo", color: '#3F51B5' },
  { name: "Blue", color: "#0077bb" },
  { name: "Material Blue", color: '#2196F3' },
  { name: "Light Blue", color: '#03A9F4' },
  { name: "Cyan", color: "#33bbee" },
  { name: "Teal", color: "#009688" },
  { name: "Green", color: '#4CAF50' },
  { name: "Light Green", color: '#8BC34A' },
  { name: "Lime", color: '#CDDC39' },
  { name: "Yellow", color: '#FFEB3B' },
  { name: "Amber", color: '#FFC107' },
  { name: "Orange", color: "#ee7733" },
  { name: "Deep Orange", color: '#FF5722' },
  { name: "Lightish red", color: "#ee3377" },
  { name: "Material Red", color: '#F44336'},
  { name: "Red", color: "#cc3311" },
  { name: "Brown", color: '#795548' },
];