import { Autocomplete, Popper, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react'
import { useState } from 'react';
import { ApiContext } from '../../../api/api.context';
import { TagChip } from '../../../components/TagChip';
import MuiTextField from "@mui/material/TextField";
import { ActiveSchoolsContext } from '../../../contexts/activeSchools.context';
import { ColorChip } from '../../../components/ColorChip';
import { stringToColorSpec } from '../../../utils/color';

const ACTIVITY_TAG_HEADER = "Practical tags"

export function ReservationTagsControl(props) {

    const {initialValues, mutate, tags, reservation, open, setOpen, tagProps, InputProps, responsiveTags, sx, deleteIconProps} = props

    //RESPONSIVE STYLES
    const theme=useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const MobileMenu = function (props) {
        return <Popper {...props} style={{minWidth:'150px'}} />;
    };

    //STATE
    const [values, setValues] = useState(initialValues)
    const [inputValue, setInputValue] = useState('')
    
    //PERMISSIONS
    const { activeProfile } = useContext(ActiveSchoolsContext)
    const isAdmin = activeProfile?.is_admin;

    //API
    const api = useContext(ApiContext);
    const { enqueueSnackbar } = useSnackbar();
    const handleSubmit = async (values)=>{
        console.log(values)
        const reservationTags = values.filter(tag=>tag.type!=="Activity tag")
        console.log(reservationTags)
        const payload = {...reservation, tags: reservationTags.map(t=>t.id)}
        await api.reservations.put(reservation.id, payload)
        mutate();
        enqueueSnackbar("Reservation tags updated", {variant: "success"})
    }

    const handleDelete = async (value) => {
        console.log(value)
        const reservationTags = values.filter(tag=>tag.type!=="Activity tag")
        console.log(reservationTags)
        const tagsPayload = reservationTags.filter(tag=>tag.id !== value.id).map(t=>t.id)
        console.log(tagsPayload)
        const payload = {...reservation, tags: tagsPayload}
        console.log(payload)
        await api.reservations.put(reservation.id, payload)
        mutate();
        enqueueSnackbar("Reservation tags updated", {variant: "success"})
    }

    //UTILS
    const getOptions = (tags) => tags.map(tag=>isOptionExistingActivityTag(tag) ? {...tag, type: ACTIVITY_TAG_HEADER} : tag)
    const isOptionExistingActivityTag = (option)=> values.filter(t=>t.type==="Activity tag").map(t=>t.id).includes(option.id)


  return (
    <Autocomplete
        freeSolo //removes dropdown arrow icon
        clearOnBlur
        fullWidth
        multiple
        readOnly={!isAdmin}
        options={getOptions(tags).sort((a, b)=>a.type===ACTIVITY_TAG_HEADER ? 1 : -1)}
        value={values}
        inputValue={inputValue}
        disableClearable={true}
        isOptionEqualToValue={(option, value)=>{
            return (option.id===value.id)}}

        //EVENTS
        open={open}
        onFocus={(e)=>{
            setOpen(true);
            e.stopPropagation();
        }}
        onOpen={(e)=>{
            e.stopPropagation();
            setOpen(true)
        }}
        onClose={(e)=>{
            e.stopPropagation();
            setOpen(false)
        }}
        onChange = {(event, value) => {
            event.stopPropagation();
            setValues(value);
            handleSubmit(value)
        }}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        
        //RENDER
        groupBy={option => option.type}
        getOptionLabel={(option) => option.name}
        //do not allow activity tags to be modified
        getOptionDisabled={(option)=> option.type===ACTIVITY_TAG_HEADER}
        renderOption={(props, option) => (
            <li key={option.id} {...props}>
              <TagChip color={option.color} label={option.name} size="small"/>
            </li>
        )}
        renderTags={(value, getTagProps) => 
            (responsiveTags && isMobile) ? 
                value.map((value,index)=>
                    <ColorChip
                        key={index}
                        color={value.color ? value.color : stringToColorSpec(value.name).color}
                    />)
            :
                value.map((value, index)=>
                    <TagChip
                        key={index} 
                        color={value.color} 
                        label={value.name} 
                        onDelete={value.type === "Activity tag" ? undefined : (() => handleDelete(value))}
                        sx={{ 
                            "& .MuiChip-deleteIcon": {
                                display: "none",
                                ...deleteIconProps
                              },
                            "&:hover": {
                                "& .MuiChip-deleteIcon": {
                                  display: "block"
                                }
                            },
                            ...sx 
                          }}
                        {...tagProps}
                        // {...getTagProps({ index })} 
                    />
        )}
        renderInput={(params) => (
            <MuiTextField
              {...params}
              variant="standard"
              margin='none'
              size='small'
              InputProps={{
                ...params.InputProps,
                label: 'hidden',
                disableUnderline: true,
                ...InputProps
              }}
            />
          )}
        PopperComponent={isMobile ? MobileMenu : Popper}
    />
  )
}
