import React from "react";
import { Route } from "react-router-dom";
import Locations from "./Locations";
import MyAccount from "./MyAccount/MyAccount";
import MySchool from "./MySchool";
import Tags from "./Tags";
import Users from "./Users";
import { SchoolReservationSlots } from "./ReservationSlots/SchoolReservationSlots";
import { UserReservationSlots } from "./ReservationSlots/UserReservationSlots";

function SettingsRoutes() {
  return (
    <div>
      <Route path="/settings/my-school" component={MySchool}></Route>
      <Route path="/settings/tags" component={Tags}></Route>
      <Route path="/settings/locations" component={Locations}></Route>
      <Route path="/settings/users" component={Users}></Route>
      <Route path="/settings/my-account" component={MyAccount}></Route>
      <Route
        path="/settings/school-reservation-slots"
        component={SchoolReservationSlots}
      ></Route>
      <Route
        path="/settings/user-reservation-slots"
        component={UserReservationSlots}
      ></Route>
    </div>
  );
}

export default SettingsRoutes;
