import { StyledEngineProvider } from "@mui/material";
import Routes from "./Routes";
import {ThemeProvider} from './theme';

import store from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ActiveSchoolsProvider } from "./contexts/activeSchools.context";
import { api } from "./api";
// import { theme } from "./theme";
import { ApiContext } from "./api/api.context";

function App() {

  return (
    <div className="app">
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <ApiContext.Provider value={api}>
              <ActiveSchoolsProvider>
                  <Provider store={store}>
                    <Router>
                      <Routes />
                    </Router>
                  </Provider>
              </ActiveSchoolsProvider>
          </ApiContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
