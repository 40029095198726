import { Box, MenuItem, Stack, TextField } from "@mui/material";
import { ColorChip } from "./ColorChip";

export function ColorOption({ name, color }) {
  return (
    <Stack direction={"row"} alignItems="center" spacing={1} sx={{ width: "100%" }}>
      <ColorChip color={color} />
      <Box width="6rem">{name}</Box>
    </Stack>
  );
}

export function ColorSelector(props) {
  const { options, onChange, textFieldProps, InputProps, ...passThroughProps } = props;
  return (
    <TextField 
      select {...passThroughProps} 
      onChange={(event) =>onChange(event.target.value)}
      InputProps={{...InputProps}}
      {...textFieldProps}
      
      >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.color}>
          <ColorOption {...option} />
        </MenuItem>
      ))}
    </TextField>
  );
}
