import { useMediaQuery, useTheme } from "@mui/material";
import JoyRide from "react-joyride";
import useModal from "./hooks/useModal";

// TODO: pull this from somewhere else.

const Tour = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [modal] = useModal();
  const showTour = JSON.parse(localStorage.getItem("showTour"));

  const handleJoyrideCallback = (data) => {
    console.log(data);
    const { status, type } = data;
    console.log(status, type);
    const finishedStatuses = ["finished", "skipped"];
    if (finishedStatuses.includes(status)) {
      window.analytics.track("Tour Completed");
      // localStorage.setItem("showTour", false);
      localStorage.removeItem("showTour");
    }
  };

  const TOUR_STEPS = [
    {
      target: "body",
      title: "Welcome to Bunsen",
      content:
        "Thanks for signing up! Get started with managing your shared school equipment by following our guided tour.",
      //<ReactPlayer url="https://youtu.be/agmxp0i-ly0" controls />,
      placement: "center",
      disableBeacon: true,
      styles: {
        tooltip: {
          width: "100%",
        },
      },
    },
    {
      target: ".fc-view-harness",
      title: "Make a Reservation",
      content: "Click on any time-slot to book out some equipment.",
      spotlightClicks: true,
    },
    {
      target: ".fc-resourceTimelineDay-button",
      title: "Bird's eye view",
      content: "Get an overview of your colleagues' Reservations in 'All Reservations'.",
      spotlightClicks: true,
    },
    {
      target: ".tour-step-3",
      placement: "right",
      title: "Explore the app here",
      content:
        "The nagivation bar takes you to pages where you can create practicals and record items in your inventory. In settings, you can customise your school timetables, turn on email notifications and more. Have a play around, and see what Bunsen can do!",
      spotlightClicks: true,
    },
    {
      target: ".mobile-tour-step-3",
      title: "Explore the app here",
      content:
        "The nagivation bar takes you to pages where you can create practicals and record items in your inventory. In settings, you can customise your school timetables, turn on email notifications and more. Have a play around, and see what Bunsen can do!",
      spotlightClicks: true,
    },
  ];

  const getTourSteps = () =>
    isMobile
      ? TOUR_STEPS.filter((step) => step.target !== ".tour-step-3")
      : TOUR_STEPS.filter((step) => step.target !== ".mobile-tour-step-3");

  return (
    <>
      {modal}
      <JoyRide
        run={showTour}
        steps={getTourSteps()}
        continuous
        showProgress
        disableOverlayClose
        disableCloseOnEsc
        callback={handleJoyrideCallback}
        styles={{
          options: { zIndex: 1175 },
          buttonNext: {
            backgroundColor: "#FF5722",
            fontFamily: "Roboto",
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857",
          },
          buttonBack: {
            display: "none",
          },
          buttonClose: {
            display: "none",
          },
          tooltipTitle: {
            fontFamily: "Raleway",
          },
          tooltipContent: {
            fontFamily: "Roboto",
          },
        }}
        locale={{
          last: "End tour",
        }}
      />
    </>
  );
};

export default Tour;
