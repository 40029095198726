import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { alpha, Grid, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, {useContext, useState} from 'react'
import { ApiContext } from '../../../api/api.context';
import { FileChip } from '../../../components/FileChip';
import { getReservationPrimaryActivity } from '../../../utils/reservation';
import { getInitials } from '../../../utils/user';
import { LoadingReservationDetailsWithControls } from './LoadingReservationDetailsWithControls';
import { ReservationTagsControl } from './ReservationTagsControl';

const StyledTagsArea = styled(Grid)(({ theme }) => ({
    borderRadius: '4px',
    backgroundColor:alpha('#000000', 0),
    '&:hover': {
      background: alpha('#000000', 0.25),
   },
  }));

export function ReservationListRow(props) {

    const {eventInfo, mutate, tags, openDrawer, closeDrawer} = props

    const api = useContext(ApiContext);


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { enqueueSnackbar } = useSnackbar();


    const reservation = eventInfo.event.extendedProps.reservation;
    
    const initials = getInitials(reservation.reserved_for_detail.name);
    
    //related activities
    const activity = getReservationPrimaryActivity(reservation);

    //related resources
    const resourceRequirements = reservation.total_required_resources || [];

    //TAGS
    const activityTags = activity?.tags_detail.map(t=>({...t, type: 'Activity tag'})) || [];
    const reservationTags = reservation?.tags_detail.map(t=>({...t, type: 'Reservation tag'})) || [];
    const allTags = [...reservationTags, ...activityTags]

    const [tagsAutocompleteOpen, setTagsAutocompleteOpen] = useState(false)
    console.log(reservation)
    const activityAttachments = reservation.activities_detail[0]?.attachments_detail?.map(a=>a)
    const attachments = reservation.attachments_detail.map(a=>a)
    console.log(activityAttachments)

    return (
        <Grid container spacing={2} onClick={()=>openDrawer(
            <div>
              <LoadingReservationDetailsWithControls
                loaderFunction={() => api.reservations.getOne(reservation.id)}
                onError={(error) =>
                  enqueueSnackbar(`Something went wrong, please try again. ${error.message}`)
                }
                onSuccess={() => {
                  closeDrawer();
                  mutate();
                }}
              />
            </div>,
            ""
          )}>
            {/* Row 1 */}
            <Grid item xs={12} md={6} display={isMobile ? "block" : "flex"}>
                <Typography style={{ fontWeight: 600 }}>{`${reservation.number_of_sets} \u00D7 ${eventInfo.event.title}`}</Typography>
                {!isMobile && '\u2002'}
                <Typography>{initials}</Typography>
                {!isMobile && '\u2002'}
                <Typography>{reservation.location_detail.name}</Typography>

            </Grid>
            <StyledTagsArea item xs={12} md={6} onClick={(e)=> {e.stopPropagation(); setTagsAutocompleteOpen(true);}} display={isMobile ? "block" : "flex"}>
                <ReservationTagsControl 
                    initialValues={allTags} 
                    mutate={mutate} 
                    tags={tags} 
                    reservation={reservation} 
                    tagProps={{size: "small"}} 
                    open={tagsAutocompleteOpen} 
                    setOpen={setTagsAutocompleteOpen} 
                />
            </StyledTagsArea>
            {/* Row 2 */}
            <Grid item xs={12} md={6}>
                {resourceRequirements.map((requirement, index)=>
                (<Typography key={index}>
                    {!!parseInt(requirement.quantity) && `${requirement.quantity} ${requirement.units ? `${requirement.units}` : "× "} `}{requirement.name}
                </Typography>)
            )}
            </Grid>
            <Grid item xs={12} md={6}>
                {activityAttachments?.map((a, index)=><FileChip onClick={e=>e.stopPropagation()} attachment={a} key={index}/>)}
                {attachments.map((a, index)=> <FileChip onClick={e=>e.stopPropagation()} attachment={a} color="primary" key={index} />)}
                <Typography sx={{mt:1}}>{reservation.comment}</Typography>
            </Grid>
        </Grid>
    )
}
