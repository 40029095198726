const { createFilterOptions } = require("@mui/material");

export const filterByNameCodeTag = createFilterOptions({
    stringify: (option) => {
      let result = option.name + option.code
      for (const tag_name of option.tag_names){
        result += tag_name
      }
      return result
    },
  });