import { useTheme } from "@emotion/react";
import { Stack, useMediaQuery } from "@mui/material";
import { TagChip } from "../../../components/TagChip";

export function SchedulerFilter ({tags, filterTags, setFilterTags}) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = (id) => {
        if (filterTags.includes(id)) {
            setFilterTags(existingTags=>existingTags.filter(t=>t!==id))
        } else {
            setFilterTags(existingTags=>[...existingTags, id])
        }
    }

    return <Stack 
            direction="row" 
            spacing={1} 
            marginBottom={isMobile ? 1 : 2} 
            overflow="scroll"
        >
        {tags.map((tag)=>
            <TagChip
                size="small"
                key={tag.id} 
                label={tag.name} 
                color={tag.color}
                variant={filterTags.includes(tag.id) ? "filled" : "outlined"} 
                // disabled 
                onClick={()=>handleClick(tag.id)}
            />
        )}
    </Stack>

}