import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import { NavLink as RouterLink, Link } from "react-router-dom";
import { SchoolSwitcher } from "../../../SchoolSwitcher";
import { VersionIndicator } from "../../../VersionIndicator";
import { navLabels } from "../navLabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect, useState } from "react";
import { GettingStartedWidgetContainer } from "../../../GettingStarted/GettingStartedWidget";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { Announcement } from "../../../Announcement/Announcement";
import { RewardsPointsWidgetContainer } from "../../../RewardsPointsWidget";
import { ENABLE_POINTS, getFeatureFlag } from "../../../../utils/featureFlags";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const Logo = () => (
  <img src={process.env.PUBLIC_URL + "/logo192.png"} alt="Bunsen logo" style={{ width: "50px", textAlign: "center" }} />
);

function InlineSubMenu({ items, open, pathMatcher }) {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List>
        {items.map((item, index) => (
          <StyledNavItem key={index} item={item} pathMatcher={pathMatcher} colorText={false} />
        ))}
      </List>
    </Collapse>
  );
}

function ModalSubMenu({ items, open, pathMatcher, onClose, anchorEl }) {
  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {items.map((item, index) => (
        <MenuItem key={index}>
          <NavItem item={item} pathMatcher={pathMatcher} colorText={false} />
        </MenuItem>
      ))}
    </Menu>
  );
}

function NavItemWithChildren({ item, pathMatcher, SubMenuComponent, ...passThroughProps }) {
  const active = pathMatcher && pathMatcher(item.path);
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [open, setOpen] = useState(active);
  useEffect(() => {
    setOpen(active);
  }, [active]);

  const chevronStyles = {
    color: 'text.secondary'
  }

  const chevron = open ? <ExpandLess sx={chevronStyles} /> : <ExpandMore sx={chevronStyles} />  

  return (
    <>
      <BasicNavItem
        item={item}
        adornments={chevron}
        onClick={(event) => {
          setOpen(!open);
          setAnchorEl(event.currentTarget);
        }}
        component={Box}
        pathMatcher={pathMatcher}
        {...passThroughProps}
      />
      <SubMenuComponent
        open={open}
        items={item.children}
        pathMatcher={pathMatcher}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
      />
    </>
  );
}

function BasicNavItem({
  item,
  adornments,
  pathMatcher,
  SubMenuComponent,
  colorText = true,
  ...passThroughProps
}) {
  const defaultColor = "text.secondary";
  const active = pathMatcher && pathMatcher(item.path);
  const textColor = active && colorText ? "primary.main" : defaultColor;
  const iconColor = active ? "primary.main" : defaultColor;

  return (
    <ListItemButton
      component={RouterLink}
      to={item.target === "_blank" ? { pathname: item.path } : item.path}
      target={item.target}
      {...passThroughProps}
    >
      <ListItemIcon sx={{ width: "2rem", minWidth: "1rem", color: iconColor }}>
        {item.icon && <FontAwesomeIcon icon={["fas", item.icon]} style={{ margin: "auto" }} />}
        {!item.icon && <CircleIcon sx={{ fontSize: active ? 12 : 6, m: "auto" }} />}
      </ListItemIcon>
      <ListItemText variant="body1" display="inline" sx={{ color: textColor, mb: 0, ml: 2 }}>
        {item.title}
      </ListItemText>
      {adornments}
    </ListItemButton>
  );
}

export const NavItem = (props) =>
  props.item.children ? <NavItemWithChildren {...props} /> : <BasicNavItem {...props} />;

const StyledNavItem = styled(NavItem)(({ theme }) => ({
    "& .MuiListItemIcon-root": {
      transition: 'all .2s ease-in-out',
    },
    "&:hover": {
      borderRadius: 20,
      "& .MuiListItemIcon-root": {
        transform: 'scale(1.2)',
      }
    }
  }));

export function SideNavigation({ pathMatcher }) {
  const menuItems = navLabels;
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const displayWidth = open ? theme.drawerWidth : 60;
  const SubMenuComponent = open ? InlineSubMenu : ModalSubMenu;
  return (
    <Drawer
      open
      variant="persistent"
      PaperProps={{ sx: { width: displayWidth, overflow: "hidden" } }}
      sx={{ width: displayWidth, overflow: "hidden" }}
    >
      <List className="tour-step-3">
        <Box sx={{ py: 2.5, display: "flex"}}>
          <Box m="auto">
            <Logo />
          </Box>
        </Box>
        {menuItems.map((item, index) => (
          <StyledNavItem
            item={item}
            key={index}
            pathMatcher={pathMatcher}
            SubMenuComponent={SubMenuComponent}
          />
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} onClick={() => setOpen(true)} />
      <Stack sx={{ ml: 1, mb: 1, mr: 1 }} spacing={2} divider={<Divider />}>
{/*         {open && <Announcement />} */}
        {getFeatureFlag(ENABLE_POINTS) && open && (
          <Box ml={1}>
            <RewardsPointsWidgetContainer />
          </Box>
        )}
        {open && (
          <Link to="/getting-started/">
            <GettingStartedWidgetContainer />
          </Link>
        )}
        <SchoolSwitcher />

        <Box display="flex" alignItems="center">
          {open && <VersionIndicator />}
          {open && (
            <ChevronLeftIcon
              onClick={() => setOpen(!open)}
              sx={{ ml: "auto", mr: 0, cursor: "pointer", color: 'text.secondary' }}
            />
          )}
          {!open && (
            <ChevronRightIcon onClick={() => setOpen(!open)} sx={{ ml: 1, cursor: "pointer", color: 'text.secondary' }} />
          )}
        </Box>
      </Stack>
    </Drawer>
  );
}
