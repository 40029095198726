import { Button, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import * as Yup from "yup";
import { ColorSelector } from "../ColorSelector";
import { themeTagColors } from "../../theme";

const defaultInitialValues = {
  name: "",
  color: "",
};

export function TagForm({ initialValues, onSuccess, apiSubmitFunction, submitButtonText }) {
  const { enqueueSnackbar } = useSnackbar();
  console.log(initialValues);
  const onSubmit = async (values, { setFieldError }) => {
    try {
      await apiSubmitFunction(values);
      enqueueSnackbar(`Tag updated`, { variant: "success" });
      onSuccess();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Formik
      initialValues={initialValues || defaultInitialValues}
      validationSchema={Yup.object({
        name: Yup.string().required(),
        color: Yup.string().required(),
      })}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
        <Form>
          <Grid container columns={12} rowSpacing={1}>
            <Grid item xs={12}>
              <Field component={TextField} label="Name" name="name" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={ColorSelector}
                label="Color"
                name="color"
                value={values.color}
                onChange={(value) => setFieldValue("color", value)}
                options={themeTagColors}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {submitButtonText || "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
