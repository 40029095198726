import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

/*
Component that extends the Autocomplete component functionality by  enabling inline creation of items.
Input values should have type 
{
  name?: string
  id?: string
}
Either one of name and id must be present. If id is present, name is looked up from the options.
The case where name is present but id is not is only allowed for "freeSolo" components.

Returned objects have type
{
  name: string
  id?: string
  inputValue?: string
}

either id or inputValue is returned. inputValue is returned instead of id only for "freeSolo" components and only 
if the user enters free text instead of picking an option.
*/

const filter = createFilterOptions();

function getOptionByName(name, options) {
  const matchingOptions = options.filter((x) => x.name === name);
  const matchingOption = matchingOptions.length > 0 && matchingOptions[0];
  return matchingOption;
}

function addValueFromOptions(value, options) {
  if (value && value.id && options) {
    const matchingOptions = options.filter((x) => x.id === value.id);
    const matchingOption = matchingOptions.length > 0 && matchingOptions[0];
    if (matchingOption) return { ...value, ...matchingOption };
  }
  return { ...value };
}

export function AutocompleteInput(props) {
  const {
    onChange,
    createItem,
    multiple,
    options,
    value,
    touched,
    textFieldProps,
    InputProps,
    autoSelect,
    ...otherProps
  } = props;

  // console.log("IP: ", InputProps);
  // console.log("label: ", props.label);
  // console.log("options: ", options);
  // console.log("value: ", value);

  // add names to value from options array if they were not provided.
  let valueWithName;
  if (!value) {
    valueWithName = multiple ? [] : null;
  } else if (props.freeSolo && value?.inputValue) {
    valueWithName = { name: value.inputValue };
  } else if (!options) {
    valueWithName = multiple ? [...value] : { ...value };
  } else if (multiple) {
    valueWithName = value.map((v) => addValueFromOptions(v, options));
  } else {
    valueWithName = addValueFromOptions(value, options);
  }

  return (
    <Autocomplete
      value={valueWithName}
      options={options || []}
      multiple={multiple}
      selectOnFocus
      clearOnBlur={!autoSelect}
      autoHighlight={autoSelect}
      // autoSelect={autoSelect}
      filterOptions={(options, params) => {
        // follows https://mui.com/material-ui/react-autocomplete/#creatable
        // console.log("options: ", options);
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a custom value
        if (props.freeSolo && inputValue !== "") {
          filtered.push({
            inputValue: inputValue,
            name: `Custom request for "${inputValue}"`,
          });
          return filtered;
        }

        if (!createItem) return filtered;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            name: inputValue,
            title: `Add new "${inputValue}"`,
          });
        }
        // console.log("filtered: ", filtered);
        return filtered;
      }}
      onChange={async (event, updatedFieldValue) => {
        //if creating a new tag AND you aren't trying to delete
        //the only remaining tag/ clear all tags
        // console.log("onChange: ", updatedFieldValue);
        if (!createItem) {
          const selectedOption = getOptionByName(updatedFieldValue, options) || updatedFieldValue;
          return onChange(selectedOption);
        }

        if (multiple && updatedFieldValue.length > 0 && !updatedFieldValue.at(-1).id) {
          const payload = updatedFieldValue.pop();
          const createdItem = await createItem(payload);
          console.log("created item: ", createdItem);

          createdItem && updatedFieldValue.push(createdItem);
        }
        // console.log(updatedFieldValue);
        if (!multiple && !updatedFieldValue.id) {
          // console.log(updatedFieldValue);
          const createdItem = await createItem(updatedFieldValue);
          // console.log(updatedFieldValue);
          // console.log(createdItem);
          Object.assign(updatedFieldValue, createdItem);
          // console.log(updatedFieldValue);

          // updatedFieldValue =createItem
        }
        // console.log(updatedFieldValue);
        onChange(updatedFieldValue);
      }}
      getOptionLabel={(option) => option.name || ""}
      renderOption={(props, option) => <li {...props}>{option.title || option.name}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          error={touched && !!props.error}
          helperText={touched && props.error}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            ...InputProps,
          }}
          {...textFieldProps}
        />
      )}
      isOptionEqualToValue={(option, value) => value.id && option.id === value.id}
      {...otherProps}
    />
  );
}
