import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { ApiContext } from "../../../api/api.context";
import { ReservationForm } from "../../../components/forms/ReservationForm";
import { ActiveSchoolsContext } from "../../../contexts/activeSchools.context";
import * as Sentry from "@sentry/react";
import { ReservationDetails } from "./ReservationDetails";
import { AssignmentControl } from "./AssignmentControl";
import { Button, Grid } from "@mui/material";
import { InlineTagsControl } from "../../../components/InlineTagsControl";
import { CreateReservationForm } from "../../../components/forms/CreateReservationForm";
import { ColorControl } from "./ColorControl";

function UpdateReservationForm(props) {
  const api = useContext(ApiContext);

  return (
    <ReservationForm
      apiSubmitFunction={(values) => api.reservations.put(props.initialValues.id, values)}
      submitButtonText="Update"
      {...props}
    />
  );
}

export function ReservationDetailsWithControls({ reservation, onSuccess }) {

  const api = useContext(ApiContext);
  const { enqueueSnackbar } = useSnackbar();

  const [updating, setUpdating] = useState(false);
  const [copying, setCopying] = useState(false);

  const { activeProfile } = useContext(ActiveSchoolsContext);

  if (!reservation) return <div />;

  const isAdmin = activeProfile?.is_admin;
  const isMine = activeProfile.user.id === reservation.reserved_for;

  const handleDelete = async () => {
    try {
      await api.reservations.del(reservation.id);
      enqueueSnackbar("Reservation deleted");
      onSuccess();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const tagsControl = (
    <InlineTagsControl
      disabled={!(isMine || isAdmin)}
      sendMessage={enqueueSnackbar}
      initialTags={reservation.tags}
      apiUpdateFunction={(payload) => api.reservations.patch(reservation.id, payload)}
      textFieldProps={{variant: "filled"}}
      InputProps={{disableUnderline: true,}}
    />
  );

  const assignmentControl = <AssignmentControl reservation={reservation} disabled={!isAdmin} />;
  const colorControl = <ColorControl reservation={reservation} disabled={!isAdmin} />;

  return (
    <>
      {updating && <UpdateReservationForm initialValues={reservation} onSuccess={onSuccess} />}
      {copying && <CreateReservationForm initialValues={{...reservation, reserved_for: activeProfile.user.id}} onSuccess={onSuccess} />}

      {!(updating || copying) && (
        <ReservationDetails
          reservation={reservation}
          tagsControl={tagsControl}
          assignmentControl={assignmentControl}
          colorControl={colorControl}
        />
      )}
      {!(copying || updating) && (
        <Grid container spacing={1} columns={12} sx={{ marginTop: 2 }}>
        {(isAdmin || isMine) && (
                <>
                  <Grid item xs={4}>
                    <Button variant="contained" onClick={() => setUpdating(true)} fullWidth>
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button variant="outlined" onClick={handleDelete} fullWidth>
                      Delete
                    </Button>
                  </Grid>
                </>
              )}
              <Grid item xs={(isAdmin || isMine) ? 4 : 12}>
                <Button variant="contained" color="secondary" onClick={() => setCopying(true)} fullWidth>
                  Copy
                </Button>
              </Grid>
              </Grid>
      )
      }
      
    </>
  );
}
