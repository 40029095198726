import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles({
  root: {
    width: "100vw",
    position: "fixed",
    bottom: 0,
    textTransform: "uppercase",
    zIndex: "999",
  },
  activeLink: {
    color: (props) => props.color,
  },
});

export default function SimpleBottomNavigation({ navLabels, activeLinkStyle }) {
  const classes = useStyles(activeLinkStyle);

  return (
    <>
      <BottomNavigation showLabels className={`mobile-tour-step-3 ${classes.root}`}>
        {navLabels.map(
          ({ title, icon, className, path, target, children }, index) =>
            !children && (
              <BottomNavigationAction
                key={index}
                component={NavLink}
                to={{ pathname: path }}
                target={target}
                rel={title === "Marketplace" ? "noopener noreferrer" : undefined}
                label={title}
                className={className}
                activeClassName={classes.activeLink}
                icon={<FontAwesomeIcon icon={["fas", icon]} />}
              />
            )
        )}
      </BottomNavigation>
    </>
  );
}
