import { Typography, CircularProgress, Box, Stack } from "@mui/material";

export function LoaderWithMessage({ message }) {
  return (
    <Box textAlign='center' width="100%" marginTop="20%">
        <Stack direction='column' alignItems='center' spacing={2}>
      <CircularProgress size={60}/>
      <Typography variant='body1'>{message}</Typography>
      </Stack>
    </Box>
  );
}
