import React from "react";
import Activities from "./Activities.container";

const ActivitiesRoot = () => {
  return (
      <Activities />
  );
};

export default ActivitiesRoot;
