import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import "./index.css";
import { getVersionString } from "./utils/version";


if (process.env.REACT_APP_ENVIRONMENT !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `bunsen-scheduling-ui@${getVersionString()}`,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
