import React, { useContext } from "react";
import DataTable from "../../components/Datatable/DataTable";
import DescriptionIcon from "@mui/icons-material/Description";
import { Chip, Link, Tooltip } from "@mui/material";
import { getFileName } from "../../utils/files";
import { ApiContext } from "../../api/api.context";
import { ActivityForm } from "../../components/forms/ActivityForm";
import { TagChip } from "../../components/TagChip";
// import ProductRecommendations from "../../components/ProductRecommendations/ProductRecommendations.container";

function CreateActivity(props) {
  const api = useContext(ApiContext);
  return (
    <ActivityForm apiSubmitFunction={api.activities.post} submitButtonText="Create" {...props} />
  );
}

function UpdateActivity(props) {
  const api = useContext(ApiContext);
  const { item, ...passThroughProps } = { ...props };
  // const productQuery = item.resource_requirements.length ? item.resource_requirements_detail[0].resource_name : item.name
  
  return (
    <div>
      <ActivityForm
        apiSubmitFunction={(values) => api.activities.put(item.id, values)}
        submitButtonText="Update"
        initialValues={item}
        {...passThroughProps}
      />
      {/* <ProductRecommendations productQuery={productQuery} /> */}
    </div>
  );
}

function Activities() {
  const api = useContext(ApiContext);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: { display: "excluded", filter: false },
    },
    { name: "name", label: "Name", options: { filter: false } },
    { name: "code", label: "Code", options: { filter: false } },
    {
      name: "tags_detail",
      label: "Tags",
      options: {
        filter: true,
        sort: false,
        filterType: "multiselect",
        customBodyRender: (value) =>
          value?.map((val, key) => (
            <TagChip sx={{ margin: 0.5 }} label={val.name} key={key} color={val.color} />
          )),
      },
    },
    {
      name: "comment",
      label: "Comment",
      options: {
        filter: false,
      },
    },
    {
      name: "resource_requirements_detail",
      label: "Equipment",
      options: {
        filter: true,
        sort: false,
        filterType: "multiselect",
        customBodyRender: (value) =>
          value?.map((val, key) => (
            <Chip
              sx={{ margin: 0.5 }}
              label={
                val.resource_units
                  ? `${val.quantity_per_set ? val.quantity_per_set : ""} ${
                      val.quantity_per_set ? val.resource_units : ""
                    } ${val.resource_name}`
                  : `${val.quantity_per_set ? val.quantity_per_set + " ×" : ""} ${
                      val.resource_name
                    }`
              }
              key={key}
            />
          )),
      },
    },
    {
      name: "attachments_detail",
      label: "Attachments",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value?.map((val, key) => (
            <Tooltip title={getFileName(val.file)} placement="top">
              <Link href={val.file} target="_blank" rel="noopener noreferrer">
                <DescriptionIcon />
              </Link>
            </Tooltip>
          )),
      },
    },
    {
      name: "department",
      label: "Department",
      options: { display: "excluded", filter: false },
    },

    {
      name: "attachment",
      label: "Attachment",
      options: { display: "excluded", filter: false },
    },
  ];

  return (
    <div style={{ height: "100%" }} className="tour-step-4">
      <DataTable
        itemName="activity"
        addDrawer={(closeDrawer) => <CreateActivity closeDrawer={closeDrawer} />}
        editDrawer={(closeDrawer, item) => <UpdateActivity closeDrawer={closeDrawer} item={item} />}
        columns={columns}
        apiListFunction={api.activities.list}
        apiDeleteFunction={api.activities.del}
        updateFormComponent={UpdateActivity}
        createFormComponent={CreateActivity}
      />
    </div>
  );
}

export default Activities;
