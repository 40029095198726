import { Button, Grid, MenuItem } from "@mui/material";
import * as Sentry from "@sentry/react";
import { TextField } from "formik-mui";

import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import useAttachmentUpload from "../../hooks/useAttachmentUpload";
import { TagsAutocomplete } from "../Autocomplete";

import { ApiContext } from "../../api/api.context";
import { useContext, useMemo } from "react";
import { LocationsAutocomplete } from "../Autocomplete/LocationsAutocomplete";

const defaultInitialValues = {
  name: "",
  type: "",
  code: "",
  tags: [],
  quantity_in_stock: 1,
  units: "",
  locations: [],
  comment: "",
};

function formatInitialValues(initialValues) {
  const formattedValues = { ...initialValues };

  if (initialValues.tags_detail) formattedValues.tags = initialValues.tags_detail;
  else formattedValues.tags = initialValues.tags?.map((id) => ({ id }));
  formattedValues.tags = formattedValues.tags || [];

  formattedValues.locations = initialValues.locations.map((id) => ({ id }));

  formattedValues.files = initialValues.attachments_detail.map((x) => ({
    name: x.file.split("/").pop().split("?")[0], // remove path and query params.
    id: x.id,
  }));
  formattedValues.type = initialValues.type

  return formattedValues;
}

export function ResourceForm({ initialValues, onSuccess, apiSubmitFunction, submitButtonText }) {
  const formattedInitialValues = useMemo(
    () => initialValues && formatInitialValues(initialValues),
    [initialValues]
  );

  const api = useContext(ApiContext);
  const { enqueueSnackbar } = useSnackbar();
  const [files, fileChips, dropzone, setDropzoneOpen] = useAttachmentUpload(
    formattedInitialValues?.files
  );

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter an activity name."),
    code: Yup.string().nullable(),
    type: Yup.string().required("This field is required"),
    comment: Yup.string().max(300, "Comment must be shorter than 300 characters").nullable(),
    quantity_in_stock: Yup.number(),
    units: Yup.string().nullable(),
    // validate tags and locations
  });

  const onSubmit = async (values, { setFieldError, resetForm }) => {
    const valuesToSubmit = { ...values };
    console.log(valuesToSubmit);

    //format tags
    valuesToSubmit["tags"] = values.tags.map((tag) => tag && tag.id);

    //format locations
    valuesToSubmit["locations"] = values.locations.map((location) => location && location.id);

    //upload file attachment and insert id into valuesToSubmit
    valuesToSubmit.attachments = [];
    if (files.length > 0) {
      valuesToSubmit.attachments = [];
      for (const file of files) {
        if (file.id) {
          valuesToSubmit.attachments.push(file.id);
          continue;
        }
        const formData = new FormData();
        formData.append("file", file);
        const uploadResponse = await api.uploads.post(formData);
        valuesToSubmit.attachments.push(uploadResponse.data.id);
      }
    }

    try {
      await apiSubmitFunction(valuesToSubmit);
      enqueueSnackbar("Resource created", { variant: "success" });
      onSuccess();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("An error occurred. Resource not created.", { variant: "error" });
      Sentry.captureException(error);
    }
  };

  return (
    <Formik
      initialValues={formattedInitialValues || defaultInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
        <Form>
          <Grid container columns={6} spacing={2} rowSpacing={1} alignItems="center">
            <Grid item xs={6}>
              <Field component={TextField} label="Name" name="name" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Field
                fullWidth
                component={TextField}
                label="Type"
                name="type"
                helperText="Reservations are restricted by limited resource stock levels only"
                select
                children={[
                  { value: "EQ", label: "Limited resource" },
                  { value: "CO", label: "Standard resource" },
                ].map(({ value, label }, index) => (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={6}>
              <Field component={TextField} label="Code" name="code" fullWidth />
            </Grid>
            <Grid item xs={6}>
            <Field
                component={TagsAutocomplete}
                name="tags"
                value={values.tags}
                onChange={(value) => setFieldValue("tags", value)}
                sendMessage={enqueueSnackbar}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={TextField}
                label="Quantity in stock"
                type="number"
                name="quantity_in_stock"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={3}>
              <Field component={TextField} label="Units" name="units" />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={LocationsAutocomplete}
                multiple
                name="locations"
                value={values.locations}
                onChange={(values) => setFieldValue("locations", values)}
                sendMessage={enqueueSnackbar}
                error={errors.locations}
                touched={touched?.locations}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                multiline={true}
                label="Comment"
                name="comment"
                fullWidth
              />
            </Grid>

            {fileChips.length > 0 && (
              <Grid item xs={6}>
                {fileChips}
              </Grid>
            )}
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setDropzoneOpen(true)}
                sx={{ width: "100%" }}
              >
                Add file attachment
              </Button>
              {dropzone}
            </Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onSubmit={submitForm}
                sx={{ width: "100%" }}
              >
                {submitButtonText || "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
