import { Chip } from "@mui/material";
import { DropzoneDialog } from "react-mui-dropzone";
import React, { useState } from "react";

function useAttachmentUpload(initialFiles) {
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [files, setFiles] = useState(initialFiles ? initialFiles : []);

  const dropzone = (
    <DropzoneDialog
      acceptedFiles={[
        "image/*",
        ".doc",
        ".docx",
        ".odt",
        ".pages",
        ".key",
        ".numbers",
        ".pdf",
        ".rtf",
        ".ppt",
        ".pptx",
        ".ods",
        ".xls",
        ".xlsx",
        ".csv",
        "odp",
      ]}
      cancelButtonText={"cancel"}
      submitButtonText={"submit"}
      maxFileSize={5000000}
      open={dropzoneOpen}
      onClose={() => setDropzoneOpen(false)}
      onSave={(files) => {
        setFiles((st) => [...st, ...files]);
        setDropzoneOpen(false);
      }}
      showPreviews={true}
      showFileNamesInPreview={true}
    />
  );

  const fileChips = files.map((file, index) => (
    <Chip
      label={file.name}
      key={index}
      onDelete={() => {
        files.splice(index, 1);
        setFiles([...files]);
      }}
    />
  ));

  return [files, fileChips, dropzone, setDropzoneOpen];
}

export default useAttachmentUpload;
