import { useCallback, useContext } from "react";
import { ApiContext } from "../../api/api.context";
import { withLoader } from "../../api/withLoader";
import { AutocompleteInput } from "./AutocompleteInput";
import { filterByNameCodeTag } from "../../utils/filters";
import { OptionWithNameCodeTag } from "./OptionWithNameCodeTag";
import { Button, Card, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ResourcesBullets from "../../views/scheduler/components/ResourcesBullets";

const AutocompleteInputWithLoader = withLoader(AutocompleteInput, "options");

const filterActivities = (options, params) => {
  const filtered = filterByNameCodeTag(options, params);
  const { inputValue } = params;

  if (inputValue !== "") {
    filtered.push({
      inputValue: inputValue,
      name: `Custom request for "${inputValue}"`,
    });
  }
  return filtered;
};

export function ActivitiesAutocomplete(props) {
  const { sendMessage, field, ...passThroughProps } = props;
  const resourceRequirements = field.value?.resource_requirements_detail

  const formatResourceRequirements= resourceRequirements && resourceRequirements.map(rr=>({
    name: rr.resource_name,
    quantity: rr.quantity_per_set,
    units: rr.resource_units,
  }))

  const api = useContext(ApiContext);

  const [showEquipment, setShowEquipment] = useState(false)

  const loaderFunction = useCallback(
    () => api.activities.list({ ad_hoc: false }),
    [api.activities]
  );
  const onError = useCallback((error) => sendMessage(error.message), [sendMessage]);

  const renderOption = useCallback((props, option) => {
    return (
      <li {...props}>
        <OptionWithNameCodeTag option={option} />
      </li>
    );
  }, []);

  return (
    <>
      <AutocompleteInputWithLoader
        loaderFunction={loaderFunction}
        onError={onError}
        autoSelect={true}
        label="Search practicals or make custom reservation"
        // textFieldProps={{ helperText: "Search practicals by name, code or tag" }}
        filterOptions={(options, params) => filterActivities(options, params)}
        renderOption={renderOption}
        {...passThroughProps}
      />
        <Stack direction="row" justifyContent="space-between">
        <Typography sx={{color: "#637381", mt:0.5, ml:1.5}} variant='caption'>Search practicals by name, code or tag</Typography>
        {resourceRequirements?.length > 0 && (
          <>
              <Button sx={{color: "#637381", fontFamily: "Roboto,sans-serif", fontWeight: 400, fontSize: "12px"}} size="small" onClick={()=>setShowEquipment(!showEquipment)}>
                {showEquipment ? "Hide equipment" : "Show equipment"}
              </Button>
          </>
        )}
        </Stack>
        {showEquipment && <Card sx={{p:1, mt:1, mb:1}}><ResourcesBullets resourceRequirements={formatResourceRequirements}/></Card>}
    </>

  );
}
