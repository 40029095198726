import { Chip } from "@mui/material";
import { stringToColorSpec } from "../utils/color";

export function TagChip(props) {
  const { variant, label, color, textColor, sx, ...passThroughProps } = props;
  const tagColor = color || stringToColorSpec(label || "").color;
  const tagTextColor = textColor || "white";
  return (
    <Chip
      label={label}
      variant={variant}
      {...passThroughProps}
      sx={{ 
        borderColor: variant === "outlined" && tagColor,
        backgroundColor: variant === "outlined" || tagColor, 
        color: variant === "outlined" || tagTextColor, 
        ...sx 
      }}
    />
  );
}
