import { Drawer, Typography, useMediaQuery, useTheme, Box, IconButton, Grid, Paper, Tooltip } from "@mui/material";

import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ChevronRight } from "@mui/icons-material";

function useDrawer(initialVal = null) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [drawerContents, setDrawerContents] = useState(null);
  const [open, setOpen] = useState(false);
  const closeDrawer = () => setOpen(false);

  const drawer = (
    <Drawer
      anchor={"right"}
      PaperProps={{
        sx: { width: { xs: "100%", sm: "auto" } },
      }}
      open={open}
      ModalProps={{
        onBackdropClick: () => closeDrawer(),
      }}
    >
      <Paper sx={{ position: 'fixed', right: theme.rightDrawerWidth -10, top: 5, borderRadius:10}}>
        <Tooltip title="Close" placement="right">
          <IconButton onClick={()=>closeDrawer()} sx={{height:20, width:20}} >
            <ChevronRight fontSize="small" />
          </IconButton>
        </Tooltip>

      </Paper>
      {" "}
      {drawerContents}{" "}
    </Drawer>
  );

  const openDrawer = (contents, drawerTitle) => {
    setDrawerContents(
      isMobile ? (
        <Box padding={2}>
          <Grid container columns={12} alignItems="center">
            <Grid item xs={2}>
              <IconButton color="inherit" onClick={closeDrawer} aria-label="close" size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6">{drawerTitle}</Typography>
            </Grid>
          </Grid>
          {contents}
        </Box>
      ) : (
        <Box maxWidth={theme.rightDrawerWidth} minWidth={theme.rightDrawerWidth} padding={2}>
          <Typography variant="h6">{drawerTitle}</Typography>
          {contents}
        </Box>
      )
    );
    setOpen(true);
  };

  return [drawer, openDrawer, closeDrawer, open];
}

export default useDrawer;
