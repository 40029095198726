import { Card, CardActionArea, Grid, Typography, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { auth0Instance } from "../../../auth/auth0";


const useStyles = makeStyles((theme) => ({
  option: {
    width: "100%",
  },
  card: {
    padding: theme.spacing(3),
    minHeight: "100px",
  },
  actionArea: {
    textAlign: "center",
  },
}));

function JoinOrCreateSchool({ nextStep, toRequestInvite }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const user = auth0Instance.getUser()

  window.analytics.identify(user?.sub, {
    name: user?.name,
    email: user?.email,
  });

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        I want to...
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardActionArea
              className={classes.actionArea}
              onClick={toRequestInvite}
            >
              <i
                className="fas fa-user-plus"
                style={{ fontSize: "32px", marginBottom: "8px" }}
              />
              <Typography>Join an existing school</Typography>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardActionArea className={classes.actionArea} onClick={nextStep}>
              <i
                className="fas fa-school"
                style={{ fontSize: "32px", marginBottom: "8px" }}
              />
              <Typography>Create a new school account</Typography>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default JoinOrCreateSchool;
