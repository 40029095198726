import { Button, Divider, Typography, useTheme, Switch, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAxios } from "../../../hooks/useAxios";
import { get } from "../../../redux/data";
import SlotCard from "./SlotCard";
import { ActiveSchoolsContext } from "../../../contexts/activeSchools.context";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  slotsHeader: { marginTop: theme.spacing(1) },
  noSlots: { marginTop: theme.spacing(1) },
}));

function SlotList({ slots, path }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [renderNewSlot, setRenderNewSlot] = useState(false);
  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setRenderNewSlot(true)}>
        New slot
      </Button>
      {renderNewSlot && (
        <div>
          <SlotCard key={0} path={path} />
          <Divider />
          <Typography variant="h6" className={classes.slotsHeader}>
            My existing reservation slots
          </Typography>
        </div>
      )}
      {slots.map((slot, index) => (
        <div key={index + 1}>
          <SlotCard slot={slot} index={index + 1} path={path} />
          <Divider />
        </div>
      ))}
      {slots.length === 0 && (
        <Typography className={classes.noSlots}>You have no reservation slots.</Typography>
      )}
    </div>
  );
}

export function UserReservationSlots() {
  const { axiosInstance } = useAxios();
  const slots = useSelector((state) => state.slots.data);
  const dispatch = useDispatch();
  const {activeProfile, dispatch: activeSchoolDispatch } = useContext(ActiveSchoolsContext);
  console.log("active profile in user reservation: ", activeProfile);
  const useUserSlots = activeProfile.use_user_reservation_slots;
  const setUseUserSlots = async (value) => {
    const newProfileData = { ...activeProfile, use_user_reservation_slots: value };
    await axiosInstance.put(`auth/userprofiles/${newProfileData.id}/`, newProfileData);
    const currentUserResponse = await axiosInstance.get("auth/me/");
    activeSchoolDispatch({ type: "SET_USER", data: currentUserResponse.data });
  };

  const theme = useTheme();
  const classes = useStyles(theme);

  const path = `user-reservation-slots`;

  useEffect(() => {
    async function loadSlots() {
      const templatesResponse = await axiosInstance.get(path);
      console.log(`slots loaded: ${templatesResponse.data}`);
      dispatch(get({ name: "slots", data: templatesResponse.data }));
    }
    loadSlots();
  }, [dispatch, axiosInstance, path]);

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        My reservation time-slots
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 1 }}>
        Your personal schedule may differ from the school time-table. <br />
        If you want more customization, you can create a personal schedule here. This is optional.
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={useUserSlots}
            onChange={(event) => setUseUserSlots(event.target.checked)}
          />
        }
        label="Use personal time-slots"
      />
      {useUserSlots && <SlotList path={path} slots={slots} />}
    </div>
  );
}
