import { Button, CircularProgress, Paper, useMediaQuery, useTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import plur from "plur";
import React, { useContext, useEffect, useState } from "react";
import useDrawer from "../../hooks/useDrawer";
import useModal from "../../hooks/useModal";
import { useSnackbar } from "notistack";
import { ActiveSchoolsContext } from "../../contexts/activeSchools.context";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import * as Sentry from "@sentry/react";
import ImportModal from "./ImportModal";
import Tooltip from "@mui/material/Tooltip";

const PaperWithLoader = () => (
  <Paper 
    elevation={2} 
    sx={{
      p: 2, 
      position: 'fixed', 
      top: '50%', 
      left: '50%', 
      borderRadius: '50%',
      zIndex: 999
    }}>
    <CircularProgress/>
  </Paper>
)

function CreateButton(props) {
  const { isMobile, children, ...passthroughProps } = props;
  return isMobile ? (
    <IconButton size="large" {...passthroughProps}>
      <AddIcon />
    </IconButton>
  ) : (
    <Button
      variant="contained"
      color="primary"
      style={{ marginLeft: "8px" }}
      {...passthroughProps}
      children={children}
    />
  );
}

function BulkUploadButton(props) {
  return (
    <Tooltip title="Bulk upload">
      <IconButton size="large" {...props}>
        <CloudUploadIcon />
      </IconButton>
    </Tooltip>
  );
}

function DataTable({
  itemName,
  title,
  minimal,
  createFormComponent: CreateFormComponent,
  updateFormComponent: UpdateFormComponent,
  apiDeleteFunction,
  apiListFunction,
  columns,
  bulkUploadHelperText,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  // used to trigger useEffect
  // const [refresh, setRefresh] = useState(0);
  // const incrementRefresh = () => setRefresh(refresh + 1);

  const { activeProfile } = useContext(ActiveSchoolsContext);
  const isAdmin = activeProfile?.is_admin;

  const [drawer, openDrawer, closeDrawer] = useDrawer();
  const [modal, openModal, closeModal] = useModal();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [queryParams, setQueryParams] = useState({
    page_size: 25,
    page: 1,
    ordering: "",
    search: "",
  });

  const disabledColumns = ["Attachments", "Status"];

  const refresh = async () => {
    setLoading(true);
    try {
      const response = await apiListFunction(queryParams);
      console.log(response);
      setData(response.data.results);
      setCount(response.data.count);
    } catch (error) {
      enqueueSnackbar(error.message);
      Sentry.captureException(error, { extra: { queryParams, response: error.response } });
    }
    setLoading(false);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, apiListFunction]);

  const handleDeleteMany = async (rowsDeleted) => {
    try {
      const idsToDelete = rowsDeleted.data.map((d) => data[d.dataIndex].id);
      await Promise.all(idsToDelete.map((id) => apiDeleteFunction(id)));
      enqueueSnackbar("Delete successful", { variant: "success" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error", { variant: "error" });
      Sentry.captureException(error);
    }
    refresh();
  };

  const options = {
    serverSide: true,
    textLabels: {
      body: {
        noMatch: `No ${plur(itemName, 2)} added yet.`,
      },
    },
    filter: false,
    selectableRows: isAdmin ? "multiple" : "none",
    elevation: 0,
    print: false,
    download: false,
    viewColumns: !(minimal || isMobile),
    tableBodyHeight: "auto",
    customToolbar: () => (
      <>
        <BulkUploadButton onClick={() => openModal(<ImportModal closeModal={closeModal} />)} />
        <CreateButton
          isMobile={isMobile}
          disabled={!isAdmin}
          onClick={() => {
            openDrawer(
              <CreateFormComponent
                onSuccess={() => {
                  closeDrawer();
                  refresh();
                }}
              />,
              `Create ${itemName}`
            );
          }}
        >{`new ${itemName}`}</CreateButton>
        {loading && <PaperWithLoader />}
      </>
    ),
    onRowsDelete: handleDeleteMany,
    onCellClick: (colData, { colIndex, dataIndex }) => {
      if (!disabledColumns.includes(columns[colIndex].label)) {
        if (isAdmin) {
          const itemId = data[dataIndex].id;
          const item = data.find((i) => i.id === itemId);
          return openDrawer(
            <UpdateFormComponent
              item={item}
              onSuccess={() => {
                closeDrawer();
                refresh();
              }}
            />,
            `Edit ${itemName}`
          );
        }
      }
    },
    rowsPerPage: queryParams.page_size,
    rowsPerPageOptions: [],
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setQueryParams((st) => ({ ...st, page: tableState.page + 1 }));
          break;
        case "sort":
          setQueryParams((st) => ({
            ...st,
            ordering: `${tableState.sortOrder.direction === "desc" ? "-" : ""}${
              tableState.sortOrder.name
            }`,
          }));
          break;
        case "search":
          tableState.searchText
            ? setQueryParams((st) => ({
                ...st,
                page: 1,
                search: tableState.searchText,
              }))
            : setQueryParams(({ search, ...st }) => st);
          break;
        case "onSearchClose":
          setQueryParams(({ search, ...st }) => st);
          break;
        default:
          break;
      }
    },
    count,
  };

  return (
    <div>
      <MUIDataTable data={data} columns={columns} options={options} />
      {drawer}
      {modal}
    </div>
  );
}

export default DataTable;
