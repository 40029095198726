import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { ActiveSchoolsContext } from "../../../../contexts/activeSchools.context";
import { navLabels } from "../navLabels";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  // necessary for content to be below app bar
  drawerPaper: {
    width: drawerWidth,
  },
}));

function SecondaryNav({ active, mobileOpen, handleDrawerToggle }) {
  const classes = useStyles();
  const theme = useTheme();
  const { activeProfile } = useContext(ActiveSchoolsContext)
  const isAdmin = activeProfile?.is_admin;
  

  const drawer = (
    <div>
      <Divider />
      <List>
        {navLabels.find(label=>label.title==="Settings").children.map(
          (child, index) => (
            <ListItem
              component={NavLink}
              selected={active(child.path)}
              disabled={!isAdmin && child.title==="Timetable"}
              to={child.path}
              button
              key={index}
            >
              <ListItemText primary={child.title} />
            </ListItem>
          )
        )}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              onClick: handleDrawerToggle,
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          {drawer}
        </Hidden>
      </nav>
    </div>
  );
}

export default SecondaryNav;
