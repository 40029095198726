import createAuth0Client from "@auth0/auth0-spa-js";

class Auth0 {
  /* 
  This class handles user authentication through auth0. It is intended to be used as a singleton.
  In particular it provides a few utility functions for logical actions like login, logout, and configure.
  It also caches some data to avoid repeated calls to the auth0 server. 
  */
  constructor() {
    this._client = undefined;
    this._isAuthenticated = false;
    this._user = undefined;
  }

  async configureClient() {
    this._client = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: `${process.env.REACT_APP_FRONTEND_URL}callback`,
      useRefreshTokens: true,
    });
  }

  getRedirectPath() {
    return window.sessionStorage.getItem("returnTo");
  }

  async login(signup = false) {
    window.sessionStorage.setItem("returnTo", window.location.pathname);
    const params = {
      redirect_uri: `${process.env.REACT_APP_FRONTEND_URL}callback`,
    };
    if (signup) params.screen_hint = "signup";
    await this._client.loginWithRedirect(params);
  }

  async logout() {
    await this._client.logout({
      returnTo: "https://bunsen.education",
    });
  }

  isAuthenticated() {
    return this._isAuthenticated;
  }

  async handleRedirectCallback() {
    await this._client.handleRedirectCallback();
    this._isAuthenticated = await this._client.isAuthenticated();
    this._user = await this._client.getUser();
  }

  async handleAppInit(signup = false) {
    // This function is called twice as part of the login flow. Once when the user first loads a protected part
    // of the app and once after redirect from auth0.
    if (this._isAuthenticated) return;
    if (!this._client) await this.configureClient();

    const query = window.location.search;
    if (query.includes("code=") && query.includes("state=")) {
      await this.handleRedirectCallback();
    } else {
      await this.login(signup);
    }
  }

  getClient() {
    if (this._client === undefined)
      throw new Error("Client is undefined. run configureClient first.");
    return this._client;
  }

  getUser() {
    return this._user;
  }
}

export const auth0Instance = new Auth0();
