import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import GeneralAccountDetails from "./GeneralAccountDetails";
import SchoolProfiles from "./SchoolProfiles";
import { Button } from "@mui/material";
import { auth0Instance } from "../../../auth/auth0";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(6)
  },
  myAccount: { display: 'flex', justifyContent: 'space-between' },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function MyAccount() {
  const classes = useStyles();


  async function handleLogout() {
    try {
      localStorage.clear();
      auth0Instance.logout();
    } catch (e) {
      localStorage.clear();
      window.location.reload();
    }
  }


  const accordionContents = [
    {
      title: 'General',
      component: <GeneralAccountDetails />
    },
    {
      title: 'School Profiles',
      component: <SchoolProfiles />
    },
    // {
    //   title: 'Change password',
    //   component: <ChangePassword />
    // },
    // {
    //   title: 'View app tour',
    //   component: <ResetTour />
    // },
  ]


  return (
    <div className={classes.root}>
      <Box className={classes.myAccount}>
        <Typography display="inline" variant="h6">My account</Typography>
        <Button variant="contained" color="primary" onClick={handleLogout}>Log out</Button>
      </Box>


      {accordionContents.map((value, index) => {
        const panel = `panel${index+1}`
        return(
          <Accordion
            expanded={true}

            // expanded={expanded === panel}
            // onChange={handleChange(panel)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${panel}bh-content`}
              id={`${panel}bh-header`}
            >
              <Typography className={classes.heading}>{value.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {value.component}
            </AccordionDetails>
          </Accordion>
        )
      })
    }
    </div>
  );
}
