import React, { useContext } from "react";
import { Chip, Tooltip, Link } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import DataTable from "../../components/Datatable/DataTable";
import { getFileName } from "../../utils/files";
import { ResourceForm } from "../../components/forms/ResourceForm";
import { ApiContext } from "../../api/api.context";
import { TagChip } from "../../components/TagChip";
// import ProductRecommendations from "../../components/ProductRecommendations/ProductRecommendations.container";

function CreateResource(props) {
  const api = useContext(ApiContext);
  return (
    <ResourceForm apiSubmitFunction={api.resources.post} submitButtonText="Create" {...props} />
  );
}

function UpdateResource(props) {
  const api = useContext(ApiContext);
  const { item, ...passThroughProps } = { ...props };
  return (
    <div>
      <ResourceForm
        apiSubmitFunction={(values) => api.resources.put(item.id, values)}
        submitButtonText="Update"
        initialValues={item}
        {...passThroughProps}
      />
      {/* <ProductRecommendations productQuery={item.name} /> */}
    </div>
  );
}

function ResourcesContainer() {
  const api = useContext(ApiContext);

  const columns = [
    { name: "id", label: "ID", options: { display: "excluded" } },
    { 
      name: "name", 
      label: "Name" ,
      options: {
        customBodyRender: (value) => (
          <Tooltip title={"Compare prices"} placement="top">
          <Link href={`${process.env.REACT_APP_MARKETPLACE_URL}search?type=product%2Carticle%2Cpage&options%5Bprefix%5D=last&q=${value}&filter.p.product_type=`} target="_blank" rel="noopener noreferrer">
            {value}
          </Link>
        </Tooltip>
        )
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        customBodyRender: (value) => (value === "EQ" ? "Limited" : "Standard"),
      },
    },
    { name: "code", label: "Code", options: { filter: false } },
    {
      name: "tags_detail",
      label: "Tags",
      options: {
        filter: true,
        sort: false,
        filterType: "multiselect",
        customBodyRender: (value) =>
          value?.map((val, key) => (
            <TagChip sx={{ margin: 0.5 }} label={val.name} key={key} color={val.color} />
          )),
      },
    },
    {
      name: "quantity_in_stock",
      label: "In stock",
      options: {
        filter: false,
      },
    },
    {
      name: "units",
      label: "Units",
      options: {
        filter: false,
      },
    },
    {
      name: "locations_detail",
      label: "Location",
      options: {
        filter: true,
        filterType: "multiselect",
        customBodyRender: (value) => value.map((val, key) => <Chip label={val.name} key={key} />),
      },
    },
    {
      name: "attachments_detail",
      label: "Attachments",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value?.map((val, key) => (
            <Tooltip title={getFileName(val.file)} placement="top">
              <Link href={val.file} target="_blank" rel="noopener noreferrer">
                <DescriptionIcon />
              </Link>
            </Tooltip>
          )),
      },
    },
    { name: "comment", label: "Comment", options: { filter: false } },
  ];

  return (
    <div>
      <DataTable
        itemName="resource"
        columns={columns}
        apiListFunction={api.resources.list}
        apiDeleteFunction={api.resources.del}
        updateFormComponent={UpdateResource}
        createFormComponent={CreateResource}
      />
    </div>
  );
}

export default ResourcesContainer;
