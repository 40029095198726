
export function getReservationPrimaryActivity(reservation) {
  const activity = reservation.activities_detail?.filter((x) => !x.ad_hoc)[0];
  return activity;
}

export function getReservationTitle(reservation) {
  const activity = getReservationPrimaryActivity(reservation);
  const title = reservation.title || activity?.name;
  return title;
}

export function formatResourceText(resource){
  console.log(resource)
  return (parseInt(resource.quantity) ? `${resource.quantity} ${resource.units ? `${resource.units}` : "× "} ` : "") + resource.name
}

export function getTagsSet(reservation){
  let allTags = [
    ...reservation.tags, 
    ...reservation.activities_detail.map(a=>a.tags).flat()
  ]
  return new Set(allTags)
}

