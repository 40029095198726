import { Grid, Typography, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import { Field } from "formik";
import { TimePicker } from 'formik-mui-lab';
import { TextField } from "formik-mui";
import Switch from '@mui/material/Switch';
import { useAxios } from '../../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import { get } from '../../../redux/data';
import { useSnackbar } from 'notistack';
import MuiTextField from "@mui/material/TextField";

function DayTimeDeadlineField({isAdmin, DAYS_OF_THE_WEEK, setFieldValue, values, school}) {
    const [toggle, setToggle] = useState(!!values.day_time_deadline)
    const {axiosInstance} = useAxios()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    

    const handleDeleteDeadline = async() => {
        try {
            axiosInstance.delete(`/auth/day-time-deadlines/${school.day_time_deadline.id}/`)
            enqueueSnackbar("Deadline removed", { variant: "success" });
            dispatch(get({ name: "school", data: {...school, day_time_deadline: null} }));
          }
          catch(err) {
            console.log(err)
            enqueueSnackbar("Sorry! An error has occurred", { variant: "error" });
          }
    }

    const handleToggle = (event) => {
        setToggle(st => {
            if (st){
                handleDeleteDeadline()
                setFieldValue("day_time_deadline", null)
                return false
            } else {
                return true
            }
        });
        // if (!toggle){
        //     setFieldValue("day_time_deadline", null)
        // }
    }

    return (
        <Grid container>
            <Grid item xs={11}>
            <Typography>Mark reservations made after{" "}
            <Field 
                component={TimePicker}
                size="small"
                label="Time" 
                ampm={false}
                variant="inline"
                name="day_time_deadline.time" 
                renderInput={(params) => <MuiTextField {...params} size="small" shrink label="Time" />}
                autoOk
            />{" "}on{" "} 
            <Field
                component={TextField}
                InputLabelProps={{ shrink: true }}
                size="small"
                variant="outlined"
                label="Day"
                name="day_time_deadline.day"
                select
                disabled={!isAdmin}
                children={DAYS_OF_THE_WEEK.map((day, index) => (
                    <MenuItem key={index} value={index}>
                    {day}
                    </MenuItem>
                ))}
                />{" "}for the following week as late</Typography>          
        </Grid>
        <Grid item xs={1}>
            <Switch disabled={!school.day_time_deadline} checked={toggle} onChange={handleToggle} />
        </Grid>
    </Grid>
    )
}

export default DayTimeDeadlineField
