import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    locations: {data: []},
    tags: {data: []},
    reservations: {data: []},
    slots: {data: []},
    defaultSlots: {data: []},
    school:{data:{}},
    requests: {
      count: 0,
      data: []
    },
    resources: {
      count: 0,
      data: []
    },
    users: {data: []},
    activities: {
      count: 0,
      data: []
    },
    cart: {data: []},
  },
  reducers: {
    get: (state, action) => {
      state[action.payload.name].data = action.payload.data
    },
    getMore: (state, action) => {
      const existingIds = state[action.payload.name].data.map(e => e.id)
      const newData = action.payload.data.filter(e => !existingIds.includes(e.id))
      state[action.payload.name].data = [...state[action.payload.name].data, ...newData]
      state[action.payload.name].count = action.payload.count
    },
    create:(state, action) => {
      state[action.payload.name].data = [...state[action.payload.name].data, action.payload.data]
      state[action.payload.name].count ++
    },
    update: (state, action) => {
      console.log(action.payload)
      state[action.payload.name].data = state[action.payload.name].data.map((item) => item.id === action.payload.data.id ? action.payload.data : item)
    },
    deleteSingle: (state, action) => {
      console.log(state, action)
      console.log(state[action.payload.name].data.filter((item) => item.id !== action.payload.data))
      state[action.payload.name].data = state[action.payload.name].data.filter((item) => item.id !== action.payload.data)
    },
    deleteSelected: (state, action) => {
      state[action.payload.name].data = state[action.payload.name].data.filter((item) => !action.payload.data.includes(item.id))
      // state[action.payload.name].count = state[action.payload.name].count - action.payload.data.count
    },
  },
})

// Action creators are generated for each case reducer function
export const { get, getMore, create, update, deleteSingle, deleteSelected } = dataSlice.actions

export default dataSlice.reducer