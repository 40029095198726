import React, { useContext } from "react";
import DataTable from "../../components/Datatable/DataTable";
import { ApiContext } from "../../api/api.context";
import { LocationForm } from "../../components/forms/LocationForm"

function CreateLocation(props) {
  const api = useContext(ApiContext);
  return (
    <LocationForm apiSubmitFunction={api.locations.post} submitButtonText="Create" {...props} />
  );
}

function UpdateLocation(props) {
  const api = useContext(ApiContext);
  const { item, ...passThroughProps } = { ...props };
  return (
    <LocationForm
      apiSubmitFunction={(values) => api.locations.put(item.id, values)}
      submitButtonText="Update"
      initialValues={item}
      {...passThroughProps}
    />
  );
}

function Locations() {
  const api = useContext(ApiContext);
  
  const columns = [
    {
      name: "id",
      label: "ID",
      options: { display: "excluded", filter: false },
    },
    { name: "name", label: "Name", options: { filter: false } },
  ]


  return (
    <DataTable
      itemName="location"
      addDrawer={(closeDrawer) => <CreateLocation closeDrawer={closeDrawer} />}
      editDrawer={(closeDrawer, item) => <UpdateLocation closeDrawer={closeDrawer} item={item} />}
      columns={columns}
      apiListFunction={api.locations.list}
      apiDeleteFunction={api.locations.del}
      updateFormComponent={UpdateLocation}
      createFormComponent={CreateLocation}
  />
  );
}

export default Locations;
