import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import AccountMenu from "./AccountMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: { flexGrow: 1, width: "100vw" },
  },
  title: {
    flexGrow: 1,
    textTransform: "capitalize",
    [theme.breakpoints.up("sm")]: { color: "black" },
  },
}));

function Appbar({ pageTitle, handleDrawerToggle }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="sticky" color={"transparent"}>
        <Toolbar>
          {pageTitle==='settings' && (
              <IconButton edge="start" color="inherit" onClick={handleDrawerToggle} size="large">
                <MenuIcon />
              </IconButton>
          )}
          <Typography variant="h6" className={classes.title}>
            {pageTitle}
          </Typography>
          <AccountMenu />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Appbar;
