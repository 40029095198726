import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

export function withLoader(Component, dataPropName = undefined) {
  return (hocProps) => {
    const { loaderFunction, onError, loading, ...passThroughProps } = { ...hocProps };
    const [data, setData] = useState(undefined);
    const [loading_, setLoading_] = useState(false);

    useEffect(() => {
      async function populate() {
        setLoading_(true);
        try {
          const response = await loaderFunction();
          setData(response.data);
        } catch (error) {
          Sentry.captureException(error, { extra: { response: error.response } });
          if (onError) onError(error);
          else throw error;
        } finally {
          setLoading_(false);
        }
      }
      populate();
    }, [loaderFunction, onError]);

    // allow parent to also be specify a loading flag.
    return (
      <Component
        {...{ [dataPropName || "data"]: data, loading: loading || loading_, ...passThroughProps }}
      />
    );
  };
}
