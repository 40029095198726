import { Box } from "@mui/material";

export function ColorChip({ color, sx, ...passThoughProps }) {
  const width = "1rem";
  const height = "1rem"; // can be overriden by sx prop
  return (
    <Box
      sx={{ backgroundColor: color, width, height, ...sx }}
      borderRadius="1rem"
      {...passThoughProps}
    />
  );
}
