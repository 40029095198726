import { Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAxios } from "../../../hooks/useAxios";
import { get } from "../../../redux/data";
import SlotCard from "./SlotCard";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  slotsHeader: { marginTop: theme.spacing(1) },
  noSlots: { marginTop: theme.spacing(1) },
}));

export function SchoolReservationSlots() {
  const slots = useSelector((state) => state.defaultSlots.data)
  const dispatch = useDispatch()
  const theme = useTheme();
  const classes = useStyles(theme);
  const [renderNewSlot, setRenderNewSlot] = useState(false);
  const {axiosInstance} = useAxios()
  const path = `school-reservation-slots`

  useEffect(() => {
    async function loadSlots() {
      const templatesResponse = await axiosInstance.get(path);
      console.log(`slots loaded: ${templatesResponse.data}`)
      dispatch(get({ name: "defaultSlots", data: templatesResponse.data }));
    }
    loadSlots();
  }, [dispatch, axiosInstance, path]);

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item className={classes.slotsHeader}>
          <Typography variant="h6" gutterBottom>
            {renderNewSlot ? ( "New school-wide reservation time-slot") : ("Default school-wide reservation time-slots")}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setRenderNewSlot(true)}
          >
            New slot
          </Button>
        </Grid>
      </Grid>
      <Typography>
        Time-slots make reservations easier. <br/> 
        Every new user who is invited to your school starts with these reservation time-slots by default. 
        These should correspond to your school's class timetable.
      </Typography>
      {renderNewSlot && (
        <div>
          <SlotCard key={0} path={path}/>
          <Divider />
          <Typography variant="h6" className={classes.slotsHeader}>
            Existing school reservation slots
          </Typography>
        </div>
      )}
      {slots.map((slot, index) => (
        <div key={index + 1}>
          <SlotCard slot={slot} index={index + 1} path={path}/>
          <Divider />
        </div>
      ))}
      {slots.length === 0 && (
        <Typography className={classes.noSlots}>
          You have no reservation slots.
        </Typography>
      )}
    </div>
  );
}


