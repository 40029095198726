export const resources = [
  "Art studio",
  "Room 11",
  "Room 12",
  "Room 13",
  "Room 14",
  "Room 21",
  "Room 22",
  "Room 23",
  "Room 24",
  "Room 25",
  "Room 31",
  "Room 32",
  "Library",
  "Gym",
  "Tennis courts",
  "Hockey Field",
  "Fitness Centre",
  "Lab A",
  "Lab B",
  "Lab C",
  "Lab D",
  "Lab E",
];

export const events = [
  {
    title: "Investigating microscopes",
    start: new Date().setHours(8, 0),
    end: new Date().setHours(8, 55),
    number_of_sets: 25,
    resourceId: 19,
    reserved_for: { first_name: "O", last_name: "T" },
  },
  {
    title: "Photosynthetic pigments",
    start: new Date().setHours(14, 0),
    end: new Date().setHours(15, 55),
    number_of_sets: 12,
    resourceId: 19,
    reserved_for: { first_name: "O", last_name: "T" },
  },
  {
    title: "Displacement reactions",
    start: new Date().setHours(10, 0),
    end: new Date().setHours(11, 55),
    number_of_sets: 12,
    resourceId: 20,
    reserved_for: { first_name: "T", last_name: "M" },
  },
  {
    title: "Investigating rates",
    start: new Date().setHours(13, 0),
    end: new Date().setHours(14, 55),
    number_of_sets: 12,
    resourceId: 20,
    reserved_for: { first_name: "T", last_name: "M" },
  },
  {
    title: "Mouse dissection",
    start: new Date().setHours(9, 0),
    end: new Date().setHours(10, 55),
    number_of_sets: 12,
    resourceId: 21,
    reserved_for: { first_name: "E", last_name: "W" },
  },
  {
    title: "Science club",
    start: new Date().setHours(12, 0),
    end: new Date().setHours(13, 55),
    number_of_sets: 12,
    resourceId: 21,
    reserved_for: { first_name: "E", last_name: "W" },
  },
  {
    title: "Temperature and enzymes",
    start: new Date().setHours(10, 0),
    end: new Date().setHours(11, 55),
    number_of_sets: 25,
    resourceId: 19,
    reserved_for: { first_name: "O", last_name: "T" },
  },
  {
    title: "Strength training",
    start: new Date().setHours(8, 0),
    end: new Date().setHours(8, 55),
    number_of_sets: 25,
    resourceId: 13,
    reserved_for: { first_name: "S", last_name: "B" },
  },
  {
    title: "Staff yoga",
    start: new Date().setHours(12, 0),
    end: new Date().setHours(12, 55),
    number_of_sets: 20,
    resourceId: 13,
    reserved_for: { first_name: "A", last_name: "M" },
  },
  {
    title: "Hockey training",
    start: new Date().setHours(10, 0),
    end: new Date().setHours(12, 55),
    number_of_sets: 13,
    resourceId: 15,
    reserved_for: { first_name: "S", last_name: "B" },
  },
  {
    title: "Junior badminton",
    start: new Date().setHours(10, 0),
    end: new Date().setHours(11, 55),
    number_of_sets: 25,
    resourceId: 16,
    reserved_for: { first_name: "S", last_name: "B" },
  },
  {
    title: "Handball",
    start: new Date().setHours(14, 0),
    end: new Date().setHours(15, 30),
    number_of_sets: 16,
    resourceId: 16,
    reserved_for: { first_name: "M", last_name: "P" },
  },
  {
    title: "Boyle's law",
    start: new Date().setHours(13, 0),
    end: new Date().setHours(13, 55),
    number_of_sets: 10,
    resourceId: 7,
    reserved_for: { first_name: "W", last_name: "P" },
  },
  {
    title: "Pendulums",
    start: new Date().setHours(9, 0),
    end: new Date().setHours(10, 55),
    number_of_sets: 30,
    resourceId: 18,
    reserved_for: { first_name: "A", last_name: "A" },
  },
  {
    title: "Reading club",
    start: new Date().setHours(12, 0),
    end: new Date().setHours(13, 55),
    number_of_sets: 12,
    resourceId: 4,
    reserved_for: { first_name: "E", last_name: "W" },
  },
  {
    title: "Silent study",
    start: new Date().setHours(9, 0),
    end: new Date().setHours(10, 55),
    number_of_sets: 12,
    resourceId: 12,
    reserved_for: { first_name: "E", last_name: "W" },
  },
  {
    title: "Pottery",
    start: new Date().setHours(9, 0),
    end: new Date().setHours(11, 55),
    number_of_sets: 7,
    resourceId: 11,
    reserved_for: { first_name: "A", last_name: "R" },
  },
  {
    title: "Still life drawing",
    start: new Date().setHours(13, 0),
    end: new Date().setHours(15, 55),
    number_of_sets: 7,
    resourceId: 0,
    reserved_for: { first_name: "A", last_name: "R" },
  },
  {
    title: "Board games",
    start: new Date().setHours(10, 0),
    end: new Date().setHours(10, 55),
    number_of_sets: 16,
    resourceId: 6,
    reserved_for: { first_name: "I", last_name: "B" },
  },
  {
    title: "Arts and crafts",
    start: new Date().setHours(10, 30),
    end: new Date().setHours(11, 55),
    number_of_sets: 11,
    resourceId: 2,
    reserved_for: { first_name: "B", last_name: "B" },
  },
  {
    title: "Junior art club",
    start: new Date().setHours(13, 0),
    end: new Date().setHours(14, 55),
    number_of_sets: 15,
    resourceId: 2,
    reserved_for: { first_name: "O", last_name: "W" },
  },
  {
    title: "Board games",
    start: new Date().setHours(10, 0),
    end: new Date().setHours(10, 55),
    number_of_sets: 17,
    resourceId: 1,
    reserved_for: { first_name: "I", last_name: "B" },
  },
  {
    title: "Sensory learning",
    start: new Date().setHours(9, 30),
    end: new Date().setHours(11, 30),
    number_of_sets: 13,
    resourceId: 7,
    reserved_for: { first_name: "Y", last_name: "M" },
  },
  {
    title: "Parachute games",
    start: new Date().setHours(12, 30),
    end: new Date().setHours(15, 55),
    number_of_sets: 2,
    resourceId: 10,
    reserved_for: { first_name: "Y", last_name: "M" },
  },
];
