import { Box, Typography } from "@mui/material";

const ResponsiveText = (props) => (
  // using MUI built in responsiveness.
  <Typography display="inline" fontSize={{ xs: "11px", sm: "11.9px" }} noWrap {...props} />
);

export function BackgroundEvent({ eventInfo }) {
  const onClick = eventInfo.event.extendedProps.onClick;
  return (
    <Box
      padding={{ xs: 0.2, sm: 0.5 }}
      boxSizing="border-box"
      position="relative"
      overflow="hidden"
      height="100%"
      onClick={(event) => {
        onClick(eventInfo);
      }}
    >
      <Box>
        <ResponsiveText>{eventInfo.event.title}</ResponsiveText>
      </Box>
    </Box>
  );
}
