import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { DONE_STATUS, PENDING_STATUS, SKIPPED_STATUS } from "./gettingStartedConfig";

function GettingStartedItem({ item, setStatus }) {
  const done = item.status === DONE_STATUS || item.status === SKIPPED_STATUS;
  return (
    <Paper>
      <Box p={2} display="flex" alignItems={"center"} sx={{ opacity: done ? 0.4 : 1 }}>
        <Box maxWidth={"50%"}>
          <Typography variant="h5">{item.heading}</Typography>
          <Typography variant="body1">{item.text}</Typography>
        </Box>
        <Stack direction="row" ml="auto" spacing={1}>
          <NavLink to={item.path}>
            <Button variant={done ? "outlined" : "contained"}>Take me</Button>
          </NavLink>
          {item.video && <Button variant="contained" color="secondary" target="_blank" href={item.video}>Watch video guide</Button>}
          <Button
            variant="outlined"
            onClick={() => setStatus(done ? PENDING_STATUS : DONE_STATUS)}
            color="success"
          >
            {done ? "Undo" : "I've Done this"}
          </Button>
          <Button variant="text" onClick={() => setStatus(SKIPPED_STATUS)}>
            Skip
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
}

export function GettingStartedList({ items, setStatus }) {
  if (!items) return null;
  return (
    <Stack spacing={2}>
      {items.map((item, index) => (
        <GettingStartedItem key={index} item={item} setStatus={(value) => setStatus(item, value)} />
      ))}
    </Stack>
  );
}
