import React from 'react'
import { CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";
import Link from "@mui/material/Link";

function Callback() {

    return (
        <div style={{ margin: "auto", textAlign:"center" }}>
            <CircularProgress size={60} />
            <Typography style={{margin: "8px"}}>
                You are being redirected. Click <Link href="/practicals/scheduler">here</Link> to return home if nothing happens.
            </Typography>
        </div>
    )
}

export default Callback
