import { useContext, useEffect, useState } from "react";
import { ActiveSchoolsContext } from "../contexts/activeSchools.context";
import { useInitGettingStarted } from "./GettingStarted/gettingStartedConfig";
import { useAxios } from "../hooks/useAxios";
import Setup from "../views/signup/Setup";
import { SubmitNameModal } from "./input/Autocomplete/SubmitNameModal";
import { LoaderWithMessage } from "./LoaderWithMessage";

function InitializeApp({ children }) {
  const { axiosInstance } = useAxios();
  const {
    activeSchoolsState: { userprofiles },
    user,
    dispatch,
  } = useContext(ActiveSchoolsContext);
  const [loading, setLoading] = useState(false);
  useInitGettingStarted();
  // console.log("user: ", user);

  const refresh = async () => {
    try {
      setLoading(true);
      const currentUserResponse = await axiosInstance.get("auth/me/");
      console.log(currentUserResponse);
      dispatch({ type: "SET_USER", data: currentUserResponse.data });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userprofiles.length) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userprofiles.length]);

  if (loading) return <LoaderWithMessage message="Loading your profile..." />;

  // if you reached stage 1 of setup, you'll have created a userprofile
  // Invited users already have a user profile created so they skip this step.
  if (localStorage.getItem("setupStage") > 0 || userprofiles.length === 0) {
    return <Setup onSuccess={refresh} />;
  }

  if (user?.name.trim() === "") return <SubmitNameModal onSuccess={refresh} />;

  return children;
}

export default InitializeApp;
