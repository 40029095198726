import CssBaseline from "@mui/material/CssBaseline";
import Hidden from "@mui/material/Hidden";
import React from "react";
import Appbar from "./nav/Appbar";
import SimpleBottomNavigation from "./nav/mobile/BottomNavigation";
import { navLabels } from "./nav/navLabels";
import { useTheme, styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router-dom";
import SecondaryNav from "./nav/mobile/SecondaryNav";
import { SideNavigation } from "./nav/desktop/SideNavigation";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}));

const ContentStyle = styled("main")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    height: `calc(100vh - ${theme.spacing(2)}*2)`,

    // height: "100vh",
  },
  [theme.breakpoints.down("sm")]: {
    height: `calc(100vh - ${theme.mobileDrawerWidth * 2}px)`,
    position: "relative",
    // overflow: 'scroll',
    marginBottom: theme.mobileDrawerWidth,
  },
}));

function MainLayout(props) {
  const theme = useTheme();

  const activeLinkStyle = { color: theme.palette.primary.main };

  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath(pathname, { path, exact: false }) : false);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <RootStyle>
      <CssBaseline />
      <Hidden smDown implementation="css">
        <SideNavigation pathMatcher={match} />
      </Hidden>

      <Hidden smUp implementation="css">
        <Appbar
          pageTitle={pathname.split("/")[1]}
          secondaryNav
          handleDrawerToggle={handleDrawerToggle}
        />
        <SecondaryNav
          active={match}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <SimpleBottomNavigation navLabels={navLabels} activeLinkStyle={activeLinkStyle} />
      </Hidden>
      <ContentStyle>{props.children}</ContentStyle>
    </RootStyle>
  );
}

export default MainLayout;
