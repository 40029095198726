import { useContext, useState, useCallback } from "react";
import { ApiContext } from "../../api/api.context";
import { withLoader } from "../../api/withLoader";
import { TagChip } from "../TagChip";
import { AutocompleteInput } from "./AutocompleteInput";

const AutocompleteInputWithLoader = withLoader(AutocompleteInput, "options");

export function TagsAutocomplete(props) {
  const { sendMessage, tagsAdornment, tagProps, ...passThroughProps } = props;
  const [loading, setLoading] = useState(false);
  const api = useContext(ApiContext);
  // console.log(`PTP ${JSON.stringify(passThroughProps)}`)
  const createTag = async (payload) => {
    let data = undefined;
    setLoading(true);
    try {
      const response = await api.tags.post(payload);
      sendMessage && sendMessage("Tag created.");
      data = response.data;
    } catch (error) {
      if (sendMessage) {
        sendMessage("Error: Tag not created", {
          variant: "error",
        });
      } else {
        throw error;
      }
    }
    setLoading(false);
    return data;
  };

  const onError = useCallback((error) => sendMessage(error.message), [sendMessage]);

  return (
    <AutocompleteInputWithLoader
      loaderFunction={api.tags.list}
      onError={onError}
      multiple
      label="Add Tags"
      createItem={createTag}
      loading={loading}
      renderOption={(props, option) => (
        <li {...props}>
          <TagChip color={option.color} label={option.name} {...tagProps} />
        </li>
      )}
      renderTags={(value, getTagProps) => (
        <>
          {value.map((option, index) => (
            <TagChip
              color={option.color}
              label={option.name}
              {...tagProps}
              {...getTagProps({ index })}
            />
          ))}
          {tagsAdornment}
        </>
      )}
      {...passThroughProps}
    />
  );
}
