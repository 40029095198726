import MobileStepper from "@mui/material/MobileStepper";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: "0 auto",
    backgroundColor: "transparent",
  },
});

export default function Stepper({ currentStep }) {
  const classes = useStyles();

  return (
    currentStep >= 0 && (
      <MobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={currentStep}
        className={classes.root}
      />
    )
  );
}
