import { Grid, Typography } from "@mui/material";
import React from "react";
import { TagChip } from "../TagChip";

export const OptionWithNameCodeTag = ({ option }) => (
  <Grid container alignContent="space-between">
    <Grid item xs={4}>
      <Typography variant="body2" color="textSecondary" align="left">
        {option.code}
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography align="right">{option.title || option.name}</Typography>
    </Grid>
    <Grid item xs={12}>
      {option.tags_detail?.map((t, i) => (
        <TagChip color={t.color} label={t.name} size="small" key={i} />
      ))}
    </Grid>
  </Grid>
);
