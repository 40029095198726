import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import Tour from "./Tour";
import SettingsRoutes from "./views/settings/SettingsRoutes";
import NotFound from "./views/NotFound";

import ActivitiesRoot from "./views/practicals";
import ResourcesContainer from "./views/equipment/Resources.container";
import { LoginFailed } from "./views/login-failed";
import { SchedulerContainer } from "./views/scheduler";
import Callback from "./views/callback";
import { GettingStarted } from "./views/getting-started";
import InitializeApp from "./components/InitializeApp";
import { LoginRequired } from "./auth/LoginRequired";
import { ActivateRewards } from "./views/activate-rewards";
import { SnackbarProvider } from "notistack";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

function Routes() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const routes = (
    <SnackbarProvider
    dense={isMobile}
    maxSnack={3}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
  >
      <Switch>
        <Route exact path="/login-failed" component={LoginFailed} />
        <Route path="/callback">
          <LoginRequired>
            <Callback />
          </LoginRequired>
        </Route>
        <Route path="/signup">
          <LoginRequired signup={true}>
            <Redirect exact from="/signup" to="/scheduler" />
          </LoginRequired>
        </Route>

        <Route
          path={[
            "/activate-rewards",
            "/scheduler",
            "/scheduler/:viewName/:startDate",
            "/practicals",
            "/equipment",
            "/settings",
            "/getting-started",
          ]}
        >
          <LoginRequired>
            <InitializeApp>
              <Tour />
              <MainLayout>
                <Route exact path="/scheduler" component={SchedulerContainer} />
                <Route exact path="/activate-rewards" component={ActivateRewards} />
                <Route
                  exact
                  path="/scheduler/:viewName/:startDate"
                  component={SchedulerContainer}
                />
                <Route exact path="/practicals" component={ActivitiesRoot} />
                <Route exact path="/equipment" component={ResourcesContainer} />
                <Route path="/settings" component={SettingsRoutes} />
                <Route path="/getting-started" component={GettingStarted} />
              </MainLayout>
            </InitializeApp>
          </LoginRequired>
        </Route>

        <Redirect from="/login" to="/scheduler" />
        <Redirect from="/practicals/practicals" to="/practicals" />
        <Redirect from="/activities" to="/practicals" />
        <Redirect from="/practicals/scheduler" to="/scheduler" />
        <Redirect exact from="/equipment/inventory" to="/equipment" />
        <Redirect exact from="/inventory" to="/equipment" />
        <Redirect exact from="/" to="/scheduler" />

        <Route render={() => <NotFound />} />
      </Switch>
    </SnackbarProvider>
  );

  return <main>{routes}</main>;
}

export default Routes;
