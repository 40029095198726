import React from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
// import SideNavigation from "../../layout/components/desktop/SideNavigation";
import { useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { resources, events } from "./SignUpBackgroundData";

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingLeft: theme.drawerWidth,
    border: `24px white solid`,
  },
  calendar: { marginLeft: theme.spacing(3) },
}));

function SignUpBackground() {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.root}>
      <FullCalendar
        plugins={[resourceTimelinePlugin]}
        scrollTime="08:00:00"
        initialView="resourceTimeline"
        schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY}
        resourceOrder="title"
        views="resourceTimeLine"
        resourceAreaHeaderContent="Location"
        slotMinWidth={75}
        resourceAreaWidth={"15%"}
        events={events}
        eventBackgroundColor="#ff5722"
        eventBorderColor="#FF5722"
        eventContent={(eventInfo) => (
          <div>
            {eventInfo.event.extendedProps.reserved_for.first_name.charAt(0)}
            {eventInfo.event.extendedProps.reserved_for.last_name.charAt(
              0
            )}{" "}
            {eventInfo.event.extendedProps.number_of_sets} &times;{" "}
            {eventInfo.event.title}
          </div>
        )}
        resources={resources.map((resource, index) => ({
          id: index,
          title: resource,
        }))}
      />
    </div>
  );
}

export default SignUpBackground;
