import React from 'react'
import { Chip, Link } from "@mui/material";
import { getFileName } from '../utils/files';

export function FileChip(props) {
    const  {attachment, ...otherProps} = props
  return (
    <Link href={props.attachment.file} target="_blank" rel="noopener noreferrer">
        <Chip
            clickable
            size="small"
            label={getFileName(attachment.file)}
            target="_blank"
            href={attachment.file}
            {...otherProps}
        />
    </Link>
  )
}